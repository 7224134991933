import { gql } from '@apollo/client';
import { COMPANY_FRAGMENT } from 'graphql/fragments/company';
import { DOCUMENT_FRAGMENT } from 'graphql/fragments/documents';
import { VIDEO_FRAGMENT } from 'graphql/fragments/media';
import { IMPACT_CLAIM_FRAGMENT } from './impactClaims';

const IMAGE_VARIANT_FRAGMENT = gql`
  fragment imageVariant on ImageVariant {
    id
    imageId
    path
    url
    width
    height
    size
    crop {
      x
      y
      width
      height
    }
  }
`;

export const CULTIVATED_AREAS_FRAGMENT = gql`
  fragment cultivatedAreaValues on CultivatedAreas {
    id
    coordinates
    farm {
      id
      partner {
        title
      }
    }
  }
`;

export const FARM_BASE_FRAGMENT = gql`
  fragment farmBaseValues on Farm {
    id
    size
    externalId
    harvestWeight
  }
`;

export const FARM_FRAGMENT = gql`
  fragment farmValues on Farm {
    ...farmBaseValues
    cultivatedAreas {
      ...cultivatedAreaValues
    }
  }
  ${FARM_BASE_FRAGMENT}
  ${CULTIVATED_AREAS_FRAGMENT}
`;

export const BASE_ACTIVITY_FRAGMENT = gql`
  fragment baseActivityValues on Activity {
    id
    partnerId
    title
    createdTimestamp
  }
`;

export const PARTNER_DOCUMENT_REQUEST_LOG = gql`
  fragment partnerRequestLogValues on PartnerRequestLog {
    id
    comment
    userEmail
    user {
      firstName
      lastName
      email
    }
    companyId
    company {
      name
      logo {
        ...imageVariant
      }
    }
    timestamp
    action
  }
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const PARTNER_LOGS_FRAGMENT = gql`
  fragment partnerLogValues on PartnerLog {
    id
    comment
    userEmail
    user {
      firstName
      lastName
      email
    }
    companyId
    company {
      name
      logo {
        ...imageVariant
      }
    }
    timestamp
    action
  }
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const ACTIVITY_FRAGMENT = gql`
  fragment activityValues on Activity {
    ...baseActivityValues
    langs
    description
    name
    locationCoordinates {
      lat
      lng
    }
    mediaList {
      ... on Video {
        ...video
      }
      ... on ImageVariant {
        ...imageVariant
      }
    }
    component {
      id
      title
      partnerId
      rawMaterials {
        id
        title
      }
    }
    partner {
      id
      title
      createdTimestamp
      status
      type
    }
    cultivatedAreas {
      ...cultivatedAreaValues
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${VIDEO_FRAGMENT}
  ${BASE_ACTIVITY_FRAGMENT}
  ${CULTIVATED_AREAS_FRAGMENT}
`;

export const PARTNER_BASE_FRAGMENT = gql`
  fragment partnerBaseValues on Partner {
    id
    title
    createdTimestamp
    status
    type
    externalId
    logo {
      ...imageVariant
    }
    farm {
      ...farmBaseValues
    }
    company {
      id
      name
      logo {
        ...imageVariant
      }
      partnerId
    }
    activities {
      count
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${FARM_BASE_FRAGMENT}
`;

export const PARTNER_FRAGMENT = gql`
  fragment partnerValues on Partner {
    ...partnerBaseValues
    logs {
      ...partnerLogValues
    }
    latestLog {
      ...partnerLogValues
    }
    impactClaims {
      edges {
        node {
          ...impactClaimValues
        }
      }
    }
    logo {
      ...imageVariant
    }
    farm {
      ...farmValues
    }
    activities {
      count
      edges {
        node {
          ...activityValues
        }
      }
    }
  }
  ${PARTNER_BASE_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  ${IMPACT_CLAIM_FRAGMENT}
  ${FARM_FRAGMENT}
  ${PARTNER_LOGS_FRAGMENT}
`;

export const BASE_PARTNER_REQUEST_FRAGMENT = gql`
  fragment basePartnerRequestValues on PartnerRequest {
    id
    requestedByUserId
    partnerId
    documentId
    componentId
    chainId
    requestTitle
    requestMessage
    requestReplyMessage
    requestStatus
    requestedTimestamp
    requestedToCompany {
      ...companyValues
    }
    requestedFromCompany {
      ...companyValues
    }
    partner {
      ...partnerValues
    }
    document {
      ...documentsValues
    }
    logs {
      ...partnerRequestLogValues
    }
    latestLog {
      ...partnerRequestLogValues
    }
    previouslyUploadedDocuments {
      ...documentsValues
    }
    requestType
  }
  ${PARTNER_FRAGMENT}
  ${COMPANY_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${PARTNER_DOCUMENT_REQUEST_LOG}
`;

export const PARTNER_REQUEST_FRAGMENT = gql`
  fragment partnerRequestValues on PartnerRequest {
    ...basePartnerRequestValues
    chain {
      id
      title
      image {
        ...imageVariant
      }
      chainTasksDetails {
        completed
        total
      }
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${BASE_PARTNER_REQUEST_FRAGMENT}
`;
