import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Edit } from '@styled-icons/boxicons-solid';
import { ThemeButton, CardContainer, ItemCardSmall } from 'designSystem';
import { AvailableSizes, BackgroundTypes } from 'types/enums';
import { IActivity } from 'types/partner.types';
import ActivityItem from 'components/Partners/ActivitiesTable/ActivityItem';

interface IPolygonDataCardProps {
  activity: IActivity;
  onEditActivity: () => void;
}

const PolygonDataCard: FC<IPolygonDataCardProps> = ({ activity, onEditActivity }) => {
  return (
    <CardContainer
      size={AvailableSizes.LARGE}
      background={BackgroundTypes.MAP_LAYERS}
      title="Upload or draw polygons for the cultivation area of each activity"
    >
      <Box mt="auto" pb={2}>
        <ItemCardSmall>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <ActivityItem activity={activity} />
            <ThemeButton
              color="YELLOW"
              size="large"
              startIcon={<Edit size={14} />}
              onClick={onEditActivity}
            >
              Add polygon
            </ThemeButton>
          </Box>
        </ItemCardSmall>
      </Box>
    </CardContainer>
  );
};

export default PolygonDataCard;
