import { PartnerAutocomplete } from 'components/Partners/Form';
import { FieldWrapper } from 'designSystem';
import { useField } from 'formik';
import React, { FC } from 'react';
import { Partner } from 'types/partner.types';

interface IPartnerAutocompleteProps {
  name: string;
  disabled?: boolean;
  label?: string;
  helperText?: string;
  isRequired?: boolean;
}

const PartnerAutocompleteWrapper: FC<IPartnerAutocompleteProps> = ({
  name,
  disabled,
  label,
  isRequired,
  helperText,
}) => {
  const [field, meta, helper] = useField<Partner | undefined>(name);

  const handlePartnerSelect = async (partner: Partner | null) => {
    await helper.setTouched(true);
    helper.setValue(partner || undefined, true);
  };

  return (
    <FieldWrapper
      label={label || 'Owner'}
      variant="small"
      required={isRequired}
      tooltip={
        helperText
          ? {
              helperText,
              variant: 'INFO',
            }
          : undefined
      }
    >
      <PartnerAutocomplete
        defaultValue={field.value}
        size="small"
        disabled={disabled}
        errorMessage={meta.error}
        hasError={!!meta.error && meta.touched}
        filterByRegisteredPartners={false}
        onPartnerSelect={handlePartnerSelect}
      />
    </FieldWrapper>
  );
};

export default PartnerAutocompleteWrapper;
