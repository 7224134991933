import { Box, MenuItem, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC, PropsWithChildren } from 'react';
import { Plus } from '@styled-icons/bootstrap/Plus';

const PaperContainer = styled(Paper)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

const AddIcon = styled(Plus)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

/**
 * This adds an add button to the bottom of the paper
 * Can be used with the component prop PaperComponent -> business-app/src/components/Partners/Form/PartnerAutocomplete.tsx
 */
const PaperWithAddItem: FC<{ title: string; onClick: () => void } & PropsWithChildren> = ({
  title,
  children,
  onClick,
}) => (
  <PaperContainer>
    {children}
    <MenuItem onMouseDown={onClick}>
      <AddIcon size={16} />
      <Box mr={1} />
      {title}
    </MenuItem>
  </PaperContainer>
);

export default PaperWithAddItem;
