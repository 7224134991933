import { styled } from '@mui/material/styles';
import { CheckCircleFill, ExclamationCircleFill } from '@styled-icons/bootstrap';
import { ICellRendererParams } from 'ag-grid-community';
import {
  isRowCompletelyFilled,
  isRowValid,
  rowHasValidatedCells,
} from 'components/DataImport/utils/dataImport.utils';
import React, { FC } from 'react';
import { IExcelTableContext } from '../../excelTable.types';

const ValidationContainer = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const ValidationCellRenderer: FC<ICellRendererParams<any, any, IExcelTableContext>> = ({
  data,
  context,
}) => {
  // @ts-ignore
  const { theme, columnDefs } = context;

  if (!rowHasValidatedCells(data) || !isRowCompletelyFilled(data, columnDefs)) {
    return null;
  }

  return (
    <ValidationContainer>
      {isRowValid(data) ? (
        <CheckCircleFill size={16} color={theme.custom.themeColors.success[80]} />
      ) : (
        <ExclamationCircleFill size={16} color={theme.custom.themeColors.error[80]} />
      )}
    </ValidationContainer>
  );
};

export default ValidationCellRenderer;
