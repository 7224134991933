import { Box, ButtonGroup, FormControl, FormHelperText, TextField, styled } from '@mui/material';
import { areCoordinatesValid } from 'components/DataImport/utils/dataImport.validators';
import { useQueryReturn } from 'components/hooks';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { FieldProps, useField } from 'formik';
import { GET_PLACES } from 'graphql/queries';
import React, { FC, useEffect, useState } from 'react';
import { CoordinateVersion } from 'types/dataImport.types';

interface ICoordinatesInputFieldProps extends FieldProps {
  locationNameKey?: string;
}

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const StyledTextField = styled(TextField)(() => ({
  minWidth: 200,
  '& .Mui-error': {
    marginLeft: 0,
    marginBottom: 2,
  },
}));

const CoordinatesInputField: FC<ICoordinatesInputFieldProps> = ({
  field,
  form,
  locationNameKey,
}) => {
  const { name, value } = field;
  const [, { error }, { setError, setValue }] = useField(name);
  const { setFieldValue } = form;
  const [input, setInput] = useState<string>('');
  const [coordinateVersion, setCoordinateVersion] = useState<CoordinateVersion>('latlng');

  useEffect(() => {
    if (value && (value.lat === undefined || value.lng === undefined)) {
      setInput('');
    } else {
      if (coordinateVersion === 'latlng') {
        setInput(`${value?.lat},${value?.lng}`);
      } else {
        setInput(`${value?.lng},${value?.lat}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    handleSetCoordinates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinateVersion]);

  const getLocation = useQueryReturn(GET_PLACES, {
    fetchPolicy: 'no-cache',
  });
  const request = (query: unknown) =>
    getLocation({
      variables: {
        query,
      },
    });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleSetCoordinates = async () => {
    if (!input) {
      return;
    }

    if (!areCoordinatesValid(input, coordinateVersion === 'lnglat')) {
      setError('Invalid coordinates');
      return;
    } else {
      setError(undefined);
    }

    const [latitude, longitude] = input?.split(',').map(Number);
    const lat = coordinateVersion === 'lnglat' ? longitude : latitude;
    const lng = coordinateVersion === 'lnglat' ? latitude : longitude;
    const hasLocationNameKey = locationNameKey !== undefined;
    const hasLatAndLng = !isNaN(lat) && !isNaN(lng);

    if (hasLocationNameKey && hasLatAndLng) {
      const { data } = await request(`${lng},${lat}`);

      if (data.getPlaces.length !== 0) {
        const location = data.getPlaces[0];
        setFieldValue(locationNameKey, location.title);
      }
    }
    if (hasLatAndLng) {
      setValue({ lat, lng });
    }
  };

  return (
    <FormControl fullWidth size="small" error={!!error}>
      <Box display="flex" alignItems="center" overflow="hidden">
        <StyledTextField
          variant="outlined"
          size="small"
          name={name}
          error={!!error}
          value={input}
          placeholder={
            coordinateVersion === 'lnglat'
              ? 'Enter coordinates (lng, lat)'
              : 'Enter coordinates (lat,lng)'
          }
          onChange={handleChange}
          onBlur={handleSetCoordinates}
        />
        <StyledButtonGroup>
          <ThemeButton
            size="medium"
            className={coordinateVersion === 'latlng' ? 'selected' : ''}
            onClick={() => setCoordinateVersion('latlng')}
          >
            Lat, Lng
          </ThemeButton>
          <ThemeButton
            size="medium"
            className={coordinateVersion === 'lnglat' ? 'selected' : ''}
            onClick={() => setCoordinateVersion('lnglat')}
          >
            Lng, Lat
          </ThemeButton>
        </StyledButtonGroup>
      </Box>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default CoordinatesInputField;
