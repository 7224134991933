import bulb from 'assets/img/welcomeSlides/icon_bulb_plant.svg';
import magnifier from 'assets/img/welcomeSlides/icon_magnifier.svg';
import tracing from 'assets/img/welcomeSlides/icon_tracing.svg';
import supplyChain from 'assets/img/welcomeSlides/icon_supply_chain.svg';
import { ApplicationPlan } from 'types/companyTypes';

export const getWelcomeItems = (plan: ApplicationPlan) => {
  if (plan === 'FULL_PLAN') {
    return [
      {
        icon: tracing,
        text: 'Supply chain <br /> tracing',
        width: 30,
      },
      {
        icon: supplyChain,
        text: 'Visualize your <br /> supply chain',
        width: 38,
      },
      {
        icon: bulb,
        text: 'Prove your <br /> impact',
        width: 30,
      },
      {
        icon: magnifier,
        text: 'Learn more about <br /> your customer',
        width: 34,
      },
    ];
  }

  if (plan === 'SLIM_PLAN') {
    return [
      {
        icon: supplyChain,
        text: 'Visualize your <br /> Supply Chain',
        width: 38,
      },
      {
        icon: magnifier,
        text: 'Learn more about <br /> your customer',
        width: 34,
      },
    ];
  }
  return [];
};
