import React, { FC, useState } from 'react';
import { Box, DialogContent, styled, ButtonGroup } from '@mui/material';
import { TextField } from 'formik-mui';
import { Partner, PartnerType, PartnerTypeEnum } from 'types/partner.types';
import emailSendIcon from 'assets/img/icons/email-send.svg';
import { Field, Form, Formik } from 'formik';
import { PartnerInviteSchema } from 'constants/schemas';
import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_PARTNER, INVITE_PARTNER } from 'graphql/mutations/partners';
import { addNewPartnerToCache } from '../utils/partnerUtils';
import { useLogEvent, useMessages } from 'components/hooks';
import { Alert } from '@mui/material';
import { usePartnerMutations } from '../hooks';
import { GET_PARTNER } from 'graphql/queries';
import { DialogDefault, ThemeButton, ThemeTypography, FieldWrapper } from 'designSystem';
import { IDefaultDialogProps } from 'types/dialog.types';

const ButtonIcon = styled('img')(() => ({
  width: 18,
  pointerEvents: 'none',
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

export interface IPartnerInviteDialogProps extends IDefaultDialogProps {
  connectToCompanyId?: string;
  existingPartner?: Partner;
  hidePartnerType?: boolean;
}

const PartnerInviteDialog: FC<IPartnerInviteDialogProps> = ({
  existingPartner,
  hidePartnerType,
  connectToCompanyId,
  onClose,
  open = true,
}) => {
  const [error, setError] = useState<string>();
  const { setSuccessMessage } = useMessages();
  const [newPartnerId, setNewSupplerId] = useState<string>();
  const { handleRemovePartner } = usePartnerMutations();
  const { logEvent } = useLogEvent();
  const isInvitingExistingPartner: boolean = existingPartner?.id !== undefined;

  const [createPartner] = useMutation<
    { createPartner: { partner: Partner } },
    { input: Partial<Partner> & { companyId?: string } }
  >(CREATE_PARTNER, {
    onError: () => setError('There was an error creating your partner'),
    update: (cache, createdPartner) => {
      const newPartner = createdPartner.data?.createPartner.partner;
      if (newPartner) {
        setNewSupplerId(newPartner.id);
        addNewPartnerToCache(cache, newPartner);
      }
    },
  });

  const [invitePartner] = useMutation<
    { invitePartner: { email: string; partnerId: string } },
    { email: string; partnerId: string }
  >(INVITE_PARTNER, {
    onCompleted: () => {
      setSuccessMessage(
        'Nice! Your partner has been invited! You will receive a confirmation email when your partner has accepted the invitation.'
      );
      logEvent('PARTNER_INVITED');
      onClose?.();
    },
    onError: (error: ApolloError) => {
      if (error.message === 'USER_EXISTS') {
        setError('This user already exists');
        // If a new partner was created, immediately remove the partner again after
        // the invitation has failed.§
        if (newPartnerId) {
          handleRemovePartner({ partnerId: newPartnerId });
        }
      } else {
        setError(error.message);
      }
    },
  });

  const handleInviteNewPartner = async (email: string, name: string, type: PartnerType) => {
    // Create a new partner as it does not exist yet.
    const newPartner = await createPartner({
      variables: {
        input: { title: name, type, companyId: connectToCompanyId },
      },
    });

    const newPartnerId: string | undefined = newPartner.data?.createPartner.partner.id;

    // If the partner is created successfully, we can create a farm and invite the user based on this newly created partner
    if (newPartner && newPartnerId) {
      handleInviteExistingPartner(email, newPartnerId);
    }
  };

  const handleInviteExistingPartner = async (email: string, partnerId: string) => {
    return await invitePartner({
      variables: {
        email,
        partnerId,
      },
      refetchQueries: [
        {
          query: GET_PARTNER,
          variables: {
            id: partnerId,
          },
        },
      ],
    });
  };

  const defaultFormValues = {
    ...PartnerInviteSchema.default(),
    name: existingPartner?.title,
  };

  return (
    <DialogDefault
      title="Invite partner"
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="xs"
      background="#fff"
      preventCloseOnBackground
      data-cy="partner-form-dialog"
    >
      <DialogContent>
        <ContentWrapper>
          <Formik
            initialValues={defaultFormValues}
            validationSchema={PartnerInviteSchema}
            onSubmit={(values, { setSubmitting }) => {
              if (values.email && values.name) {
                if (isInvitingExistingPartner && existingPartner?.id) {
                  handleInviteExistingPartner(values.email, existingPartner?.id);
                } else {
                  if (values.type) handleInviteNewPartner(values.email, values.name, values.type);
                }
                setSubmitting(false);
              }
            }}
          >
            {({ handleSubmit, setFieldValue, isSubmitting, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {error && (
                    <Box mb={3}>
                      <Alert severity="error">{error}</Alert>
                    </Box>
                  )}

                  <FieldWrapper label="Partner Name" variant="small" required>
                    <Field
                      component={TextField}
                      fullWidth
                      placeholder="Company"
                      name="name"
                      variant="outlined"
                      disabled={isInvitingExistingPartner}
                    />
                  </FieldWrapper>
                  <FieldWrapper
                    mt={2}
                    label="Internal ID"
                    variant="small"
                    tooltip={{
                      helperText:
                        "This should be an unique identifier used to track and manage partner-related data for example within your company's ERP system.",
                      variant: 'INFO',
                    }}
                  >
                    <Field
                      component={TextField}
                      placeholder="Unique identifier (optional)"
                      fullWidth
                      name="externalId"
                      variant="outlined"
                      data-cy="external-id-input"
                    />
                  </FieldWrapper>
                  {!isInvitingExistingPartner && !hidePartnerType && (
                    <FieldWrapper label="Partner type" variant="small" mt={2} required>
                      <ButtonGroup>
                        <ThemeButton
                          size="small"
                          className={values.type === PartnerTypeEnum.SUPPLIER ? 'selected' : ''}
                          onClick={() => setFieldValue('type', PartnerTypeEnum.SUPPLIER)}
                        >
                          Supplier
                        </ThemeButton>
                        <ThemeButton
                          size="small"
                          className={values.type === PartnerTypeEnum.FARM ? 'selected' : ''}
                          onClick={() => setFieldValue('type', PartnerTypeEnum.FARM)}
                        >
                          Farm
                        </ThemeButton>
                      </ButtonGroup>
                    </FieldWrapper>
                  )}

                  <Box mt={2}>
                    <FieldWrapper label="Partner Email" variant="small" required>
                      <Field
                        component={TextField}
                        fullWidth
                        placeholder="contact@partner.com"
                        name="email"
                        variant="outlined"
                      />
                    </FieldWrapper>
                  </Box>
                  <Box my={3}>
                    <ThemeTypography variant="BODY_SMALL" color="GRAY">
                      The partner will receive an email where they can login to the seedtrace
                      platform. There, they can then edit their own information and share data that
                      you request from them.
                    </ThemeTypography>
                  </Box>

                  <Box display="flex">
                    <Box mr={2}>
                      <ThemeButton color="BLUE_ICE" fullWidth size="large" onClick={onClose}>
                        Cancel
                      </ThemeButton>
                    </Box>
                    <ThemeButton
                      color="YELLOW"
                      fullWidth
                      disabled={isSubmitting}
                      startIcon={<ButtonIcon src={emailSendIcon} />}
                      size="large"
                      type="submit"
                    >
                      Send Invite
                    </ThemeButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </ContentWrapper>
      </DialogContent>
    </DialogDefault>
  );
};

export default PartnerInviteDialog;
