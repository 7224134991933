import { Box, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { Search } from '@styled-icons/bootstrap/Search';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { Loader } from 'components/Forms';
import { SearchInput } from 'components/Forms/SearchToolbar';
import PartnerDeleteDialog from 'components/Partners/PartnerDeleteDialog/PartnerDeleteDialog';
import { CloseButton } from 'components/Partners/Partners.styles';
import PartnersTable from 'components/Partners/PartnersTable/PartnersTable';
import PartnerSkeleton from 'components/Partners/Skeleton/PartnerSkeleton';
import { usePartnerMutations } from 'components/Partners/hooks';
import useSubPartners from 'components/Partners/hooks/useSubPartners';
import {
  ActionContainer,
  EmptySearchResults,
  ErrorState,
  InfiniteScrollWrapper,
  PageContainer,
  PageTitle,
} from 'components/Structure';
import { EmptyStateCard } from 'designSystem';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvailableSizes, BackgroundTypes, ItemTypes } from 'types/enums';
import { Partner } from 'types/partner.types';
import usePageTitle from '../components/hooks/usePageTitle';
import { PartnerHeaderColumnEnum } from 'components/Partners/PartnersTable/partnerHeaderColumns';

const Container = styled('div')(() => ({
  position: 'relative',
  minHeight: 50,
  marginTop: 16,

  '& .MuiGrid-item': {
    display: 'flex',
  },
}));

const Partners: FC = () => {
  usePageTitle('Partners');

  const {
    error,
    hasNextPage,
    partners,
    searchTerm,
    partnerToDelete,
    loading,
    handlePageEndReached,
    handleSearchTermChange,
    handleCreatePartner,
    onDeletePartner,
    onEditPartner,
    clearDeletingPartner,
  } = useSubPartners({ useInfiniteScroll: true, useSearchQuery: true });

  const { handleRemovePartner } = usePartnerMutations();
  const navigate = useNavigate();

  const onSelectPartner = (partner: Partner) => {
    navigate(`/partners/${partner.id}`);
  };

  if (error) {
    return <ErrorState />;
  }

  return (
    <PageContainer>
      <PageTitle
        autoBackNavigation
        title="Sub partners"
        goBackLabel="All Partners"
        goBackUrl="/partners"
      />
      <Box mb={3} />

      {loading && <Loader relativeHeightToScreen overlayOpacity />}

      {!loading && (partners.length > 0 || (searchTerm && searchTerm.length > 0)) && (
        <Container>
          <ActionContainer>
            <SearchInput
              setDebouncedState={handleSearchTermChange}
              className="search-input"
              data-cy="partner-search-input"
              delay={500}
              placeholder="Search partner"
              initialValue={searchTerm}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {searchTerm === '' ? (
                      <Search size={12} />
                    ) : (
                      <CloseButton onClick={() => handleSearchTermChange('')}>
                        <Close size={16} />
                      </CloseButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <Box ml={1}>
              <ThemeButton
                size="medium"
                color="YELLOW"
                onClick={handleCreatePartner}
                startIcon={<Plus size={20} />}
                data-cy="create-partner-btn"
                loading={false}
              >
                Add new partner
              </ThemeButton>
            </Box>
          </ActionContainer>

          {partners.length > 0 && (
            <InfiniteScrollWrapper
              dataLength={partners.length}
              hasMore={hasNextPage}
              next={handlePageEndReached}
            >
              <PartnersTable
                disablePagination
                partners={partners}
                onSelect={onSelectPartner}
                onEdit={onEditPartner}
                onDelete={onDeletePartner}
                columns={[
                  PartnerHeaderColumnEnum.TITLE,
                  PartnerHeaderColumnEnum.ACTIVITIES,
                  PartnerHeaderColumnEnum.FARM_SIZE,
                  PartnerHeaderColumnEnum.FARM_HARVEST_WEIGHT,
                  PartnerHeaderColumnEnum.CREATED_TIMESTAMP,
                  PartnerHeaderColumnEnum.ACTIONS,
                ]}
              />
            </InfiniteScrollWrapper>
          )}
        </Container>
      )}

      {!loading &&
        !partners.length &&
        partners.length === 0 &&
        searchTerm &&
        searchTerm.length > 0 && (
          <EmptySearchResults
            message="No search results found"
            action={{
              label: 'Create partner',
              onClick: handleCreatePartner,
            }}
            skeletonComponent={<PartnerSkeleton title={searchTerm || ''} />}
          />
        )}

      {!loading && partners.length === 0 && (!searchTerm || searchTerm?.length === 0) && (
        <Container>
          <Box mt={4}>
            <EmptyStateCard
              onCreateItemClick={handleCreatePartner}
              variant={ItemTypes.PARTNER}
              title="Add your partners to manage and communicate your supply chain"
              size={AvailableSizes.LARGE}
              data-cy="partners-getting-started-card"
              background={BackgroundTypes.WORLD}
              buttonText="Add partner"
            />
          </Box>
        </Container>
      )}

      {partnerToDelete && (
        <PartnerDeleteDialog
          onClose={clearDeletingPartner}
          open={partnerToDelete !== undefined}
          partner={partnerToDelete}
          onSubmit={() => {
            handleRemovePartner({ partnerId: partnerToDelete.id });
            clearDeletingPartner();
          }}
        />
      )}
    </PageContainer>
  );
};

export default Partners;
