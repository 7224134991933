import { useQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { HelpIcon } from 'assets/img/menu';
import { CustomTooltip } from 'components/Structure';
import { useCompanyFeatureFlags, useConfig, useLogEvent, useUserData } from 'components/hooks';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { GET_PARTNER_ONLY } from 'graphql/queries/partners';
import React, { FC, useMemo } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { Booleanish } from 'types/booleanish.types';
import { Partner, PartnerTypeEnum } from 'types/partner.types';
import { getDrawerMenu } from './DrawerUtils';
import {
  MainNavigationButtonExternal,
  NewTabIcon,
  StyledBox,
  StyledList,
  StyledListItem,
  StyledListItemText,
  StyledSvgIcon,
} from './StyledDrawerItems';
import DrawerItem from './utils/DrawerItem';

const PREFIX = 'DrawerMenu';

export const DRAWER_CLASSES = {
  icon: `${PREFIX}-icon`,
  subMenuIcon: `${PREFIX}-subMenuIcon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Container = styled('div')(({ theme }) => ({
  [`& .${DRAWER_CLASSES.icon}`]: {
    flexShrink: 0,
    transition: 'transform 0.125s ease',
    transform: 'translateX(0%)',
  },

  [`& .${DRAWER_CLASSES.subMenuIcon}`]: {
    marginRight: theme.spacing(1),
  },
}));

interface IDrawerProps {
  drawerExpanded: boolean;
  hasOverflow: boolean;
}

const DrawerMenu: FC<IDrawerProps> = ({ drawerExpanded, hasOverflow }) => {
  const { logEvent } = useLogEvent();
  const { helpDeskURL } = useConfig();
  const { company } = useUserData();
  const { plan, isPartner, companyType } = useCompanyPlanContext();
  const isComponentChainMapping = !!useMatch('/component-chains/:id/editor');
  const isInternalDocument = !!useMatch('/documents/internal/:id');
  const isSharedDocument = !!useMatch('/documents/shared/:id');

  const { isCompanyFeatureEnabled } = useCompanyFeatureFlags();
  const isCompliancePageEnabled = isCompanyFeatureEnabled('compliance');
  const isPaymentsPageEnabled = isCompanyFeatureEnabled('payments');
  const isInPartnerLibrary = !!useMatch({ path: '/partners/:id', end: false });

  // If path already contains the id the routing will work in the overview and guide
  const isEUDRComplianceGuideEnabled = !!useMatch({ path: '/compliance/eudr/:id', end: false });

  const { id: urlPartnerId } = useParams<{ id?: string }>();

  const { data: partnerData, loading } = useQuery<{ partner: Partner }>(GET_PARTNER_ONLY, {
    variables: {
      id: urlPartnerId,
    },
    skip: !isInPartnerLibrary || !urlPartnerId,
  });

  const isPartnerTypeFarm =
    companyType === PartnerTypeEnum.FARM ||
    (isInPartnerLibrary && !loading && partnerData?.partner?.type === PartnerTypeEnum.FARM);

  const MENU = useMemo(() => {
    const isCompanyDataValid: boolean =
      company?.dataValid !== undefined ? company.dataValid : false;

    return getDrawerMenu({
      plan,
      isCompanyDataValid,
      isPartnerUI: isPartner,
      isPartnerTypeFarm,
      isComponentChainMapping,
      isInternalDocument,
      isSharedDocument,
      isCompliancePageEnabled,
      isPaymentsPageEnabled,
      isEUDRComplianceGuideEnabled,
    });
  }, [
    plan,
    company,
    isPartner,
    isComponentChainMapping,
    isPartnerTypeFarm,
    isInternalDocument,
    isSharedDocument,
    isCompliancePageEnabled,
    isPaymentsPageEnabled,
    isEUDRComplianceGuideEnabled,
  ]);

  return (
    <Container>
      <StyledList>
        {MENU.map((item, index) => (
          <DrawerItem
            key={`${item.label}-${index}`}
            item={item}
            drawerExpanded={drawerExpanded}
            hasOverflow={hasOverflow}
          />
        ))}
        <MainNavigationButtonExternal
          href={helpDeskURL}
          target="_blank"
          data-cy="menu-link-help"
          data-tut="onboarding_tour__help"
          onClick={() => logEvent('VISIT_HELP_DESK')}
        >
          <CustomTooltip placement="right" title={drawerExpanded ? '' : 'Help'}>
            <StyledBox>
              <StyledListItem>
                <StyledSvgIcon viewBox="0 0 16 16">
                  <HelpIcon color="white" />
                </StyledSvgIcon>
                <StyledListItemText drawer-expanded={Booleanish(drawerExpanded)}>
                  Help
                </StyledListItemText>
                <NewTabIcon size={14} />
              </StyledListItem>
            </StyledBox>
          </CustomTooltip>
        </MainNavigationButtonExternal>
      </StyledList>
    </Container>
  );
};

export default DrawerMenu;
