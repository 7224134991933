import { useMutation } from '@apollo/client';
import { useLogEvent, useMessages } from 'components/hooks';
import {
  CREATE_CHAIN_ACTIVITY,
  CREATE_CHAIN_STEPS,
  CREATE_COMPONENT_CHAIN,
  CREATE_SUB_CHAIN_FROM_STEP_ACTIVITIES,
  DELETE_CHAIN_ACTIVITY,
  DELETE_CHAIN_STEP,
  DELETE_COMPONENT_CHAIN,
  DUPLICATE_CHAIN,
  IMPORT_CHAINS,
  INITIALISE_CHAIN_MAPPING,
  REMOVE_SUB_CHAIN,
  REORDER_SUB_CHAINS,
  UPDATE_CHAIN_ACTIVITY,
  UPDATE_CHAIN_STEP,
  UPDATE_COMPONENT_CHAIN,
} from 'graphql/mutations/chains';
import { UPDATE_ACTIVITY } from 'graphql/mutations/partners';
import {
  ICreateChainActivityInput,
  ICreateChainStepsInput,
  ICreateSubChainFromStepActivitiesInput,
  IDuplicateChainInput,
  IUpdateChainActivityInput,
  IUpdateChainInput,
  IUpdateChainStepInput,
} from 'graphql/mutations/types/chains-mutation.types';
import { GET_CHAINS } from 'graphql/queries';
import { useNavigate } from 'react-router-dom';
import { IChainResponse } from 'types/chain.types';
import { AvailableLanguagesType } from 'types/enums';
import { IActivity } from 'types/partner.types';
import { UpdateActivityInput } from 'types/types';

const useComponentChainActions = () => {
  const { logEvent } = useLogEvent();
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const navigate = useNavigate();

  const [createComponentChain, { loading: createChainLoading }] = useMutation<
    {
      createChain: IChainResponse;
    },
    { input: { partnerId?: string } }
  >(CREATE_COMPONENT_CHAIN, {
    onCompleted: ({
      createChain: {
        chain: { id },
      },
    }) => {
      setSuccessMessage('Success! Your component chain was created.');
      navigate(`/component-chains/${id}/editor`);
    },
    onError: e => {
      setErrorMessage('Something went wrong creating your component chain');
    },
  });

  const [updateComponentChain, { loading: updateComponentChainLoading }] = useMutation<
    {
      updateComponentChain: IChainResponse;
    },
    {
      id: string;
      input: IUpdateChainInput;
    }
  >(UPDATE_COMPONENT_CHAIN, {
    onCompleted: () => {
      setSuccessMessage('Success! Your component chain was updated.');
    },
    onError: e => {
      setErrorMessage('Something went wrong updating your component chain');
    },
  });

  const [createSteps, { loading: createStepsLoading }] = useMutation<
    { createChainSteps: IChainResponse },
    { input: ICreateChainStepsInput }
  >(CREATE_CHAIN_STEPS, {
    onCompleted: () => {
      setSuccessMessage('Success! Your component chain was updated.');
    },
    onError: e => {
      setErrorMessage('Something went wrong updating your component chain');
    },
  });

  const [updateStep, { loading: updateStepLoading }] = useMutation<
    { updateChainStep: IChainResponse },
    { input: IUpdateChainStepInput }
  >(UPDATE_CHAIN_STEP, {
    onCompleted: () => {
      setSuccessMessage('Success! Your component chain was updated.');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your component chain');
    },
  });

  const [deleteStep, { loading: deleteStepLoading }] = useMutation<
    { deleteChainStep: IChainResponse },
    { id: string }
  >(DELETE_CHAIN_STEP, {
    onCompleted: () => {
      setSuccessMessage('Success! Your component chain was updated.');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your component chain');
    },
  });

  const [importSubChains, { loading: importSubChainsLoading }] = useMutation<
    { importChains: IChainResponse },
    { id: string; importChainIds: string[] }
  >(IMPORT_CHAINS, {
    onCompleted: () => {
      logEvent('SUB_CHAIN_IMPORTED');
      setSuccessMessage('Success! Your component chain was updated.');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your component chain');
    },
  });

  const [copyComponentChain, { loading: copyComponentChainLoading }] = useMutation<
    { copyChain: IChainResponse },
    { input: IDuplicateChainInput }
  >(DUPLICATE_CHAIN, {
    onCompleted: () => {
      setSuccessMessage('Success! Your component chain was created.');
    },
    onError: () => {
      setErrorMessage('Something went wrong copying your component chain');
    },
  });

  const [removeSubChain, { loading: removeSubChainLoading }] = useMutation<
    { removeSubChain: IChainResponse },
    {
      id: string;
    }
  >(REMOVE_SUB_CHAIN, {
    onCompleted: () => {
      logEvent('SUB_CHAIN_REMOVED');
      setSuccessMessage('Success! Your component sub chain was deleted.');
    },
    onError: () => {
      setErrorMessage('Something went wrong deleting your component chain');
    },
  });

  const [reorderSubChains, { loading: reorderSubChainsLoading }] = useMutation<
    { reorderSubChains: IChainResponse },
    { id: string; subChainIdsOrdered: string[] }
  >(REORDER_SUB_CHAINS, {
    onCompleted: () => {
      setSuccessMessage('Success! Your reordered the component sub chains.');
    },
    onError: () => {
      setErrorMessage('Something went reordering the component sub chain');
    },
  });

  const [deleteChain, { loading: deleteChainLoading }] = useMutation<
    { deleteChain: { id: string } },
    { id: string }
  >(DELETE_COMPONENT_CHAIN, {
    onCompleted: () => {
      logEvent('SUB_CHAIN_REORDERED');
      setSuccessMessage('Success! Your component chain was deleted.');
    },
    onError: () => {
      setErrorMessage('Something went wrong deleting your component chain');
    },
    // We need to refetch everything here since the isUsed field could change for other chains
    refetchQueries: [GET_CHAINS],
  });

  const [initializeChainMapping, { loading: initializeChainMappingLoading }] = useMutation<
    { initialiseChainMapping: IChainResponse },
    { id: string }
  >(INITIALISE_CHAIN_MAPPING, {
    onCompleted: ({
      initialiseChainMapping: {
        chain: { id },
      },
    }) => {
      navigate(`/component-chains/${id}/editor`);
    },
    onError: () => {
      setErrorMessage('Something went wrong initializing your component chain');
    },
  });

  const [createActivity, { loading: createActivityLoading }] = useMutation<
    { createChainActivity: IChainResponse },
    { input: ICreateChainActivityInput }
  >(CREATE_CHAIN_ACTIVITY, {
    onCompleted: () => {
      setSuccessMessage('Success! Your activity was created.');
    },
    onError: () => {
      setErrorMessage('Something went wrong creating your activity');
    },
  });

  const [updateActivity, { loading: updateActivityLoading }] = useMutation<
    { updateChainActivity: IChainResponse },
    {
      id: string;
      input: IUpdateChainActivityInput;
    }
  >(UPDATE_CHAIN_ACTIVITY, {
    onCompleted: () => {
      setSuccessMessage('Success! Your activity was changed.');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your activity');
    },
  });

  const [deleteActivity, { loading: deleteActivityLoading }] = useMutation<
    { deleteChainActivity: IChainResponse },
    { id: string }
  >(DELETE_CHAIN_ACTIVITY, {
    onCompleted: () => {
      setSuccessMessage('Success! Your activity was deleted.');
    },
    onError: () => {
      setErrorMessage('Something went wrong deleting your activity');
    },
  });

  const [updateActivityPartner, { loading: updateActivityPartnerLoading }] = useMutation<
    { updateActivity: { activity: IActivity } },
    { id: string; input: UpdateActivityInput; lang?: AvailableLanguagesType }
  >(UPDATE_ACTIVITY, {
    onCompleted: () => {
      setSuccessMessage('Partner successfully updated');
    },
    onError: () => setErrorMessage('There was an error updating your partner'),
  });

  const [createSubChainFromStepActivities, { loading: createSubChainFromStepActivitiesLoading }] =
    useMutation<
      { createSubChainFromStepActivities: IChainResponse },
      { input: ICreateSubChainFromStepActivitiesInput }
    >(CREATE_SUB_CHAIN_FROM_STEP_ACTIVITIES, {
      onCompleted: () => {
        logEvent('SUB_CHAIN_FROM_STEP_ACTIVITY_CREATED');
        setSuccessMessage('Success! Your component chain was updated.');
      },
      onError: event => {
        if (event.message === 'CHAIN_IMPORT_TOO_DEEP') {
          setErrorMessage(
            'The sub chain you are trying to create is too deep. Please create a sub chain from the parent chain.'
          );
        } else {
          setErrorMessage('Something went wrong updating your component chain');
        }
      },
    });

  const loading =
    createChainLoading ||
    updateComponentChainLoading ||
    importSubChainsLoading ||
    removeSubChainLoading ||
    reorderSubChainsLoading ||
    createStepsLoading ||
    updateStepLoading ||
    deleteStepLoading ||
    deleteChainLoading ||
    initializeChainMappingLoading ||
    createActivityLoading ||
    updateActivityLoading ||
    deleteActivityLoading ||
    updateActivityPartnerLoading ||
    createSubChainFromStepActivitiesLoading ||
    copyComponentChainLoading;

  return {
    loading,
    updateStep,
    createSteps,
    deleteStep,
    removeSubChain,
    importSubChains,
    deleteChain,
    createActivity,
    updateActivity,
    deleteActivity,
    createComponentChain,
    reorderSubChains,
    updateActivityPartner,
    updateComponentChain,
    initializeChainMapping,
    copyComponentChain,
    createSubChainFromStepActivities,
  };
};

export default useComponentChainActions;
