import { styled } from '@mui/material/styles';
import { Position } from '@turf/turf';
import { useQueryReturn } from 'components/hooks';
import CustomMap from 'designSystem/Map/CustomMap';
import { DEFAULT_ZOOM_TO_LOCATION_LEVEL } from 'designSystem/Map/utils/map.config';
import { GET_PLACES } from 'graphql/queries';
import get from 'lodash/get';
import React, { FC, useEffect, useState } from 'react';
import { MapEvent, MapLayerMouseEvent, MarkerDragEvent } from 'react-map-gl';

interface ISelectableMapProps {
  field: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    name: string;
  };
  form: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (name: string, value: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
  };
  locationNameKey?: string;
}

const MapContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
}));

const SelectableMap: FC<ISelectableMapProps> = ({ ...props }) => {
  const {
    field: { value, name },
    form: { setFieldValue },
    locationNameKey = 'activity.name',
  } = props;
  const [mapRef, setMapRef] = useState<MapEvent['target'] | null>(null);

  const [markerCoordinates, setMarkerCoordinates] = useState<Position>();

  const getLocation = useQueryReturn(GET_PLACES, {
    fetchPolicy: 'no-cache',
  });
  const request = (query: unknown) =>
    getLocation({
      variables: {
        query,
      },
    });

  useEffect(() => {
    // The default validation is run for name, that's why the field name is `name`
    // But to get the coordinates we have them stored in `locationCoordinates`
    const lat = get(props.form?.values, name)?.lat;
    const lng = get(props.form?.values, name)?.lng;
    if (lat && lng) {
      mapRef?.flyTo({
        center: [lng, lat],
        screenSpeed: 1,
        maxDuration: 1,
        zoom: DEFAULT_ZOOM_TO_LOCATION_LEVEL,
      });
      setMarkerCoordinates([lng, lat]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, mapRef]);

  const handleMoveMarker = async (event: MapLayerMouseEvent | MarkerDragEvent) => {
    const {
      lngLat: { lng, lat },
    } = event;
    if (lat && lng) {
      mapRef?.flyTo({
        center: [lng, lat],
        screenSpeed: 1,
        maxDuration: 3000,
        zoom: DEFAULT_ZOOM_TO_LOCATION_LEVEL,
      });
      setMarkerCoordinates([lng, lat]);
    }
    const { data } = await request(`${lng},${lat}`);

    if (data.getPlaces.length !== 0) {
      const location = data.getPlaces[0];

      setFieldValue(name, {
        lat,
        lng,
      });
      setFieldValue(locationNameKey, location.title);
    }
  };

  return (
    <MapContainer>
      <CustomMap
        markers={markerCoordinates ? [markerCoordinates] : undefined}
        markerOptions={{ draggable: true }}
        style={{
          height: '100%',
          width: '100%',
        }}
        config={{
          disableFullscreenControl: true,
        }}
        onClick={handleMoveMarker}
        onMarkerDragEnd={handleMoveMarker}
        onMapLoad={setMapRef}
      />
    </MapContainer>
  );
};

export default SelectableMap;
