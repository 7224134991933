import { Box, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoTooltip from 'designSystem/DataDisplay/InfoTooltip/InfoTooltip';
import HTMLLinkDialog from 'designSystem/Overlays/HTMLLinkDialog/HTMLLinkDialog';
import { Editor, EditorState, RichUtils } from 'draft-js';
import { Options, stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { FieldProps, getIn } from 'formik';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import theme from 'styles/theme';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { AvailableLanguagesType, AvailableSizes } from 'types/enums';
import { useDebouncedCallback } from 'use-debounce';
import { LANGUAGES } from 'utils/language.utils';
import HTMLEditorButton from './HTMLEditorButton';
import InlineStyleControls from './InlineStyleControls';
import LinkDecorator from './LinkDecorator';

interface IHTMLEditorFieldProps {
  charLimit?: number;
  placeholder?: string;
  minHeight?: number;
  disabled?: boolean;
  'data-cy'?: string;
}

type Status = 'default' | 'error' | 'focused';

const BORDER_COLOR: Record<Status, string> = {
  default: theme.custom.colors.lightestBorder,
  error: theme.palette.error.main,
  focused: theme.palette.primary.main,
};

const StyledEditorContainer = styled(Box)<{ 'min-height'?: number; status: Status }>(
  // @ts-ignore - Need since overflowWrap type is not allowing the important props (otherwise the maxWidth could overflow if no spaces are included)
  ({ 'min-height': minHeight, status = 'default', theme }) => ({
    position: 'relative',
    background: '#fff',
    border: '1px solid',
    borderColor: BORDER_COLOR[status],
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),

    '& .public-DraftEditor-content': {
      minHeight,
      overflowWrap: 'anywhere !important',
    },
  })
);

const ControlContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .tooltip-url': {
    margin: theme.spacing(0, 0.5),
  },
}));

const CharacterLimit = styled(FormHelperText)<{ visible: booleanish }>(({ visible }) => ({
  position: 'absolute',
  right: 0,
  bottom: -20,
  opacity: visible === 'true' ? 1 : 0,
  transform: visible === 'true' ? 'translateY(0)' : 'translateY(-20px)',
  transition: 'all 0.06s ease-out',
}));

const StyledEditor = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.custom.colors.lightestBorder}`,
  paddingTop: theme.spacing(1),
}));

const Flag = styled('img')<{ visible: booleanish }>(({ visible }) => ({
  position: 'absolute',
  right: 0,
  top: -22,
  borderRadius: 2,
  width: 20,
  opacity: visible === 'true' ? 1 : 0,
  transform: visible === 'true' ? 'translateY(0)' : 'translateY(22px)',
  transition: 'all 0.06s ease-out',
}));

const HTMLEditorField: FC<IHTMLEditorFieldProps & FieldProps<string>> = ({
  charLimit = 650,
  placeholder,
  minHeight,
  disabled,
  'data-cy': dataCy = 'html-editor',
  ...props
}) => {
  const {
    field: { name, value: initialValue },
    form: { touched, setFieldValue, errors, validateOnMount },
  } = props;
  const lang: AvailableLanguagesType = props.form.status?.lang;

  const [focused, setFocused] = useState<boolean>(false);
  const [linkDialog, setLinkDialog] = useState<string | undefined>(undefined);
  const [showURLInput, setShowURLInput] = useState<boolean>(false);

  const makeEditorState = (htmlString: string) =>
    EditorState.createWithContent(stateFromHTML(htmlString), LinkDecorator);
  const [editorState, setEditorState] = useState<EditorState>(makeEditorState(initialValue));

  const fieldError = getIn(errors, name);
  const showError = (getIn(touched, name) || validateOnMount) && !!fieldError;

  const currentTextLength = useMemo(
    () => (editorState.getCurrentContent().getPlainText() || '').length,
    [editorState]
  );

  const fieldStatus = useMemo(() => {
    if (showError) {
      return 'error';
    }
    if (focused) {
      return 'focused';
    }
    return 'default';
  }, [showError, focused]);

  // if text already exists and it is longer than the limit, then the limit is not applied
  const maxChars = useMemo(() => {
    if (currentTextLength <= charLimit) {
      return charLimit;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charLimit]);

  useEffect(() => {
    if (focused) return;

    setEditorState(makeEditorState(initialValue));
  }, [initialValue, focused]);

  const onChange = useCallback(
    (newState: EditorState) => {
      const contentState = newState.getCurrentContent();
      const oldContent = editorState.getCurrentContent();
      if (
        !maxChars ||
        contentState <= oldContent ||
        (contentState.getPlainText() || '').length <= charLimit
      ) {
        setEditorState(newState);
        debouncedSetFieldValue.callback(newState);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorState, maxChars, charLimit]
  );

  const debouncedSetFieldValue = useDebouncedCallback((state: EditorState) => {
    const options: Options = {
      entityStyleFn: entity => {
        const entityType = entity.getType().toLowerCase();
        if (entityType === 'link') {
          const data = entity.getData();
          return {
            element: 'a',
            attributes: {
              href: data.url,
              target: '_blank',
            },
          };
        }
      },
    };
    setFieldValue(name, stateToHTML(state.getCurrentContent(), options));
  }, 250);

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);

      return 'handled';
    }

    return 'not-handled';
  };

  const toggleInlineStyle = (inlineStyle: string) => {
    if (!disabled) {
      onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    }
  };

  const promptForLink = () => {
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const selected = currentContentBlock.getText().slice(start, end);
    // openDialog({ type: 'HTML_LINK', props: { linkText: selected, onSubmit: handleSubmitLink } });
    setLinkDialog(selected);
    if (!selection.isCollapsed()) {
      setShowURLInput(true);
    }
  };

  const handleSubmitLink = (event: { url?: string }) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: event.url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    let nextEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    nextEditorState = RichUtils.toggleLink(
      nextEditorState,
      nextEditorState.getSelection(),
      entityKey
    );
    setShowURLInput(false);
    setEditorState(nextEditorState);
  };

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const checkCharacterLimit = useCallback(
    (chars: string) => {
      if (!chars) return 'handled';

      const selectedText = getLengthOfSelectedText() || 0;
      const totalLength =
        editorState.getCurrentContent().getPlainText().length + chars.length - selectedText;

      return totalLength > charLimit ? 'handled' : 'not-handled';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorState, charLimit]
  );

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    if (!showURLInput) {
      setFocused(false);
    }
  }, [showURLInput]);

  return (
    <>
      {/* TODO: Remove this and use the dialog context instead as soon as the import issue is resolved */}
      {!!linkDialog && (
        <HTMLLinkDialog
          open={!!linkDialog}
          linkText={linkDialog}
          onSubmit={handleSubmitLink}
          onClose={() => setLinkDialog(undefined)}
        />
      )}
      <StyledEditorContainer data-cy={dataCy} status={fieldStatus} min-height={minHeight}>
        <ControlContainer>
          <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
          <HTMLEditorButton label="Add Link" style={undefined} onToggle={promptForLink} />
          <Box mb={1} ml={1}>
            <InfoTooltip
              className="tooltip-url"
              size={AvailableSizes.SMALL}
              text="To create a clickable url link please mark the desired text."
            />
          </Box>
        </ControlContainer>
        <StyledEditor>
          <Editor
            editorState={editorState}
            placeholder={placeholder}
            readOnly={disabled}
            handleKeyCommand={handleKeyCommand}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
            handleBeforeInput={(charLimit && checkCharacterLimit) || undefined}
            handlePastedText={(charLimit && checkCharacterLimit) || undefined}
            {...props}
          />
        </StyledEditor>
        {lang && <Flag visible={Booleanish(focused)} src={LANGUAGES[lang].flag} />}
        {maxChars && (
          <CharacterLimit
            visible={Booleanish(focused)}
          >{`${currentTextLength}/${charLimit}`}</CharacterLimit>
        )}
      </StyledEditorContainer>
      {showError && <FormHelperText error>{fieldError}</FormHelperText>}
    </>
  );
};

export default HTMLEditorField;
