import { gql } from '@apollo/client';
import {
  MINIMAL_VERSION_PROPS_FRAGMENT,
  IMAGE_VARIANT_FRAGMENT,
  IMPACT_FACTS_FRAGMENT,
  PRODUCTS_FRAGMENT,
  PRODUCT_JOURNEY_FRAGMENT,
} from 'graphql/fragments';

export const UPDATE_PRODUCT_JOURNEY = gql`
  mutation UpdateProductJourney($id: UUID!) {
    updateProductJourney(id: $id) {
      version {
        ...minimalVersionProperties
        contentBlocks(contentTypes: [PRODUCT_JOURNEY_BLOCK]) {
          ... on ProductJourney {
            ...ProductJourneyValues
          }
        }
      }
    }
  }
  ${MINIMAL_VERSION_PROPS_FRAGMENT}
  ${PRODUCT_JOURNEY_FRAGMENT}
`;

export const UPDATE_ABOUT = gql`
  mutation UpdateAbout($id: UUID!, $input: AboutBlockDataInput!, $lang: Language) {
    updateAboutBlock(id: $id, input: $input, lang: $lang) {
      aboutBlockData {
        id
        headline
        description
        image {
          ...imageVariant
        }
      }
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const UPDATE_IMPACT_FACTS = gql`
  mutation UpdateImpactFacts($id: UUID!, $input: ImpactFactsBlockDataInput!, $lang: Language) {
    updateImpactFactsBlock(id: $id, input: $input, lang: $lang) {
      version {
        ...minimalVersionProperties
        contentBlocks(contentTypes: [IMPACT_FACTS_BLOCK]) {
          ... on ImpactFactsBlock {
            ...ImpactFactsValues
          }
        }
      }
    }
  }
  ${MINIMAL_VERSION_PROPS_FRAGMENT}
  ${IMPACT_FACTS_FRAGMENT}
`;

export const UPDATE_MORE_INFO_BLOCK = gql`
  mutation UpdateMoreInfoBlock($id: UUID!, $input: MoreInfoBlockDataInput!, $lang: Language) {
    updateMoreInfoBlock(id: $id, input: $input, lang: $lang) {
      moreInfoBlockData {
        id
        blockTitle
        description
        headline
        mediaItem {
          ... on ImageVariant {
            ...imageVariant
          }
          ... on Video {
            vimeoLink
            videoId: id
          }
        }
      }
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const UPDATE_VERIFIED_PAYMENT_BLOCK = gql`
  mutation UpdateVerifiedPaymentsBlock(
    $id: UUID!
    $input: VerifiedPaymentsBlockDataInput!
    $lang: Language
  ) {
    updateVerifiedPaymentsBlock(id: $id, lang: $lang, input: $input) {
      verifiedPaymentsBlockData {
        id
        headline
        description
        image {
          ...imageVariant
        }
      }
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const UPDATE_FEEDBACK_BLOCK = gql`
  mutation UpdateFeedbackBlock($id: UUID!, $input: FeedbackBlockDataInput!, $lang: Language) {
    updateFeedbackBlock(id: $id, input: $input, lang: $lang) {
      feedbackBlockData {
        id
        ratingEnabled
        headline
        subtitle
      }
    }
  }
`;

export const UPDATE_PRODUCTS_BLOCK = gql`
  mutation UpdateProductsBlock($id: UUID!, $input: ProductDataInput!, $lang: Language) {
    updateProductsBlock(id: $id, input: $input, lang: $lang) {
      version {
        ...minimalVersionProperties
        contentBlocks(contentTypes: [PRODUCTS_BLOCK]) {
          ... on ProductsBlock {
            ...ProductsValues
          }
        }
      }
    }
  }
  ${MINIMAL_VERSION_PROPS_FRAGMENT}
  ${PRODUCTS_FRAGMENT}
`;
