import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CardImage } from '@styled-icons/bootstrap/CardImage';
import { Upload } from '@styled-icons/bootstrap/Upload';
import { FolderVideo } from '@styled-icons/entypo/FolderVideo';
import { Loader } from 'components/Forms';
import { useSelectable } from 'components/MediaLibrary/hooks';
import { ErrorState, InfiniteScrollWrapper } from 'components/Structure';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { GET_IMAGES, GET_VIDEOS } from 'graphql/queries';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import ImageList from './ImageList';
import LibraryFilterBar from './LibraryFilterBar';
import LibraryItemDetail from './LibraryItemDetail';
import VideoList from './VideoList';
import { CardIllustration, CardContainer } from 'designSystem';

const LibraryContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
}));

const CardWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const CardIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.grey[300],
  display: 'flex',
  padding: theme.spacing(0, 2, 2, 0),
  gap: theme.spacing(3),
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MediaContainer = styled(({ scroll, ...props }) => <div {...props} />)(({ scroll }) => ({
  marginBottom: 0,
  minHeight: 400,
  overflowY: scroll ? 'auto' : 'hidden',
  overflowX: 'hidden',
}));

const Error = styled(rest => <ErrorState {...rest} />)({
  minHeight: 200,
  width: '100%',
});

const DetailColumn = styled('div')(() => ({
  width: 375,
}));

const ContainerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  paddingTop: theme.spacing(3),
  overflow: 'auto',
}));

const ItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
  alignContent: 'baseline',
  gap: theme.spacing(2),
}));

const Library = ({ windowScroll, withUploadModal, hideMediaIds = [] }) => {
  const { selectableMedia, selectedItem, onUnselect, setUploadActive } = useSelectable();
  const [selectedItemTypes, setSelectedItemTypes] = useState(selectableMedia);

  const hasSeletedItem = !isEmpty(selectedItem) && !selectedItem.isTranscoding;
  const scrollableTarget = windowScroll ? null : 'infiniteScrollContainer';

  const { data: videoData, loading: loadingVideos, error: videoError } = useQuery(GET_VIDEOS);

  const {
    fetchMore: fetchMoreImages,
    data: imageData,
    loading: loadingImages,
    error: imageError,
  } = useQuery(GET_IMAGES, {
    variables: {
      first: 21,
    },
  });

  const isMissingVideos =
    videoData?.getVideos.edges === undefined || !videoData?.getVideos.edges.length ? true : false;

  const isMissingImages =
    imageData?.getImages.edges === undefined || !imageData?.getImages.edges.length ? true : false;

  const isLoading = loadingVideos || loadingImages;
  const isMissingData = isMissingVideos || isMissingImages;

  if (isLoading && isMissingData) {
    return <Loader />;
  }

  if (videoError || imageError) {
    return <Error />;
  }

  if (isMissingVideos && isMissingImages) {
    return (
      <CardWrapper data-cy="media-library-empty-state">
        <CardContainer
          title="Upload image and video material to make your product stories more engaging"
          size="LARGE"
          data-cy="documents-getting-started-card"
          background="WORLD"
          renderIllustration={
            <CardIllustration image="MEDIA" width={400} right={0} top={0} float="right" />
          }
        >
          <CardIconWrapper>
            <CardImage size={100} />
            <FolderVideo size={100} />
          </CardIconWrapper>

          <Box display="flex" gap={1.5}>
            <ThemeButton
              color="YELLOW"
              size="large"
              onClick={() => setUploadActive(true)}
              startIcon={<Upload size={20} />}
              data-cy="create-documents-btn-getting-started"
            >
              Upload Media
            </ThemeButton>
          </Box>
        </CardContainer>
      </CardWrapper>
    );
  }

  const images = imageData.getImages.edges.filter(({ node }) => !hideMediaIds.includes(node.id));
  const videos = videoData.getVideos.edges.filter(
    ({ node }) => !hideMediaIds.includes(node.videoId)
  );
  const { endCursor, hasNextPage } = imageData.getImages.pageInfo;

  return (
    <LibraryContainer>
      <LibraryFilterBar
        selectedItemTypes={selectedItemTypes}
        selectableTypes={selectableMedia}
        setUploadActive={setUploadActive}
        onItemTypeChange={value => {
          setSelectedItemTypes(value);
          onUnselect();
        }}
        // If the uploadmodal functionality is disabled, we also want to hide the upload button from the filter bar.
        hideUploadButton={withUploadModal ? false : true}
      />

      <ContainerWrapper>
        <Box
          position="relative"
          display="flex"
          alignItems="stretch"
          flexDirection="column"
          flex={1}
        >
          <MediaContainer id="infiniteScrollContainer" scroll={!windowScroll}>
            <InfiniteScrollWrapper
              hasMore={hasNextPage}
              dataLength={images.length + videos.length}
              scrollThreshold={0.9}
              scrollableTarget={scrollableTarget}
              next={() => {
                fetchMoreImages({
                  variables: {
                    after: endCursor,
                  },
                });
              }}
            >
              <ItemContainer data-cy="scroll-item-container">
                {selectedItemTypes.includes('video') && videos && <VideoList videos={videos} />}
                {selectedItemTypes.includes('image') && images && <ImageList images={images} />}
              </ItemContainer>
            </InfiniteScrollWrapper>
          </MediaContainer>
        </Box>

        {withUploadModal && (
          <DetailColumn>
            {hasSeletedItem && (
              <LibraryItemDetail key={selectedItem.id} item={selectedItem} sticky={windowScroll} />
            )}
          </DetailColumn>
        )}
      </ContainerWrapper>
    </LibraryContainer>
  );
};

Library.propTypes = {
  windowScroll: PropTypes.bool,
  withUploadModal: PropTypes.bool,
  hideMediaIds: PropTypes.arrayOf(PropTypes.string),
};

export default memo(Library);
