import React, { useEffect } from 'react';
import { AuthHeadline, AuthLayout, RegistrationForm, ResendVerification } from 'components/Auth';
import { REGISTER_USER, RESEND_USER_VERIFICATION_MAIL } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { useMessages, useLogEvent, useLogEventOnMount } from 'components/hooks';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { SLIM_PLAN_IDENTIFIER } from 'utils/companyPlan.utils';
import { useLocation, useNavigate } from 'react-router-dom';
import useAutoLogout from 'components/hooks/useAutoLogout';
import { RegisterValues } from 'types/types';

export const Subtitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: '#fff',
}));

const Register = () => {
  const navigate = useNavigate();
  const [signedUp, setSignedUp] = useQueryParam('registered', BooleanParam);
  const [email, setEmail] = useQueryParam('email', StringParam);
  const [planQueryParam] = useQueryParam('customerPlanId', StringParam);

  const { pathname } = useLocation();
  const { logEvent } = useLogEvent();
  const isSlimPlanRegistration = pathname === '/register/light';

  useLogEventOnMount('VIEW_REGISTER');

  // Logout user when still found
  useAutoLogout();

  useEffect(() => {
    // For backwards compatiblity
    // Checks if the planQueryParam exists and matches the Slim plan.
    // redirects the user to the register/light path to continue.
    if (planQueryParam && planQueryParam === SLIM_PLAN_IDENTIFIER) {
      navigate('/register/light');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planQueryParam]);

  const { setErrorMessage } = useMessages();

  const [register] = useMutation(REGISTER_USER, {
    onError: error => setErrorMessage(error.message),
    onCompleted: () => {
      setSignedUp(true);
      logEvent('USER_REGISTERED');
    },
    fetchPolicy: 'no-cache',
  });

  const onSubmit = (values: RegisterValues) => {
    setEmail(values.email);

    if (isSlimPlanRegistration) {
      return register({
        variables: { input: { ...values, customerPlanId: SLIM_PLAN_IDENTIFIER } },
      });
    } else {
      return register({ variables: { input: values } });
    }
  };

  return (
    <AuthLayout title="Register">
      <AuthHeadline text="Welcome to" highlighted="seedtrace" />
      {signedUp ? (
        <ResendVerification
          title="Verify your email address"
          subtitle={`We have sent a verification link to ${email}. It might take a
            moment for the mail to arrive. Please check your inbox and spam
            folder.`}
          email={email || ''}
          mutation={RESEND_USER_VERIFICATION_MAIL}
        />
      ) : (
        <Box mt={5}>
          <RegistrationForm onSubmit={onSubmit} />
        </Box>
      )}
    </AuthLayout>
  );
};

export default Register;
