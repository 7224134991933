import Bugsnag from '@bugsnag/js';
import { styled } from '@mui/material';
import { useUserData } from 'components/hooks';
import React, { FC, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { convertHexToRGBarray } from 'utils';

const ButtonContainer = styled('button')(({ theme }) => ({
  backgroundColor: `rgba(${convertHexToRGBarray(theme.palette.primary.main)}, 0.2)`,
  border: 'none',
  boxShadow: 'none',
  padding: '12px 18px',
  borderRadius: '50px',
  cursor: 'pointer',
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
  fontWeight: 700,
  fontSize: '13px',
  zIndex: 10,

  '& .beamer_icon.active': {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    width: 19,
    height: 19,
    lineHeight: '19px',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: 6,
  },
}));

const FeatureAnnouncements: FC = () => {
  const { user } = useUserData();

  if (!process.env.REACT_APP_BEAMER_PRODUCT_ID) {
    console.error('Missing .env variable REACT_APP_BEAMER_PRODUCT_ID');
    return null;
  }

  window.beamer_config = {
    product_id: process.env.REACT_APP_BEAMER_PRODUCT_ID,
    selector: 'feature-announcements',
    display: 'right',
    button_position: 'top-right',
    language: 'EN',
    lazy: false,
    user_id: user?.id,
    onerror: err => {
      console.error(err);
      Bugsnag.notify(err);
    },
  };

  return (
    <Fragment>
      <ButtonContainer id="feature-announcements">What&#39;s new?</ButtonContainer>
      <Helmet>
        <script
          type="text/javascript"
          src="https://app.getbeamer.com/js/beamer-embed.js"
          defer={true}
        ></script>
      </Helmet>
    </Fragment>
  );
};

export default FeatureAnnouncements;
