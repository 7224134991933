import { Grid, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FieldWrapper, ThemeButton } from 'designSystem';
import { useConfig } from 'components/hooks';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import AuthHeadline from './AuthHeadline';
import PasswordInputField from './PasswordInputField';

const Options = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
});

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required('This field is required'),
  password: Yup.string().required('This field is required'),
});

const LoginForm = ({ onSubmit }) => {
  const { bookDemoUrl } = useConfig();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validateOnBlur={false}
      validationSchema={LoginSchema}
      onSubmit={values => onSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Fragment>
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AuthHeadline text="Welcome to" highlighted="seedtrace" />
              </Grid>
              <Grid item xs={8}>
                <FieldWrapper label="Email" inverted>
                  <Field
                    component={TextField}
                    fullWidth
                    placeholder="example@company.com"
                    name="email"
                    variant="outlined"
                  />
                </FieldWrapper>
              </Grid>
              <Grid item xs={8}>
                <FieldWrapper label="Password" inverted>
                  <Field
                    fullWidth
                    component={PasswordInputField}
                    name="password"
                    variant="outlined"
                  />
                </FieldWrapper>
              </Grid>
              <Grid item xs={12}>
                <ThemeButton
                  loading={isSubmitting}
                  size="large"
                  color="YELLOW"
                  type="submit"
                  className="button-wide"
                >
                  Login
                </ThemeButton>
              </Grid>
              <Options item md={10} xs={10}>
                <Link target="_blank" href={bookDemoUrl} color="secondary">
                  Not registered? Book a demo
                </Link>
                <Link component={RouterLink} to="/password-forgot" color="secondary">
                  Forgot password?
                </Link>
              </Options>
            </Grid>
          </Form>
        </Fragment>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
