import {
  ClaimIcon,
  ClaimsIcon,
  CompliancePlaceholderIcon,
  DocumentPlaceholderIcon,
  HomeIcon,
  ImagesIcon,
  ImpactClaimPlaceholderIcon,
  PartnerIcon,
  PartnerPlaceholderIcon,
  PartnersIcon,
  ProductPlaceholderIcon,
  SettingsIcon,
} from 'assets/img/menu';
import DocumentTitle from 'components/DocumentLibrary/DocumentTitle';
import DrawerComponentItemIcon from 'components/Drawer/utils/DrawerComponentItemIcon';
import DrawerComponentItemTitle from 'components/Drawer/utils/DrawerComponentItemTitle';
import { ClaimTitle } from 'components/ImpactClaims/ClaimShared';
import PartnerName from 'components/Partners/PartnerName/PartnerName';
import { ProductTitle } from 'components/Product';
import { IconNameType } from 'designSystem/Primitives/Icon/Icon';
import React, { CSSProperties, FC } from 'react';
import theme from 'styles/theme';
import { ApplicationPlan } from 'types/companyTypes';

export type DrawerSubMenuItem = {
  label: string;
  to: (params: { [key: string]: string }) => string;
  activeRoutes?: string[];
  visible?: boolean;
} & (
  | {
      /**
       * Use the iconName instead
       * @deprecated
       */
      Icon: FC;
      /**
       * Remove this prop when we have a better solution for defining svg colors
       * @deprecated
       */
      activeClassStyle?: CSSProperties | { [key: string]: CSSProperties };
    }
  | { iconName: IconNameType }
);

export type DrawerMenuItem = {
  label: string;
  to: string;
  submenu?: DrawerSubMenuItem[];
  submenuPaths?: string[];
  /**
   * If the main page has multiple sub routes
   */
  paths?: string[];
  params?: string[];
  SubmenuTitle?: FC;
  SubmenuIcon?: FC;
  showWarning?: boolean;
  exact?: boolean;
  dividerAbove?: boolean;
  sectionLabelAbove?: string;
  visible: boolean;
} & (
  | {
      /**
       * Use the iconName instead
       * @deprecated
       */
      Icon: FC;
    }
  | { iconName: IconNameType }
);

export const getDrawerMenu = ({
  isCompanyDataValid,
  plan,
  isPartnerUI,
  isComponentChainMapping,
  isPartnerTypeFarm,
  isSharedDocument,
  isInternalDocument,
  isCompliancePageEnabled,
  isPaymentsPageEnabled,
  isEUDRComplianceGuideEnabled,
}: {
  isCompanyDataValid: boolean;
  plan: ApplicationPlan;
  // TODO: Instead of passing these items as a boolean, we could pass the whole active menu items
  isPartnerUI: boolean;
  isPartnerTypeFarm: boolean;
  // TODO: Workaround to only show the correct submenu item in the drawer. Refactor that
  isComponentChainMapping: boolean;
  isInternalDocument: boolean;
  isSharedDocument: boolean;
  isCompliancePageEnabled: boolean;
  isPaymentsPageEnabled: boolean;
  isEUDRComplianceGuideEnabled: boolean;
}): DrawerMenuItem[] => {
  const fullPlan = plan === 'FULL_PLAN';

  return [
    {
      label: 'Home',
      Icon: HomeIcon,
      exact: true,
      to: '/',
      visible: fullPlan,
    },
    // Management section
    {
      label: 'Supply chains',
      iconName: 'supply-chain',
      to: '/supply-chains',
      params: ['productId', 'chainId'],
      submenuPaths: [
        '/supply-chains/:productId/:chainId',
        '/supply-chains/:productId/:chainId/editor',
      ],
      sectionLabelAbove: 'Management',
      SubmenuTitle: ProductTitle,
      SubmenuIcon: ProductPlaceholderIcon,
      submenu: [
        {
          label: 'Supply chain overview',
          to: ({ productId, chainId }) => `/supply-chains/${productId}/${chainId}`,
          iconName: 'supply-chain',
          activeRoutes: ['/supply-chains/:productId/:chainId'],
          visible: true,
        },
        {
          label: 'Supply chain mapping',
          to: ({ productId, chainId }) => `/supply-chains/${productId}/${chainId}/editor`,
          iconName: 'supply-chain-edit',
          activeRoutes: ['/supply-chains/:productId/:chainId/editor'],
          visible: true,
        },
      ],
      visible: !isPartnerUI,
    },
    {
      label: 'Components',
      iconName: 'components',
      to: '/components',
      params: ['id'],
      paths: ['/components', '/component-chains'],
      submenuPaths: ['/components/:id', '/component-chains/:id/editor'],
      SubmenuTitle: DrawerComponentItemTitle,
      SubmenuIcon: DrawerComponentItemIcon,
      submenu: [
        {
          label: 'Component overview',
          to: ({ id }) => `/components/${id}`,
          iconName: 'component',
          visible: !isComponentChainMapping,
        },
        {
          label: 'Component chain mapping',
          to: ({ id }) => `/component-chains/${id}/editor`,
          iconName: 'component-chain',
          visible: isComponentChainMapping,
        },
      ],
      visible: true,
    },
    {
      label: 'Partners',
      Icon: PartnersIcon,
      to: '/partners',
      submenuPaths: [
        '/partners/:id',
        '/partners/:id/components',
        '/partners/:id/components/:componentId',
        '/partners/:id/component-chains',
        '/partners/:id/sub-partners',
        '/partners/:id/activities',
        '/partners/:id/documents',
        '/partners/:id/documents/:documentId',
        '/partners/:id/requests',
        '/partners/:id/requests/:requestId',
        '/partners/:id/imports',
        '/partners/:id/imports/farms',
        '/partners/:id/imports/farms/:importId',
      ],
      params: ['id', 'componentId', 'importId'],
      exact: true,
      visible: fullPlan,
      SubmenuTitle: PartnerName,
      SubmenuIcon: PartnerPlaceholderIcon,
      submenu: [
        {
          label: 'Partner overview',
          to: ({ id }) => `/partners/${id}`,
          Icon: PartnerIcon,
          activeClassStyle: {}, // Empty object to override default svg color
          visible: true,
        },
        {
          label: 'Partner components',
          to: ({ id }) => `/partners/${id}/components`,
          Icon: PartnerIcon,
          activeRoutes: ['/partners/:id/components/:componentId', '/partners/:id/component-chains'],
          visible: !isPartnerUI && !isPartnerTypeFarm,
        },
        {
          label: 'Sub partners',
          to: ({ id }) => `/partners/${id}/sub-partners`,
          Icon: PartnerIcon,
          visible: !isPartnerUI && !isPartnerTypeFarm, // Only accessible for clients and partner of type supplier
        },
        {
          label: 'Partner activities',
          to: ({ id }) => `/partners/${id}/activities`,
          iconName: 'locations',
          visible: true,
        },
        {
          label: 'Partner documents',
          to: ({ id }) => `/partners/${id}/documents`,
          iconName: 'documents',
          activeRoutes: ['/partners/:id/documents/:documentId'],
          activeClassStyle: { '& path': { fill: theme.palette.secondary.main } },
          visible: !isPartnerUI,
        },
        {
          label: 'Partner requests',
          to: ({ id }) => `/partners/${id}/requests`,
          iconName: 'document-request-pending',
          visible: !isPartnerUI,
          activeRoutes: ['/partners/:id/requests/:requestId'],
          activeClassStyle: { '& path': { fill: theme.palette.secondary.main } },
        },
        {
          label: 'Data Import',
          iconName: 'import',
          to: ({ id }) => `/partners/${id}/imports`,
          activeRoutes: [
            '/partners/:id/imports',
            '/partners/:id/imports/farms',
            '/partners/:id/imports/farms/:importId',
          ],
          visible: !isPartnerUI && !isPartnerTypeFarm, // Only accessible for clients and partner of type supplier
        },
      ],
    },
    {
      label: 'Activities',
      iconName: 'locations',
      to: '/activities/internal',
      paths: ['/activities/internal', '/activities/external', '/activities/unassigned'],
      visible: fullPlan,
    },
    {
      label: 'Requests',
      iconName: 'document-request-pending',
      to: '/requests',
      visible: isPartnerUI,
    },
    {
      label: 'Documents',
      iconName: 'documents',
      exact: false,
      to: '/documents/internal',
      paths: [
        '/documents/internal',
        '/documents/internal/:documentId',
        '/documents/shared',
        '/documents/shared/:documentId',
        '/documents/requested',
        '/documents/requested/:documentId',
      ],
      submenuPaths: [
        '/documents/internal/:documentId',
        '/documents/shared/:documentId',
        '/documents/requested/:documentId',
      ],
      SubmenuTitle: DocumentTitle,
      SubmenuIcon: DocumentPlaceholderIcon,
      visible: fullPlan,
      params: ['documentId'],
      submenu: [
        {
          label: 'Document overview',
          to: ({ documentId }) => `/documents/internal/${documentId}`,
          iconName: 'document',
          visible: isInternalDocument,
        },
        {
          label: 'Document overview',
          to: ({ documentId }) => `/documents/shared/${documentId}`,
          iconName: 'document',
          visible: isSharedDocument,
        },
        {
          label: 'Document overview',
          to: ({ documentId }) => `/documents/requested/${documentId}`,
          iconName: 'document',
          visible: !isInternalDocument && !isSharedDocument,
        },
      ],
    },
    {
      label: 'Compliance',
      iconName: 'compliance',
      to: '/compliance',
      params: ['id'],
      SubmenuTitle: () => <>EUDR</>, // TODO: change later for other compliance pages
      SubmenuIcon: CompliancePlaceholderIcon,
      visible: fullPlan && !isPartnerUI && isCompliancePageEnabled,
      submenuPaths: ['/compliance/eudr', '/compliance/eudr/:id', '/compliance/eudr/:id/guide'],
      submenu: [
        {
          label: 'Compliance overview',
          to: ({ id }) => `/compliance/eudr/${id}`,
          iconName: 'due-diligence-badge',
          activeRoutes: ['/compliance/eudr', '/compliance/eudr/:id'],
          visible: true,
        },
        {
          label: 'Compliance guide',
          to: ({ id }) => `/compliance/eudr/${id}/guide`,
          iconName: 'guide',
          visible: isEUDRComplianceGuideEnabled,
        },
      ],
    },
    {
      label: 'Payments',
      iconName: 'scale',
      to: '/payments',
      visible: fullPlan && !isPartnerUI && isPaymentsPageEnabled,
    },
    {
      label: 'Data Import',
      iconName: 'import',
      to: '/imports',
      exact: false,
      visible: fullPlan && isPartnerUI && !isPartnerTypeFarm,
    },
    // Communication section
    {
      label: 'Product pages',
      iconName: 'products',
      to: '/products',
      submenuPaths: [
        '/products/:id',
        '/products/:id/editor',
        '/products/:id/editor',
        '/products/:id/analytics',
        '/products/:id/analytics/behavior',
        '/products/:id/analytics/embedded',
        '/products/:id/feedback',
      ],
      sectionLabelAbove: fullPlan ? 'Communication' : undefined,
      SubmenuTitle: ProductTitle,
      SubmenuIcon: ProductPlaceholderIcon,
      params: ['id'],
      exact: true,
      visible: !isPartnerUI,
      submenu: [
        {
          label: 'Product page overview',
          to: ({ id }) => `/products/${id}`,
          iconName: 'product',
          visible: true,
          activeRoutes: [
            '/products/:id',
            '/products/:id/analytics',
            '/products/:id/analytics/behavior',
            '/products/:id/analytics/embedded',
            '/products/:id/feedback',
          ],
        },
        {
          label: 'Product page editor',
          to: ({ id }) => `/products/${id}/editor`,
          iconName: 'product-edit',
          visible: true,
        },
      ],
    },
    {
      label: 'Impact Claims',
      Icon: ClaimsIcon,
      to: '/impact-claims',
      submenuPaths: ['/impact-claims/:id'],
      params: ['id'],
      exact: true,
      SubmenuTitle: ClaimTitle,
      SubmenuIcon: ImpactClaimPlaceholderIcon,
      visible: fullPlan && !isPartnerUI,
      submenu: [
        {
          label: 'Claim overview',
          to: ({ id }) => `/impact-claims/${id}`,
          Icon: ClaimIcon,
          visible: true,
        },
      ],
    },
    {
      label: 'Media',
      Icon: ImagesIcon,
      exact: true,
      to: '/media-library',
      visible: fullPlan,
    },
    // Settings and help section
    {
      label: 'Settings',
      Icon: SettingsIcon,
      to: '/settings',
      showWarning: !isPartnerUI && !isCompanyDataValid,
      dividerAbove: true,
      visible: true,
    },
  ];
};
