import { gql } from '@apollo/client';
import { PRODUCT_FRAGMENT, PRODUCT_LANGUAGE_CONFIG } from 'graphql/fragments';

export const PUBLISH_PRODUCT = gql`
  mutation PublishProduct($id: UUID!, $lang: Language) {
    publishProduct(id: $id, lang: $lang) {
      product {
        id
        publishedLanguages
      }
    }
  }
`;

export const UNPUBLISH_PRODUCT = gql`
  mutation PublishProduct($id: UUID!, $lang: Language) {
    unpublishProduct(id: $id, lang: $lang) {
      product {
        ...productValues
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const SET_LAST_EDITED_LANG = gql`
  mutation SetLastEditedLang($id: UUID!, $lang: Language!) {
    setLastEditedLang(id: $id, lang: $lang) @client {
      lastEditedLang
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: CreateNewProductInput!) {
    createNewProduct(input: $input) {
      product {
        id
      }
    }
  }
`;

export const DUPLICATE_PRODUCT = gql`
  mutation DuplicateProduct($id: UUID!, $input: DuplicateProductInput!) {
    duplicateProduct(id: $id, input: $input) {
      product {
        id
      }
    }
  }
`;

export const ARCHIVE_PRODUCT = gql`
  mutation ArchiveProduct($id: UUID!) {
    archiveProduct(id: $id) {
      product {
        id
      }
    }
  }
`;

export const UNARCHIVE_PRODUCT = gql`
  mutation UnarchiveProduct($id: UUID!) {
    unarchiveProduct(id: $id) {
      product {
        id
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: UUID!, $input: UpdateProductInput!) {
    updateProduct(id: $id, input: $input) {
      product {
        id
        title
        slug
        articleNumber
        languageConfig {
          ...languageConfigValues
        }
      }
    }
  }
  ${PRODUCT_LANGUAGE_CONFIG}
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: UUID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`;

export const UPDATE_PRODUCT_SLUG = gql`
  mutation UpdateProductSlug($id: UUID!, $input: UpdateProductSlugInput!) {
    updateProductSlug(id: $id, input: $input) {
      product {
        ...productValues
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const UPDATE_PRODUCT_CONTENT_STATUS = gql`
  mutation UpdateProductContentStatus($id: UUID!, $contentStatus: Int!, $lang: Language) {
    updateProductContentStatus(id: $id, contentStatus: $contentStatus, lang: $lang) {
      ...productValues
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const UPDATE_PRODUCT_LANGUAGES = gql`
  mutation UpdateProductLanguages($id: UUID!, $input: UpdateProductLanguagesInput!) {
    updateProductLanguageConfig(id: $id, input: $input) {
      product {
        id
        languageConfig {
          ...languageConfigValues
        }
      }
    }
  }
  ${PRODUCT_LANGUAGE_CONFIG}
`;
