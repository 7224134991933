import { ApolloError, useQuery } from '@apollo/client';
import { useLogEvent } from 'components/hooks';
import useConfig from 'components/hooks/useConfig';
import useDialog from 'components/hooks/useDialog';
import useFeatureFlags, { FeatureFlag } from 'components/hooks/useFeatureFlags';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { GET_ALL_BASE_PARTNERS } from 'graphql/queries/partners';
import get from 'lodash/get';
import { useEffect, useMemo, useState } from 'react';
import { Partner, PartnerTypeEnum } from 'types/partner.types';
import { GraphQlConnection } from 'types/types';
import {
  BooleanParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { PartnerHeaderColumnEnum } from '../PartnersTable/partnerHeaderColumns';

interface UsePartnersData {
  selectedPartnerType: PartnerTypeEnum.SUPPLIER | PartnerTypeEnum.FARM;
  partners: Partner[];
  searchTerm: string | null;
  partnerToDelete: Partner | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  hasNextPage: boolean;
  handlePageEndReached: () => void;
  handleCreatePartner: () => void;
  handleTabChange: (partnerType: PartnerTypeEnum.SUPPLIER | PartnerTypeEnum.FARM) => void;
  handleSearchTermChange: (searchTerm: string) => void;
  clearDeletingPartner: () => void;
  onDeletePartner: (partner: Partner) => void;
  onEditPartner: (partner: Partner, lang?: string) => void;
  refetch: () => void;
}

interface Props {
  customSearch?: string;
  includeSelf?: boolean;
  queryByPartnerType?: boolean;
  useInfiniteScroll?: boolean;
  useSearchQuery?: boolean;
}

const usePartners = ({
  customSearch,
  includeSelf,
  useInfiniteScroll,
  useSearchQuery,
  queryByPartnerType,
}: Props = {}): UsePartnersData => {
  const { appQueryParams } = useConfig();
  const { isPartner } = useCompanyPlanContext();

  const [partnerToDelete, setPartnerToDelete] = useState<Partner>();

  const [query, setQuery] = useQueryParams(
    {
      [appQueryParams.new]: withDefault(BooleanParam, false),
      [appQueryParams.query]: withDefault(StringParam, ''),
      [appQueryParams.type]: withDefault(
        createEnumParam([PartnerTypeEnum.SUPPLIER, PartnerTypeEnum.FARM]),
        isPartner ? PartnerTypeEnum.FARM : PartnerTypeEnum.SUPPLIER
      ),
      [appQueryParams.order]: withDefault(StringParam, 'DESC'),
      [appQueryParams.orderBy]: withDefault(StringParam, PartnerHeaderColumnEnum.CREATED_TIMESTAMP),
    },
    { removeDefaultsFromUrl: true }
  );

  const addNewPartner = query[appQueryParams.new];
  const searchTerm = (query[appQueryParams.query] as string) || '';
  const selectedPartnerType = query[appQueryParams.type] as
    | PartnerTypeEnum.SUPPLIER
    | PartnerTypeEnum.FARM;

  const orderByMethod = query[appQueryParams.order] as 'ASC' | 'DESC' | undefined;
  const orderByField = query[appQueryParams.orderBy];

  const { isFeatureEnabled } = useFeatureFlags();
  const canInvitePartners = isFeatureEnabled(FeatureFlag.PARTNER_INVITATIONS);
  const { logEvent } = useLogEvent();
  const { openDialog } = useDialog();

  const { data, loading, error, refetch, fetchMore } = useQuery<{
    partners: GraphQlConnection<Partner>;
  }>(GET_ALL_BASE_PARTNERS, {
    variables: {
      filters: {
        searchTerm: useSearchQuery && searchTerm?.length ? searchTerm : undefined,
        ...(queryByPartnerType && { partnerType: selectedPartnerType }),
        selfType: includeSelf ? 'INCLUDE_SELF' : 'EXCLUDE_SELF',
      },
      orderBy: {
        orderByField,
        orderByMethod,
      },
      ...(useInfiniteScroll && { first: 15 }),
    },
  });

  const hasNextPage: boolean = !!data?.partners?.pageInfo?.hasNextPage;
  const endCursor = data?.partners?.pageInfo?.endCursor;

  const partners: Partner[] = useMemo(() => {
    if (!data) return [];
    return data?.partners.edges.map(edge => edge.node);
  }, [data]);

  const filteredPartners = useMemo(() => {
    const searchToUse = customSearch !== undefined ? customSearch : searchTerm;
    if (useSearchQuery || !searchToUse?.length) return partners;

    return partners?.filter(partner => {
      // Filter a list of fields to see which fields have a match with the searchTerm
      return ['title', 'location.name'].some(field =>
        // See if the field includes the search term
        get(partner, field)?.toLowerCase().includes(searchToUse.toLowerCase())
      );
    });
  }, [partners, searchTerm, customSearch, useSearchQuery]);

  const handlePageEndReached = () => {
    if (endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  const handleCreatePartner = () => {
    logEvent('CLICK_PARTNER_CREATE');
    if (canInvitePartners && !isPartner) {
      openDialog({ type: 'PARTNER_CREATE' });
    } else {
      openDialog({ type: 'PARTNER_FORM', props: { isPartner } });
    }
  };

  const clearDeletingPartner = () => {
    setPartnerToDelete(undefined);
  };

  const onDeletePartner = (partner: Partner) => {
    setPartnerToDelete(partner);
  };

  const onEditPartner = (partner: Partner) => {
    openDialog({ type: 'PARTNER_FORM', props: { partner, isPartner } });
  };

  const handleSearchTermChange = (term: string) => {
    setQuery({ [appQueryParams.query]: term });
    logEvent('PARTNER_SEARCHED', { searchTerm: term });
  };

  const handleTabChange = (partnerType: PartnerTypeEnum.SUPPLIER | PartnerTypeEnum.FARM) => {
    setQuery({
      [appQueryParams.type]: partnerType,
      [appQueryParams.query]: '',
      [appQueryParams.order]: 'DESC',
      [appQueryParams.orderBy]: PartnerHeaderColumnEnum.CREATED_TIMESTAMP,
    });
  };

  useEffect(() => {
    if (addNewPartner) {
      handleCreatePartner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewPartner]);

  return {
    selectedPartnerType: selectedPartnerType || PartnerTypeEnum.SUPPLIER,
    partners: filteredPartners,
    searchTerm,
    hasNextPage,
    loading,
    error,
    partnerToDelete,
    handleCreatePartner,
    handleSearchTermChange,
    clearDeletingPartner,
    onDeletePartner,
    onEditPartner,
    handlePageEndReached,
    handleTabChange,
    refetch,
  };
};

export default usePartners;
