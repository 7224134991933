import { useMutation, useQuery } from '@apollo/client';
import { Box, styled } from '@mui/material';
import { Check, InfoCircleFill } from '@styled-icons/bootstrap';
import useRawMaterials from 'components/ComponentsLibrary/hooks/useRawMaterials';
import ExcelTable, { IGridApiHandle } from 'components/DataImport/DataImportTable/ExcelTable';
import TableSettingsDialog from 'components/DataImport/TableSettings/TableSettingsDialog';
import {
  DATA_IMPORT_TABLE_ROW_HEIGHT,
  DEFAULT_TABLE_SETTINGS,
} from 'components/DataImport/constants/dataImport.constants';
import { DATA_IMPORT_CATEGORY_CONFIG } from 'components/DataImport/constants/dataImportConfig';
import { Loader } from 'components/Forms';
import { SearchInput } from 'components/Forms/SearchToolbar';
import { useMessages } from 'components/hooks';
import { ThemeButton } from 'designSystem';
import ErrorMessage from 'designSystem/DataDisplay/ErrorMessage/ErrorMessage';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { CREATE_FIRST_MILE_IMPORT } from 'graphql/mutations/imports';
import { GET_FIRST_MILE_IMPORT } from 'graphql/queries/imports';
import omit from 'lodash/omit';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { IRawMaterial } from 'types/component.types';
import {
  FirstMileImportCreateInput as CreateFirstMileImportInput,
  Farm,
  FarmColumns,
  IFirstMileImportResponse,
  ITableSettings,
} from 'types/dataImport.types';
import { AvailableLanguages, AvailableLanguagesType } from 'types/enums';
import {
  getEmptyRow,
  isRowEmpty,
  isRowValid,
  transformDataForDisplay,
  transformDataForMutation,
} from './utils/dataImport.utils';

interface IDataImportViewProps {
  containerHeight: number;
  datasetId?: string;
  displayOnboardingTour?: boolean | null;
  partnerId: string;
  onDataImportCreated: () => void;
}

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  minHeight: 50,
  padding: theme.spacing(0.5),
}));

const ActionBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  padding: theme.spacing(2, 0.5, 1.5),
}));

const ElementGroup = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const ErrorContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: 4,
  padding: theme.spacing(0.2, 2),
  marginRight: theme.spacing(2),
}));

const InfoPill = styled(Box)(({ theme }) => ({
  color: theme.custom.themeColors.grayScale[60],
  fontSize: 11,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 12,
  background: theme.custom.themeColors.white,
  borderRadius: 6,
}));

const PillIconWrapper = styled('span')(({ theme }) => ({
  color: theme.custom.themeColors.grayScale[30],
}));

const AbsoluteTableContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: 150,
});

const DataImportView: FC<IDataImportViewProps> = ({
  containerHeight,
  datasetId,
  displayOnboardingTour,
  partnerId,
  onDataImportCreated,
}) => {
  const editMode = datasetId === undefined;
  const tableHeight = containerHeight - 200;
  const initialRows = editMode ? Math.floor(tableHeight / DATA_IMPORT_TABLE_ROW_HEIGHT - 1) : 0;
  const categoryConfig = DATA_IMPORT_CATEGORY_CONFIG[0];

  const gridApiRef = useRef<IGridApiHandle<FarmColumns>>(null);
  const { rawMaterials } = useRawMaterials();
  const { setSuccessMessage } = useMessages();
  const { data, loading } = useQuery<IFirstMileImportResponse>(GET_FIRST_MILE_IMPORT, {
    variables: { id: datasetId },
    skip: editMode,
  });

  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const [tableSettings, setTableSettings] = useState<ITableSettings>(DEFAULT_TABLE_SETTINGS);
  const [datasetName, setDatasetName] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [rowData, setRowData] = useState<FarmColumns[]>(
    [...Array(initialRows).keys()].map(() => getEmptyRow(tableSettings) as FarmColumns)
  );

  const columnDefs = useMemo(
    () => categoryConfig.getColumnDefinitions(tableSettings),
    [categoryConfig, tableSettings]
  );

  useEffect(() => {
    if (!dataLoaded && data && data?.firstMileImport.rows.edges.length > 0) {
      setDataLoaded(true);
      const rows = data?.firstMileImport.rows.edges.map(({ node }) =>
        transformDataForDisplay(node, tableSettings, rawMaterials)
      );
      setRowData(rows);
      setDatasetName(data?.firstMileImport.title);
    }
  }, [data, dataLoaded, tableSettings, rawMaterials]);

  const [createFirstMileImport, { loading: creationLoading }] = useMutation<
    { createFirstMileImport: { firstMileImport: CreateFirstMileImportInput } },
    { input: CreateFirstMileImportInput; lang: AvailableLanguagesType }
  >(CREATE_FIRST_MILE_IMPORT, {
    onCompleted: () => {
      setSuccessMessage('Your data was imported successfully.');
      onDataImportCreated();
    },
    onError: () => {
      setErrorMessage('Something went wrong. Please try again.');
    },
  });

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  const handleSaveData = async () => {
    setErrorMessage('');
    const api = gridApiRef.current?.getApi();

    if (!api) {
      return;
    }

    let valid = true;
    const importData: Omit<Farm, 'id'>[] = [];
    const updates: (FarmColumns & { id: string })[] = [];
    api.forEachNode(node => {
      const updatedRow: Partial<FarmColumns> = {
        farmId: { value: '', isValid: false },
        name: { value: '', isValid: false },
        crop: { value: '', isValid: false },
        location: { value: '', isValid: false },
        farmSize: { value: undefined, isValid: false },
        ...node.data,
      };

      if (node.data !== undefined && !isRowEmpty(node.data)) {
        updates.push(updatedRow as FarmColumns & { id: string });
        if (valid) {
          valid = valid && isRowValid(updatedRow as FarmColumns, true);
          if (valid) {
            const result = omit(
              transformDataForMutation(node.data, tableSettings, rawMaterials),
              'id'
            );
            if (result) importData.push(result);
          }
        }
      }
    });

    if (!valid) {
      if (updates.length > 0) {
        api.applyTransaction({ update: updates });
        gridApiRef.current?.runValidations();
      }
      setErrorMessage('Please fix all errors.');
    } else if (importData.length > 0) {
      await createFirstMileImport({
        variables: {
          input: {
            partnerId,
            title: datasetName,
            firstMileImportInputs: importData,
          },
          lang: AvailableLanguages.ENGLISH,
        },
      });
    } else {
      setErrorMessage('There are no rows to save.');
    }
  };

  const canSave = !loading && gridApiRef.current !== null;

  return (
    <>
      {editMode && openSettings && (
        <TableSettingsDialog
          open={true}
          onClose={handleCloseSettings}
          settings={tableSettings}
          onChangeSettings={newSettings => {
            setTableSettings(newSettings);
            handleCloseSettings();
          }}
        />
      )}
      <ActionBar>
        <ElementGroup data-tut="onboarding_tour_data_import_dataset_name">
          <SearchInput
            setDebouncedState={setDatasetName}
            delay={100}
            placeholder="Enter name of dataset"
            initialValue={datasetName}
            disabled={!editMode}
          />
          {!editMode && (
            <InfoPill height={30} ml={2}>
              <PillIconWrapper>
                <InfoCircleFill size={13} />
              </PillIconWrapper>
              <Box ml={1}>
                You can not edit a previously imported data set. If you upload a new data set, rows
                with the same id will be updated.
              </Box>
            </InfoPill>
          )}
        </ElementGroup>
        <ElementGroup>
          {editMode ? (
            <>
              {errorMessage && (
                <ErrorContainer>
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                </ErrorContainer>
              )}
              <Box mr={1}>
                <ThemeButton
                  variant="contained"
                  color="WHITE"
                  onClick={handleOpenSettings}
                  startIcon={<Icon name="settings-filled" size="small" />}
                  data-tut="onboarding_tour_data_import_settings"
                >
                  Table settings
                </ThemeButton>
              </Box>

              <ThemeButton
                variant="contained"
                loading={creationLoading}
                disabled={!canSave}
                onClick={handleSaveData}
              >
                Save data
              </ThemeButton>
            </>
          ) : (
            <InfoPill height={36}>
              <PillIconWrapper>
                <Check size={32} />
              </PillIconWrapper>
              <Box ml={1}>Imported today</Box>
            </InfoPill>
          )}
        </ElementGroup>
      </ActionBar>
      <Container>
        {displayOnboardingTour && (
          <AbsoluteTableContainer data-tut="onboarding_tour_data_import_table" />
        )}
        {!!tableHeight ? (
          <ExcelTable<FarmColumns, { settings: ITableSettings; rawMaterials: IRawMaterial[] }>
            gridRef={gridApiRef}
            rowData={rowData}
            columnDefs={columnDefs}
            addionalContextValues={{ settings: tableSettings, rawMaterials }}
            height={tableHeight}
            editMode={editMode}
          />
        ) : (
          <Loader />
        )}
      </Container>
    </>
  );
};

export default DataImportView;
