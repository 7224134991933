import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useEditorState } from 'components/Product/MultiStep/hooks';
import { useProduct } from 'components/Product/hooks';
import { useDebouncedCallback } from 'use-debounce';

const Autosave = ({ values, dirty, delay = 2000, pause = false, handleSave }) => {
  const {
    product: { id },
  } = useProduct();
  const { setUnsavedChanges, onError, onCompleted, lang, setSaving } = useEditorState();

  // Triggers after delay ( 2s ) if the values change.
  const debouncedSubmit = useDebouncedCallback(async values => {
    setSaving(true);
    // Fixme
    delete values['enabled'];

    // TODO: Clean this conditional about block update up once the other content blocks have been migrated as well.
    const migratedContentBlocks = [
      'AboutBlockData',
      'MoreInfoBlockData',
      'FeedbackBlockData',
      'VerifiedPaymentsBlockData',
    ];
    if (values.__typename && migratedContentBlocks.includes(values.__typename)) {
      // The AboutBlock is the only block ( for now ) which is updated in a different way from the other content blocks
      // > hence this edge case which saves the block with the contentblock id instead of the product id.
      await handleSave({
        variables: {
          id: values.id, // content block id
          lang,
          input: {
            // the input should not have the id passed to the mutation
            ...values,
            id: undefined,
          },
        },
      }).then(onCompleted, onError);
      setSaving(false);

      return;
    }

    // This is passed through the formcontainer > FormikAutosave > Autosave
    // Usually triggers the mutation of the activeMenu
    await handleSave({
      variables: {
        id, // id of the product
        lang,
        input: values,
      },
    }).then(onCompleted, onError);

    setSaving(false);
  }, delay);

  useEffect(() => {
    if (!dirty || pause) return;

    debouncedSubmit.callback(values);
    setUnsavedChanges(true);
  }, [debouncedSubmit, values, pause, dirty]); // eslint-disable-line

  return null;
};

Autosave.propTypes = {
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  dirty: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  pause: PropTypes.bool,
  delay: PropTypes.number,
};

export default Autosave;
