import { FormHelperText } from '@mui/material';
import { styled } from '@mui/styles';
import { useConfig } from 'components/hooks';
import { TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CharacterLimit = styled(({ visible, ...props }) => <FormHelperText {...props} />)(
  ({ visible }) => ({
    position: 'absolute',
    right: 0,
    bottom: -20,
    opacity: visible ? 1 : 0,
    transform: visible ? 'translateY(0)' : 'translateY(-20px)',
    transition: 'all 0.06s ease-out',
  })
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TextContainer = styled(({ lightBordered, ...props }) => <div {...props} />)(
  ({ lightBordered }) => ({
    position: 'relative',

    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${lightBordered ? '#F4F4F4' : '#EEEEEE'}`,
    },
  })
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Flag = styled(({ visible, ...props }) => <img {...props} alt="flag" />)(({ visible }) => ({
  position: 'absolute',
  right: 0,
  top: -22,
  borderRadius: 2,
  width: 20,
  opacity: visible ? 1 : 0,
  transform: visible ? 'translateY(0)' : 'translateY(22px)',
  transition: 'all 0.06s ease-out',
}));

const TextFieldLimited = ({ charLimit = 50, lightBordered, ...props }) => {
  const {
    field: { value },
    form: { status: { lang } = {} },
  } = props;
  const [focused, setFocused] = useState(false);
  const config = useConfig();

  // if text already exists and it is longer than the limit, then the limit is not applied
  const applyCharLimit = useMemo(() => {
    return (value || '').length <= charLimit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charLimit]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return (
    <TextContainer lightBordered={lightBordered}>
      {lang && <Flag visible={focused} src={config.getLanguageConfig(lang).flag} />}
      <TextField
        {...props}
        inputProps={{
          ...(props.inputProps ? props.inputProps : {}),
          maxLength: applyCharLimit ? charLimit : null,
          onBlur: handleBlur,
          onFocus: handleFocus,
        }}
      />
      {applyCharLimit && (
        <CharacterLimit visible={focused}>{`${(value || '').length}/${charLimit}`}</CharacterLimit>
      )}
    </TextContainer>
  );
};

TextFieldLimited.propTypes = {
  charLimit: PropTypes.number,
};

export default TextFieldLimited;
