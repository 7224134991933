import { Box, ButtonGroup, Grid } from '@mui/material';
import { MediaField } from 'components/Forms';
import { FieldWrapper, ThemeButton } from 'designSystem';
import { FastField as Field, useField } from 'formik';
import { TextField } from 'formik-mui';
import React, { FC } from 'react';
import { PartnerTypeEnum } from 'types/partner.types';

interface IPartnerFormProps {
  hideTypeSelection?: boolean;
}

const PartnerForm: FC<IPartnerFormProps> = ({ hideTypeSelection }) => {
  const [{ value: typeValue }, , { setValue: setTypeValue }] = useField<PartnerTypeEnum>('type');

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FieldWrapper flex={1} label="Partner Name" required>
            <Field
              component={TextField}
              placeholder="Name of company or owner"
              fullWidth
              name="title"
              variant="outlined"
              data-cy="partner-title-input"
            />
          </FieldWrapper>
        </Grid>
        <Grid item xs={12}>
          <FieldWrapper
            flex={1}
            label="Internal ID"
            tooltip={{
              helperText:
                "This should be an unique identifier used to track and manage partner-related data for example within your company's ERP system.",
              variant: 'INFO',
            }}
          >
            <Field
              component={TextField}
              placeholder="Unique identifier (optional)"
              fullWidth
              name="externalId"
              variant="outlined"
              data-cy="external-id-input"
            />
          </FieldWrapper>
        </Grid>
        {!hideTypeSelection && (
          <Grid item xs={12}>
            <FieldWrapper label="Partner type" required>
              <ButtonGroup>
                <ThemeButton
                  size="medium"
                  className={typeValue === PartnerTypeEnum.SUPPLIER ? 'selected' : ''}
                  onClick={() => setTypeValue(PartnerTypeEnum.SUPPLIER)}
                >
                  Supplier
                </ThemeButton>
                <ThemeButton
                  size="medium"
                  className={typeValue === PartnerTypeEnum.FARM ? 'selected' : ''}
                  onClick={() => setTypeValue(PartnerTypeEnum.FARM)}
                >
                  Farm
                </ThemeButton>
              </ButtonGroup>
            </FieldWrapper>
          </Grid>
        )}

        <Grid item xs={6}>
          <FieldWrapper label="Partner Logo">
            <Field
              component={MediaField}
              name="logo"
              data-cy="partner-logo-input"
              legacyImage={false}
              styles={{
                width: 150,
                maxWidth: '100%',
                height: 150,
              }}
            />
          </FieldWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PartnerForm;
