import { Box, Grid } from '@mui/material';
import { FieldWrapper, ThemeTypography } from 'designSystem';
import { Field, useField } from 'formik';
import React, { FC } from 'react';
import ComponentAutocomplete from '../../ComponentsLibrary/Forms/ComponentAutocomplete';
import { ShadowedFieldBlock } from './styles';
import { IComponentItem } from 'types/component.types';
import { IChainActivity } from 'types/chain.types';
import TextField from 'designSystem/Formik/TextField/TextField';

interface IOutputComponentBlockProps {
  /**
   * The mode of the component block, either "select" the output component which will query all available components or
   * "change-language" which will only allow the user to change the language of already selected output component, if none is selected the field will be hidden
   * Default: "select"
   */
  mode?: 'select' | 'change-language';
  /*
   * Warning: This overrides the partner with the the companies partner and will hide the partner selector in the component creation form
   */
  partnerView?: boolean;
}

const OutputComponentBlock: FC<IOutputComponentBlockProps> = ({ mode = 'select', partnerView }) => {
  const [{ value: selectedPartner }] = useField<IChainActivity['activity']['partner'] | null>(
    'activity.partner'
  );
  const [field, , helper] = useField<IChainActivity['activity']['component'] | null>(
    'activity.component'
  );

  const handleSelect = (component: IComponentItem | null) => {
    helper.setValue(
      component
        ? {
            id: component.id,
            title: component.title,
            partnerId: component.partner?.id || null,
            rawMaterials: component.rawMaterials,
            __typename: 'Component',
          }
        : null
    );
  };

  // If the mode is "change-language" and the field value is empty, hide the field
  if (mode === 'change-language' && !field.value) {
    return <></>;
  }

  return (
    <ShadowedFieldBlock container>
      <Grid item xs={12}>
        <FieldWrapper
          label="Output component"
          tooltip={{
            helperText:
              'Select the component that outputs from this activity in the supply chain. Components are ingredients, packaging or something else that makes up your end product.',
            variant: 'INFO',
          }}
        >
          {mode === 'select' && (
            <ComponentAutocomplete
              defaultSelectedId={field.value?.id || selectedPartner?.id}
              partner={selectedPartner || undefined}
              partnerView={partnerView}
              onSelect={handleSelect}
            />
          )}
          {mode === 'change-language' && (
            <>
              <Field
                component={TextField}
                fullWidth
                type="text"
                name="activity.component.title"
                variant="outlined"
                size="small"
                placeholder="Change component language here"
                data-cy="output-component-lang-input"
                inputProps={{
                  style: { fontSize: 14, height: 19 }, // Fix size difference between select and text input
                  'data-cy': 'output-component-lang-input',
                }}
              />
              <Box mb={1} />
              <ThemeTypography variant="BODY_MEDIUM" color="ALMOST_BLACK">
                You can only correct the language translation here. The output component can be
                changed in the supply chain mapping tool.
              </ThemeTypography>
            </>
          )}
        </FieldWrapper>
      </Grid>
    </ShadowedFieldBlock>
  );
};

export default OutputComponentBlock;
