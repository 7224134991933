/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CellStyleFunc,
  ColDef,
  ColDefField,
  ICellRendererParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import React, { createElement } from 'react';
import { IRawMaterial } from 'types/component.types';
import { Farm, FarmColumns, ITableSettings } from 'types/dataImport.types';
import { Coordinates } from 'types/types';
import { v4 as uuid } from 'uuid';
import ValidatedCellRenderer from '../DataImportTable/CellRenderers/ValidatedCellRenderer';
import { IHeaderColumnProps } from '../DataImportTable/HeaderColumn';
import TooltipComponent from '../DataImportTable/TooltipComponent';
import {
  ColDefWithValidator,
  ColumnDefinition,
  ValidatorFunction,
} from '../DataImportTable/excelTable.types';
import { CROPS_SEPARATOR } from '../constants/dataImport.constants';

export const transformDataForMutation = (
  data: FarmColumns & { id?: string },
  settings: ITableSettings,
  allRawMaterials: IRawMaterial[]
): (Omit<Farm, 'id'> & { id?: string }) | null => {
  if (!data.farmId.value || !data.name.value || !data.crop.value || !data.farmSize.value) {
    return null;
  }

  const rawMaterials = data.crop.value.split(CROPS_SEPARATOR) || [];
  const rawMaterialIds = rawMaterials
    .map(
      rawMaterial =>
        allRawMaterials.find(({ title }) => title.toLowerCase() === rawMaterial.toLowerCase())?.id
    )
    .filter((x): x is string => !!x);

  const coordinates = data.location.value?.split(',').map(Number);
  const locationCoordinates: Coordinates | undefined = coordinates
    ? settings.coordinatesVersion === 'latlng'
      ? { lat: coordinates[0], lng: coordinates[1] }
      : { lat: coordinates[1], lng: coordinates[0] }
    : undefined;

  const farmSize =
    settings.farmSizeUnit === 'km2' ? Number(data.farmSize.value) : data.farmSize.value / 100;

  const farmHarvestWeight =
    data.weight?.value &&
    (settings.weightUnit === 'kg' ? Number(data.weight.value) : data.weight.value * 1000);

  return {
    id: data.id,
    farmExternalId: data.farmId.value,
    partnerTitle: data.name.value,
    rawMaterialIds,
    locationCoordinates,
    farmSize,
    farmHarvestWeight,
  };
};

export const transformDataForDisplay = (
  data: Farm,
  settings: ITableSettings,
  allRawMaterials: IRawMaterial[]
): FarmColumns & { id: string } => {
  const rawMaterialNames = data.rawMaterialIds
    .map(dataId => allRawMaterials.find(({ id }) => dataId === id)?.title)
    .filter((x): x is string => !!x)
    .join(CROPS_SEPARATOR);

  let coordinates = '';
  if (data.locationCoordinates?.lat && data.locationCoordinates?.lng) {
    coordinates =
      settings.coordinatesVersion === 'latlng'
        ? `${data.locationCoordinates.lat}, ${data.locationCoordinates.lng}`
        : `${data.locationCoordinates.lng}, ${data.locationCoordinates.lat}`;
  }

  const farmSize = settings.farmSizeUnit === 'km2' ? data.farmSize : data.farmSize * 100;

  const weight =
    data.farmHarvestWeight &&
    (settings.weightUnit === 'kg' ? data.farmHarvestWeight : data.farmHarvestWeight / 1000);

  return {
    id: data.id || uuid(),
    farmId: { value: data.farmExternalId },
    name: { value: data.partnerTitle, isValid: true },
    crop: { value: rawMaterialNames },
    location: { value: coordinates },
    farmSize: { value: farmSize },
    weight: weight ? { value: weight } : undefined,
  };
};

export const getEmptyRow = (settings?: ITableSettings): Partial<FarmColumns & { id: string }> => ({
  id: uuid(),
  ...(settings?.autoAssignIds ? { farmId: { value: uuid(), isValid: true } } : {}),
});

export const isValueEmpty = (value: string | number | null | undefined): boolean => {
  return value === '' || value === undefined || value === null;
};

const isColumnDefinition = (
  obj: FarmColumns[keyof FarmColumns]
): obj is ColumnDefinition<string> | ColumnDefinition<number> => {
  return typeof obj !== 'string'; // If type is string, it must be internalId column - all other columns are ColumnDefinition
};

export const isRowEmpty = (row: FarmColumns): boolean => {
  return Object.values(row)
    .filter(isColumnDefinition)
    .every(cell => {
      return isValueEmpty(cell.value);
    });
};

export const isRowValid = (row: FarmColumns, forSave: boolean = false): boolean => {
  return Object.values(row)
    .filter(isColumnDefinition)
    .every(({ isValid, error }) =>
      forSave ? isValid : (isValid === undefined || isValid) && !error
    );
};

export const isRowCompletelyFilled = (
  row: FarmColumns,
  columnDefs: ColDefWithValidator<any>[]
): boolean => {
  const requiredFields = columnDefs
    .filter(({ isRequired }) => isRequired)
    .map(({ field }) => field as ColDefField<any, any>);

  const filledFields = Object.entries(row)
    .filter(([key, columnValue]) => {
      // If columnValue is string, it must be internalId column - all other columns are ColumnDefinition
      if (!isColumnDefinition(columnValue)) return true;

      // Column is not required, so it can be empty
      if (!requiredFields.includes(key)) return true;

      // Column is required, check if it is filled
      return columnValue.value !== undefined;
    })
    .map(([key]) => key);

  return requiredFields.every(field => filledFields.includes(field));
};

export const rowHasValidatedCells = (row: FarmColumns): boolean =>
  Object.values(row)
    .filter(isColumnDefinition)
    .some(({ isValid }) => isValid !== undefined);

const valueGetter = ({ data, column }: ValueGetterParams) => data[column.getId()]?.value;

const valueSetter =
  (validateFn: ValidatorFunction) =>
  ({ data, oldValue, column, newValue, context, api }: ValueSetterParams) => {
    // Do nothing if value is unchanged
    if (isValueEmpty(oldValue) ? isValueEmpty(newValue) : oldValue === newValue) return false;

    const field = column.getColId();

    data[field] = {
      ...data[field],
      ...validateFn(newValue, context),
      value: newValue,
    };

    // This interrupts with the native undo/redo behavior of ag-grid so avoid this
    // Not 100% why it was used here in the first place
    // api.applyTransaction({ update: [data] });
    // This should achieve the same result
    api.refreshCells({ rowNodes: [data], force: true, suppressFlash: true });

    return true;
  };

const cellStyleFunc: CellStyleFunc = ({ column, value, data, context }) => {
  const cell = data[column.getId()];
  const isValid = isRowEmpty(data) || cell?.isValid === undefined || cell?.isValid;

  return {
    color: isValid ? 'unset' : context.theme.custom.themeColors.error[80],
    backgroundColor: isValid ? 'unset' : context.theme.custom.themeColors.error[20],
  };
};

export const createColumnDefinition: <RowData = unknown>(
  field: ColDefField<RowData>,
  headerComponent: React.FC<IHeaderColumnProps>,
  validateFn: ValidatorFunction,
  cellRenderer?: React.FC<ICellRendererParams>,
  additionalSettings?: Partial<
    Omit<
      ColDef,
      | 'field'
      | 'headerComponent'
      | 'cellRenderer'
      | 'valueGetter'
      | 'valueSetter'
      | 'tooltipComponent'
      | 'tooltipField'
      | 'cellStyle'
      | 'refreshCell'
    >
  >,
  // By default all columns are required
  isRequired?: boolean
) => ColDefWithValidator<RowData> = (
  field,
  headerComponent,
  validateFn,
  cellRenderer,
  additionalSettings,
  isRequired = true
) => {
  const CustomCellRenderer: React.FC<ICellRendererParams> = params => {
    if (cellRenderer) {
      return (
        <ValidatedCellRenderer {...params}>
          {createElement(cellRenderer, params)}
        </ValidatedCellRenderer>
      );
    }
    return <ValidatedCellRenderer {...params} />;
  };

  return {
    field,
    enableCellChangeFlash: true,
    headerComponent,
    cellRenderer: CustomCellRenderer,
    valueGetter,
    valueSetter: valueSetter(validateFn),
    tooltipComponent: TooltipComponent,
    tooltipField: field,
    cellStyle: cellStyleFunc,
    validator: validateFn,
    isRequired,
    ...additionalSettings,
  };
};
