import { gql } from '@apollo/client';

export const GET_PLACES = gql`
  query GetPlaces($query: String!) {
    getPlaces(query: $query) @client(always: true) {
      title
      lat
      lng
    }
  }
`;
