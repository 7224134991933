import * as Yup from 'yup';
import { ILocationVisibility } from 'types/supplyChain.type';
import { createMediaSchema } from './shared';

export const VisibilitySettingsSchema = Yup.object().shape({
  locationVisibility: Yup.mixed()
    .oneOf(Object.keys(ILocationVisibility))
    .default('EXACT')
    .required(),
});

const ActivitySchema = Yup.object().shape({
  mediaList: Yup.array().of(createMediaSchema('', false)).nullable().default([]),
  description: Yup.string().label('Description').default(''),
  title: Yup.string().label('Title').default('').required(),
  locationCoordinates: Yup.object()
    .shape({
      lat: Yup.number(),
      lng: Yup.number(),
    })
    .typeError('Please add a location to the activity')
    .required('Location is a required field'),
  name: Yup.string().nullable().required('Location is a required field'),
  partnerId: Yup.string().nullable(),
  component: Yup.object()
    .shape({
      id: Yup.string(),
      title: Yup.string(),
      partnerId: Yup.string().nullable(),
    })
    .nullable(),
});

const IncomingLinkSchema = Yup.object().shape({ chainStepActivitySourceId: Yup.string() });

const OutputComponentsSchema = Yup.object().shape({
  partner: Yup.object().shape({ title: Yup.string() }).nullable(),
});

export const ChainActivitySchema = Yup.object().shape({
  id: Yup.string(),
  incomingLinks: Yup.array().of(IncomingLinkSchema),
  activity: ActivitySchema,
});

export const SubChainSchema = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string(),
  outputComponents: Yup.array().of(OutputComponentsSchema),
});

export const ChainStepSchema = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string().required(),
});

export const SupplyChainSchema = Yup.object().shape({
  id: Yup.string().required(),
  title: Yup.string().required(),
  subChains: Yup.array().of(SubChainSchema).default([]),
  chainStepActivities: Yup.array()
    .of(ChainActivitySchema)
    .min(1, 'Please add at least one activity'),
  chainSteps: Yup.array().of(ChainStepSchema).min(1, 'Please add at least one step'),
});
