import { Box, Tooltip } from '@mui/material';
import { Tour } from '@styled-icons/material/Tour';
import ChainMapping from 'components/ChainMapping/ChainMapping';
import ChainTasksDetails from 'components/ChainMapping/components/ChainTasksDetails';
import CreateChainPlaceholder from 'components/ComponentsLibrary/CreateChainPlaceholder';
import useComponentChainMapping from 'components/ComponentsLibrary/hooks/useComponentChainMapping';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { Loader } from 'components/Forms';
import { ErrorState, PageContainer, PageTitle } from 'components/Structure';
import { useDialog, usePageTitle } from 'components/hooks';
import { STEP_BY_STEP_LIST_HEIGHT } from 'constants/chainMapping';
import { InfoTooltip, ThemeButton } from 'designSystem';
import React, { FC } from 'react';
import { AvailableSizes, PartnerRequestStatus } from 'types/enums';
import Icon from 'designSystem/Primitives/Icon/Icon';
import {
  REVIEW_REQUEST_BUTTON_STATUS_CLIENT,
  REVIEW_REQUEST_BUTTON_STATUS_PARTNER,
} from 'utils/reviewRequestButtonStatus.utils';

const ComponentChainMapping: FC = () => {
  usePageTitle('Component chain mapping');
  const {
    error,
    queryLoading,
    mutationLoading,
    componentChain,
    expandList,
    handleEditComponentChain,
    handleExpandList,
    handleClickOnboardingTour,
    handleCreateByCopyComponentClick,
    handleCreateNewChainClick,
    handleChainMappingAction,
  } = useComponentChainMapping();
  const { isPartner } = useCompanyPlanContext();
  const { openDialog } = useDialog();

  const request = componentChain?.partnerRequest;

  const diabledRequestButton = componentChain && componentChain.chainTasksDetails?.completed < 4;

  const handleClickFulfillRequestButton = () => {
    if (!componentChain?.partnerRequest) return;
    openDialog({
      type: 'FINISH_CHAIN_REQUEST',
      props: { requestId: componentChain?.partnerRequest.id, chain: componentChain },
    });
  };

  const handleClickReviewRequestButton = async () => {
    if (!componentChain?.partnerRequest) return;

    openDialog({
      type: 'REVIEW_CHAIN_REQUEST',
      props: { request: { ...componentChain?.partnerRequest, chain: componentChain } },
    });
  };

  if (queryLoading || !componentChain) {
    return <Loader />;
  }
  if (error || !componentChain) {
    return <ErrorState />;
  }

  return (
    <PageContainer>
      <Box display="flex" alignItems="end">
        <Box width="100%">
          <PageTitle
            title="Component chain mapping"
            titleExtension={componentChain.title}
            goBackLabel="All component chains"
            goBackUrl="/component-chains"
            goBackExtension={
              request ? (
                <>
                  {isPartner ? (
                    <Tooltip
                      title={
                        REVIEW_REQUEST_BUTTON_STATUS_PARTNER[
                          PartnerRequestStatus[request.requestStatus]
                        ].tooltip ||
                        `You need to complete at least 4/${componentChain.chainTasksDetails?.total} steps to confirm chain as completed`
                      }
                    >
                      <div>
                        <ThemeButton
                          startIcon={<Icon name="chain-request-fulfilled" />}
                          color="YELLOW_40"
                          onClick={handleClickFulfillRequestButton}
                          disabled={
                            REVIEW_REQUEST_BUTTON_STATUS_PARTNER[
                              PartnerRequestStatus[request.requestStatus]
                            ].disabled || diabledRequestButton
                          }
                        >
                          Confirm chain mapping request as finished
                        </ThemeButton>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        REVIEW_REQUEST_BUTTON_STATUS_CLIENT[
                          PartnerRequestStatus[request.requestStatus]
                        ].tooltip
                      }
                    >
                      <div>
                        <ThemeButton
                          startIcon={<Icon name="chain-request-fulfilled" />}
                          color="YELLOW_40"
                          onClick={handleClickReviewRequestButton}
                          disabled={
                            REVIEW_REQUEST_BUTTON_STATUS_CLIENT[
                              PartnerRequestStatus[request.requestStatus]
                            ].disabled
                          }
                        >
                          Review chain mapping request status
                        </ThemeButton>
                      </div>
                    </Tooltip>
                  )}
                </>
              ) : undefined
            }
            TitleAdornment={
              isPartner ? (
                <></>
              ) : (
                <Box ml={1}>
                  <InfoTooltip
                    size={AvailableSizes.LARGE}
                    text="Component chains are the sub-supply chains of your product supply chains. They describe the material flow of your components (ingredients, packaging or something else that makes up your end product). Using the component chains will help you to have better clarity and makes it easier to re-use your component chains across your different product's supply chains."
                  />
                </Box>
              )
            }
          />
        </Box>
        <ThemeButton
          color="WHITE"
          data-cy="restart-tour-button"
          size="small"
          onClick={handleClickOnboardingTour}
          startIcon={<Tour size={16} />}
        >
          Restart guided tour
        </ThemeButton>
      </Box>
      <Box position="relative" mt={2}>
        <ChainTasksDetails
          expanded={expandList}
          height={STEP_BY_STEP_LIST_HEIGHT}
          chainTasksDetails={componentChain.chainTasksDetails}
          title={componentChain.title}
          image={componentChain.image}
          onEditChain={handleEditComponentChain}
          onExpand={handleExpandList}
        />
      </Box>
      {mutationLoading && <Loader overlayOpacity />}
      <Box height="calc(100% - 180px)" mt={`${STEP_BY_STEP_LIST_HEIGHT + 35}px`} overflow="hidden">
        {!componentChain.mappingInitialised ? (
          <CreateChainPlaceholder
            isCopyButtonVisible={false} // Disabled until the proper duplication feature is implemented in the backend
            startFromScratchButtonLabel="New component chain"
            onCopyChainClick={handleCreateByCopyComponentClick}
            onStartFromSketchClick={handleCreateNewChainClick}
          />
        ) : (
          <ChainMapping
            viewMode={isPartner ? 'partner-default' : 'default'}
            chainMapping={componentChain}
            onActionClick={handleChainMappingAction}
          />
        )}
      </Box>
    </PageContainer>
  );
};

export default ComponentChainMapping;
