import useRawMaterials from 'components/ComponentsLibrary/hooks/useRawMaterials';
import { FieldWrapper } from 'designSystem';
import AutocompleteSelection from 'designSystem/Inputs/AutocompleSelection/AutocompleteSelection';
import { useField } from 'formik';
import React, { FC } from 'react';

interface IRawMaterialSelectionProps {
  name: string;
  size?: 'small' | 'medium';
  helperText?: string;
  isRequired?: boolean;
  multiple?: boolean; // Default is true
}

const RawMaterialSelection: FC<IRawMaterialSelectionProps> = ({
  name,
  size = 'medium',
  helperText,
  isRequired,
  multiple = true,
}) => {
  const { rawMaterials, createRawMaterial } = useRawMaterials();

  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<
    string[] | string | undefined
  >(name);

  const handleSelectionChange = async (selection: string[] | string | undefined) => {
    await setTouched(true);
    setValue(selection || undefined, true);
  };

  const handleAddNewItemClick = (title: string) => {
    createRawMaterial({ variables: { title } });
  };

  return (
    <FieldWrapper
      label={`Raw material${multiple ? 's' : ''}`}
      labelIcon="raw-material"
      variant={size}
      required={isRequired}
      tooltip={
        helperText
          ? {
              helperText,
              variant: 'INFO',
            }
          : undefined
      }
    >
      <AutocompleteSelection
        label="Select raw material"
        closeAfterSelection
        enableAddNewItem
        multiple={multiple}
        size={size}
        itemName="raw material"
        items={rawMaterials}
        errorMessage={error}
        hasError={!!error && touched}
        selected={value || (multiple ? [] : '')}
        onAddNewItemClick={handleAddNewItemClick}
        onSelectionChange={handleSelectionChange}
      />
    </FieldWrapper>
  );
};

export default RawMaterialSelection;
