import { Box, IconButton, Tabs as MuiTabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, {
  ChangeEvent,
  FC,
  Fragment,
  MouseEvent,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import Icon, { IconNameType } from '../Icon/Icon';

export type ITab<Key extends string = string> = {
  key: Key;
  label: string;
  removable?: boolean;
  error?: boolean;
} & (
  | {
      /**
       * Use the startIconName instead
       * @deprecated
       */
      startIcon?: ReactNode;
    }
  | { startIconName?: IconNameType }
);

export interface ITabsProps<Key extends string = string> {
  enableRemove?: boolean;
  selectedTab?: Key;
  tabs: ITab<Key>[];
  onChange: (value: Key, event: ChangeEvent<Key>) => void;
  onRemoveClick?: (key: Key) => void;
}

const StyledTabs = styled(MuiTabs)(({ theme }) => ({
  minHeight: 38.1,
  height: 38,
  borderBottom: `1px ${theme.custom.themeColors.grayScale[30]} solid`,
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
  },
  '& .MuiTab-textColorPrimary.Mui-selected': {
    color: theme.palette.secondary.main,
  },
  '& .MuiTabs-scroller': {
    minHeight: 38,
  },
}));

const StyledTab = styled(Tab)<{ error: booleanish }>(({ theme, error }) => ({
  color: theme.custom.colors.textLight,
  position: 'relative',
  fontWeight: 700,
  minHeight: 38,
  opacity: 0.7,
  padding: '0 32px',

  '& .MuiButtonBase-root': {
    display: 'none',
  },
  '&:hover .MuiButtonBase-root': {
    display: 'block',
  },
  '& svg, svg > *': {
    fill: error === 'true' ? `${theme.palette.error.main}` : `${theme.custom.colors.textLight}`,
  },
  '& p': {
    color: error === 'true' ? `${theme.palette.error.main}` : `${theme.palette.secondary.main}`,
  },
  '&.Mui-selected, &.Mui-selected p': {
    opacity: 1,
    color: error === 'true' ? `${theme.palette.error.main} ` : `${theme.palette.secondary.main} `,
    '& svg, svg > *': {
      fill: error === 'true' ? `${theme.palette.error.main} ` : `${theme.palette.secondary.main} `,
    },
  },
  '&:not(.Mui-selected):hover': {
    color: error === 'true' ? `${theme.palette.error.main} ` : `${theme.custom.colors.blueFrost} `,
    '& p': {
      color:
        error === 'true' ? `${theme.palette.error.main} ` : `${theme.custom.colors.blueFrost} `,
    },
    '& svg, svg > *': {
      fill: error === 'true' ? `${theme.palette.error.main} ` : `${theme.custom.colors.blueFrost} `,
    },
  },
}));

const RemoveContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(0.5),
}));

const TabContent: FC<
  {
    label: string;
    enableRemove?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onRemoveClick?: () => void;
  } & (
    | {
        startIcon?: ReactNode;
      }
    | { startIconName?: IconNameType }
  )
> = ({ label, enableRemove, onRemoveClick, onMouseEnter, onMouseLeave, ...iconProps }) => {
  let IconNode: ReactNode;

  const handleRemove = (event: MouseEvent) => {
    event.stopPropagation();
    onRemoveClick?.();
    onMouseLeave?.();
  };

  if ('startIcon' in iconProps && iconProps.startIcon) {
    IconNode = (
      <Box display="flex" mr={1.5}>
        {iconProps.startIcon}
      </Box>
    );
  } else if ('startIconName' in iconProps && iconProps.startIconName) {
    IconNode = <Icon name={iconProps.startIconName} mr={1.5} />;
  } else {
    IconNode = <Fragment />;
  }
  return (
    <Box display="flex" alignItems="center">
      {IconNode}
      <ThemeTypography variant="LABEL_INPUT">{label}</ThemeTypography>
      {enableRemove && (
        <RemoveContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <IconButton size="small" color="error" onClick={handleRemove}>
            <Icon name="delete" size="x-small" color="red" />
          </IconButton>
        </RemoveContainer>
      )}
    </Box>
  );
};

/**
 * Generic FC are not supported yet so we need to use a function instead
 */
const Tabs: <Key extends string = string>(props: ITabsProps<Key>) => ReactElement = ({
  selectedTab,
  tabs,
  enableRemove,
  onRemoveClick,
  onChange,
}) => {
  const [hoveredTab, setHoveredTab] = useState<string>();

  // Change order since the event is often not used and the value can be used with less props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: ChangeEvent<any>, value: any) => onChange(value, event);
  const handleMouseLeave = enableRemove ? () => setHoveredTab(undefined) : undefined;

  return (
    <Box display="flex">
      <StyledTabs variant="scrollable" value={selectedTab} onChange={handleChange}>
        {tabs?.map(({ key, label, removable, error, ...iconProps }) => (
          <StyledTab
            key={key}
            error={Booleanish(error || hoveredTab === key)}
            data-cy={`tab-${key}`}
            value={key}
            label={
              <TabContent
                {...iconProps}
                label={label}
                enableRemove={enableRemove && removable}
                onMouseLeave={handleMouseLeave}
                onRemoveClick={() => onRemoveClick?.(key)}
                onMouseEnter={enableRemove ? () => setHoveredTab(key) : undefined}
              />
            }
          />
        ))}
      </StyledTabs>
    </Box>
  );
};

export default Tabs;
