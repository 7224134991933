import React, { MouseEvent, useMemo } from 'react';
import { Tooltip, Box, styled } from '@mui/material';
import { StyledTableBody } from 'components/TableBase';
import {
  StyledIconButton,
  IconContainer,
  StyledCell,
  TableRow,
  Date,
} from './PartnersTableBody.styles';
import { Trash } from '@styled-icons/bootstrap';
import { Edit } from '@styled-icons/boxicons-solid';
import { Partner, PartnerTypeEnum } from 'types/partner.types';
import Moment from 'react-moment';
import { HeaderColumn } from 'types/types';
import { ReactComponent as PinIcon } from 'assets/img/icons/dark-pin.svg';
import PartnerTableTitle from 'components/Partners/PartnersTable/PartnersTableBody/PartnerTableTitle';
import { Booleanish } from 'types/booleanish.types';
import { PartnerHeaderColumnEnum } from '../partnerHeaderColumns';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import { FlexBox } from 'components/Structure';
import Image from 'designSystem/DataDisplay/Image/Image';

interface Props {
  selectedPartner: Partner | undefined;
  partners: Partner[];
  rowsPerPage: number;
  currentPage: number;
  isTransparent?: boolean;
  disablePagination?: boolean;
  headerColumns: HeaderColumn[];
  onSelect: (partner: Partner) => void;
  onEdit?: (partner: Partner) => void;
  onDelete?: (partner: Partner) => void;
}

export const Pin = styled(PinIcon)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  width: 14,
  height: 14,
  color: theme.custom.themeColors.grayScale[100],
}));

const PartnersTableBody: React.FC<Props> = ({
  selectedPartner,
  partners,
  onSelect,
  onEdit,
  onDelete,
  rowsPerPage,
  currentPage,
  isTransparent = false,
  disablePagination,
  headerColumns,
}) => {
  const actions = [
    {
      icon: <Edit size="17" />,
      tooltip: 'Edit partner',
      onClick: onEdit,
      dataCy: 'edit-partner-btn',
    },
    {
      icon: <Trash size="16" />,
      tooltip: 'Delete partner',
      onClick: onDelete,
      dataCy: 'delete-partner-btn',
    },
  ];

  const displayedRows = useMemo(() => {
    if (disablePagination) {
      return partners;
    }
    return partners.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
  }, [disablePagination, partners, currentPage, rowsPerPage]);

  return (
    <StyledTableBody data-cy="partners-table-body">
      {displayedRows.map((partner, index) => {
        const { id, activities } = partner;
        const labelId = `partner-table-${id}`;
        const isSelected = selectedPartner && selectedPartner.id === partner.id;

        return (
          <TableRow
            data-cy="partner-table-row"
            data-cy-id={id}
            hover
            id={labelId}
            is-transparent={Booleanish(isTransparent)}
            key={`${id}-${index}`}
            is-selected={Booleanish(isSelected)}
            onClick={(event: MouseEvent) => {
              event.stopPropagation();
              onSelect(partner);
            }}
          >
            {headerColumns.map(({ id: column, align }) => {
              switch (column) {
                case PartnerHeaderColumnEnum.TITLE:
                  return <PartnerTableTitle key={partner.id} partner={partner} align={align} />;

                case PartnerHeaderColumnEnum.ACTIVITIES:
                  return (
                    <StyledCell key={`${id}-${index}-activities`} scope="row" align={align}>
                      <Box display="flex" alignItems="center">
                        <Pin />
                        {activities.count}
                      </Box>
                    </StyledCell>
                  );

                case PartnerHeaderColumnEnum.PARTNER:
                  return (
                    <StyledCell key={`${id}-${index}-supplier`} scope="row" align={align}>
                      {partner.company?.name ? (
                        <FlexBox>
                          <Image
                            image={partner.company?.logo}
                            width={44}
                            height={44}
                            backupImageUrl={defaultPartnerImage}
                            alt={`Logo of ${partner.company?.name}`}
                          />
                          <Box mr={2} />
                          {partner.company?.name}
                        </FlexBox>
                      ) : (
                        ''
                      )}
                    </StyledCell>
                  );

                case PartnerHeaderColumnEnum.FARM_SIZE:
                  return (
                    <StyledCell key={`${id}-${index}-farmSize`} scope="row" align={align}>
                      {partner.type === PartnerTypeEnum.FARM ? `${partner.farm.size || ''}` : ''}
                    </StyledCell>
                  );

                case PartnerHeaderColumnEnum.FARM_HARVEST_WEIGHT:
                  return (
                    <StyledCell key={`${id}-${index}-farmSize`} scope="row" align={align}>
                      {partner.type === PartnerTypeEnum.FARM
                        ? `${partner.farm.harvestWeight || ''}`
                        : '-'}
                    </StyledCell>
                  );

                case PartnerHeaderColumnEnum.CREATED_TIMESTAMP:
                  return (
                    <StyledCell key={`${id}-${index}-createdTimestamp`} scope="row" align={align}>
                      <Date variant="subtitle2">
                        <Moment format="LL">{partner.createdTimestamp}</Moment>
                      </Date>
                    </StyledCell>
                  );

                case PartnerHeaderColumnEnum.ACTIONS:
                  return (
                    <StyledCell
                      key={`${id}-${index}-actions`}
                      width={100}
                      scope="row"
                      align={align}
                    >
                      <IconContainer>
                        {actions.map((action, index) => {
                          return (
                            <Tooltip key={index} title={action.tooltip}>
                              <StyledIconButton
                                data-cy={action.dataCy}
                                onClick={event => {
                                  event.stopPropagation();
                                  action.onClick?.(partner);
                                }}
                              >
                                {action.icon}
                              </StyledIconButton>
                            </Tooltip>
                          );
                        })}
                      </IconContainer>
                    </StyledCell>
                  );

                default:
                  return (
                    <StyledCell key={`${id}-${column}`} width={100} scope="row" align={align}>
                      {partner[column as keyof unknown]}
                    </StyledCell>
                  );
              }
            })}
          </TableRow>
        );
      })}
    </StyledTableBody>
  );
};

export default PartnersTableBody;
