import { Box, FormHelperText, InputBase, Tooltip, styled } from '@mui/material';
import { ContentCopy } from '@styled-icons/material/ContentCopy';
import { Polygon, geometry } from '@turf/turf';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import copyTextToClipboard from 'utils/clipboard.utils';

interface IJsonEditorProps {
  /** Value for controlled component */
  value?: object;

  /** Default value for uncontrolled component */
  defaultValue?: object;

  /** Placeholder JSON object */
  placeholder?: object;

  /** Custom error message, this error message displayed when value is not valid JSON */
  errorMessage?: string;

  /** Custom hint message */
  hintMessage?: string;

  /** Validation format */
  validationFormat?: 'json' | 'geojson-multi-polygon';

  /** Styles for the sizing
   * @default 300
   */
  minHeight?: number | string;

  /** Determines whether the value should be formatted on blur, `false` by default */
  formatOnBlur?: boolean;

  /** Styles for the sizing
   * @default 14
   */
  rows?: number;

  /** Called when value changes
   * Depending on the input type object or string the value will serialize or deserialize
   * @default value: string
   */
  onChange?: (value: object | null) => void;

  /** Function to serialize value into a string, used for value formatting, `JSON.stringify` by default */
  serialize?: typeof JSON.stringify;

  /** Function to deserialize string value, used for value formatting and input JSON validation, must throw error if string cannot be processed, `JSON.parse` by default */
  deserialize?: typeof JSON.parse;
}

const validateJson = (
  value: string,
  deserialize: typeof JSON.parse,
  serialize: typeof JSON.stringify,
  isGeoJsonMultiPolygon: boolean = false
) => {
  if (typeof value === 'string' && value.trim().length === 0) {
    return true;
  }

  try {
    const parsedValue = deserialize(value);

    if (isGeoJsonMultiPolygon) {
      // Check if it's GeoJSON
      const geoJsonRegex = /\[((?:\[((?:(?:(?:-?\d+\.\d+),\s*)*-?\d+\.\d+\s*)?)]\s*,?\s*)*)?]/;

      if (!serialize(parsedValue).match(geoJsonRegex)) {
        return false;
      }

      // Check if it's a valid polygon
      parsedValue.map((singlePolygon: Polygon) => geometry('Polygon', [singlePolygon]));
    }

    return true;
  } catch (e) {
    return false;
  }
};

const formatValue = (value: string | object, serialize: typeof JSON.stringify): string => {
  try {
    if (typeof value === 'string' && value.trim().length === 0) {
      return value;
    } else {
      return serialize(value, null, 6);
    }
  } catch (e) {
    return 'Failure formatting value';
  }
};

const StyledContainer = styled(Box)<{
  'min-height': number | string;
  error: booleanish;
  'is-focused': booleanish;
}>(({ 'min-height': minHeight, error, 'is-focused': isFocused, theme }) => ({
  position: 'relative',
  background: '#fff',
  border: '1px solid',
  borderColor:
    error === 'true'
      ? theme.palette.error.main
      : isFocused === 'true'
      ? theme.palette.primary.main
      : theme.custom.colors.lightestBorder,
  padding: theme.spacing(1),
  borderRadius: theme.spacing(0.5),
  color: theme.custom.themeColors.success[100],
  minHeight,
  paddingBottom: theme.spacing(4),
}));

const HelperContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(1),
  bottom: theme.spacing(2),
  /** Subtract the width of the copy button */
  maxWidth: 'calc(100% - 150px)',
  backgroundColor: '#fff',
}));

const CopyButton = styled(ThemeButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  bottom: theme.spacing(1),
  color: theme.custom.colors.textLight,
}));

const StyledTextField = styled(InputBase)<{ 'min-height': number | string }>(
  ({ 'min-height': minHeight, theme }) => ({
    width: '100%',
    fontSize: 11,
    fontWeight: 400,

    '& textarea': {
      height: 'auto !important',
      minHeight: minHeight,
      color: theme.custom.themeColors.success[100],
      overflow: 'auto !important',

      '&::placeholder': {
        color: theme.custom.themeColors.grayScale[100],
      },
    },
  })
);

const JsonEditor: FC<IJsonEditorProps> = ({
  value,
  defaultValue,
  errorMessage = 'Invalid JSON',
  validationFormat = 'json',
  hintMessage,
  placeholder,
  minHeight = 300,
  rows = 5,
  formatOnBlur = true,
  onChange,
  serialize = JSON.stringify,
  deserialize = JSON.parse,
}) => {
  const [editorState, setEditorState] = useState<string>(() =>
    formatValue(defaultValue || value || '', serialize)
  );
  const [valid, setValid] = useState<boolean>(true);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isControlled = !!value && onChange;

  useEffect(() => {
    if (isControlled) {
      formatValue(value, serialize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleCopy = () => {
    setTooltipOpen(true);
    copyTextToClipboard(editorState);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditorState(e.currentTarget.value);
  };

  const handleChangeOnBlur = () => {
    const isValid = validateJson(
      editorState,
      deserialize,
      serialize,
      validationFormat === 'geojson-multi-polygon'
    );
    setValid(isValid);
    setIsFocused(false);
    if (formatOnBlur && isValid && editorState.trim() !== '') {
      setEditorState(formatValue(deserialize(editorState), serialize));
    }
    if (isValid) {
      onChange?.(editorState.trim() !== '' ? deserialize(editorState) : null);
    }
  };

  const handleOnFocus = () => {
    setValid(true);
    setIsFocused(true);
  };

  return (
    <StyledContainer
      min-height={minHeight}
      error={Booleanish(!valid)}
      is-focused={Booleanish(isFocused)}
    >
      <Box overflow="auto">
        <StyledTextField
          value={editorState}
          placeholder={placeholder ? serialize(placeholder, null, 6) : undefined}
          multiline
          min-height={minHeight}
          minRows={rows}
          onChange={handleChange}
          inputProps={{
            onBlur: handleChangeOnBlur,
            onFocus: handleOnFocus,
          }}
        />
      </Box>
      <HelperContainer>
        {!valid && <FormHelperText error>{errorMessage}</FormHelperText>}
        {valid && hintMessage && editorState.trim().length === 0 && (
          <FormHelperText>
            <ThemeTypography variant="BODY_MEDIUM" color="GRAY_40">
              {hintMessage}
            </ThemeTypography>
          </FormHelperText>
        )}
      </HelperContainer>
      <Tooltip
        title="Content copied to clipboard!"
        onClose={() => setTooltipOpen(false)}
        open={tooltipOpen}
        leaveDelay={800}
      >
        <CopyButton color="BLUE_ICE" startIcon={<ContentCopy size={14} />} onClick={handleCopy}>
          Copy data
        </CopyButton>
      </Tooltip>
    </StyledContainer>
  );
};

export default JsonEditor;
