import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

const useQueryReturn = (query, options = {}) => {
  const client = useApolloClient();

  return useCallback(
    ({ variables }) =>
      client.query({
        query,
        variables,
        ...options,
      }),
    [client, query, options]
  );
};

export default useQueryReturn;
