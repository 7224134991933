import { useApolloClient } from '@apollo/client';
import { PARTNER_FRAGMENT } from 'graphql/fragments/partner';
import { useParams } from 'react-router-dom';
import { Partner } from 'types/partner.types';
import { RouteParamsWithId } from 'types/router.types';

interface ReturnProps {
  partner: Partner | null;
}

/**
 * Getting the partner that is currently active from the cache
 * Used currently to get the partner name for the sidebar menu
 */
const useActivePartner = (): ReturnProps => {
  const client = useApolloClient();
  const { cache } = client;
  const { id: partnerId } = useParams<RouteParamsWithId>();
  const cacheKey = cache.identify({
    __typename: 'Partner',
    id: partnerId,
  });
  const partnerInCache = client.readFragment<Partner>({
    fragment: PARTNER_FRAGMENT,
    fragmentName: 'partnerValues',
    id: cacheKey,
  });

  // Getting only the cache version cause right now it will already be fetched on a different location
  return { partner: partnerInCache };
};

export default useActivePartner;
