import { Box } from '@mui/material';
import { BoxArrowUpRight } from '@styled-icons/bootstrap/BoxArrowUpRight';
import DocumentSkeleton from 'components/DocumentLibrary/DocumentSkeleton';
import { DocumentTable } from 'components/DocumentLibrary/Table';
import { DocumentTableBodyAction } from 'components/DocumentLibrary/Table/DocumentTableBody';
import { DocumentsTableColumnKeys } from 'components/DocumentLibrary/Table/constants/documentHeaderColumns';
import { Loader, SearchToolbar } from 'components/Forms';
import {
  EmptySearchResults,
  ErrorState,
  InfiniteScrollWrapper,
  ActionContainer,
} from 'components/Structure';
import { useSharedDocuments } from 'components/hooks';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Document } from 'types/types';

const SharedDocuments: FC = () => {
  const {
    error,
    loading,
    documents,
    searchTerm,
    hasNextPage,
    setSearchTerm,
    handlePageEndReached,
  } = useSharedDocuments();
  const navigate = useNavigate();

  const documentTableActions: DocumentTableBodyAction[] = [
    {
      icon: <BoxArrowUpRight size="15" />,
      tooltip: 'Open in new tab',
      onClick: (document: Document) => window.open(document.url, '_blank'),
      dataCy: 'download-document-btn',
    },
  ];

  const opeDocumentOverview = (document: Document) => {
    navigate(`/documents/shared/${document.id}`);
  };

  if (error) {
    return <ErrorState />;
  }
  if (loading) {
    return <Loader />;
  }
  if (!documents.length && !searchTerm) {
    return (
      <Box mt={4}>
        <EmptySearchResults skeletonComponent={<DocumentSkeleton />} />
      </Box>
    );
  }

  return (
    <>
      <Box position="relative">
        <ActionContainer>
          <SearchToolbar
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            data-cy="document-search-input"
            placeholder="Search document"
            className="search-input"
          />
        </ActionContainer>
        <InfiniteScrollWrapper
          hasMore={hasNextPage}
          dataLength={documents.length}
          next={handlePageEndReached}
        >
          <DocumentTable
            columns={[
              DocumentsTableColumnKeys.TITLE,
              DocumentsTableColumnKeys.OWNER,
              DocumentsTableColumnKeys.USAGE,
              DocumentsTableColumnKeys.ISSUE_DATE,
              DocumentsTableColumnKeys.EXPIRY_DATE,
              DocumentsTableColumnKeys.ACTIONS,
            ]}
            documents={documents}
            actions={documentTableActions}
            onDocumentClick={opeDocumentOverview}
            disableSelection
          />
        </InfiniteScrollWrapper>
      </Box>

      {!documents?.length && (
        <EmptySearchResults
          message="No search results found"
          skeletonComponent={<DocumentSkeleton title={searchTerm} />}
        />
      )}
    </>
  );
};

export default SharedDocuments;
