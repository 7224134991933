import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import App from 'App';
import ErrorBoundary from 'components/ErrorBoundary';
import { ConfigProvider } from 'config';
import 'constants/schemas/locale';
import { AmplitudeProvider } from 'contexts/AmplitudeContext';
import { BugsnagProvider } from 'contexts/BugsnagContext';
import CustomProviders from 'contexts/CustomProviders';
import Firebase from 'contexts/Firebase';
import { FirebaseProvider } from 'contexts/FirebaseContext';
import { HeadProvider } from 'contexts/HeadContext';
import MaintenanceProvider from 'contexts/MaintenaceContext';
import { MessageProvider } from 'contexts/MessageContext';
import ApolloProvider from 'graphql/ApolloProvider';
import 'lazysizes';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { createRoot } from 'react-dom/client';
import theme from 'styles/theme';

// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires

// unhandled exceptions and unhandled promise rejections will be reported and automatically appear on the Bugsnag dashboard
Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY as string,
  //starting bugsnag with react integration
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_DEPLOYMENT_ENV,
  enabledReleaseStages: ['stg', 'prd'],
});

const firebase = new Firebase({
  useMock: process.env.REACT_APP_FIREBASE_EMULATOR,
});
const graphqlEndpoint = process.env.REACT_APP_GQL_ENDPOINT;

/** These providers live at the top of the application and are available for child component  */
const OUTER_ROUTER_PROVIDERS = [
  { provider: ThemeProvider, props: { theme } },
  // @ts-ignore // TODO: Refactor upload provider and use tsx
  ConfigProvider as FC<PropsWithChildren>,
  { provider: FirebaseProvider, props: { firebase } },
  { provider: ApolloProvider, props: { firebase, graphqlEndpoint } },
  MessageProvider,
  HeadProvider,
  BugsnagProvider,
  AmplitudeProvider,
  MaintenanceProvider,
];

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <ErrorBoundary>
    <CustomProviders providers={OUTER_ROUTER_PROVIDERS}>
      <CssBaseline />
      <App />
    </CustomProviders>
  </ErrorBoundary>
);
