import { ColDef } from 'ag-grid-community';
import { ITableSettings } from 'types/dataImport.types';
import { SystemColumns } from '../DataImportTable/excelTable.types';

export const DATA_IMPORT_TABLE_ROW_HEIGHT = 30;

export const CROPS_SEPARATOR = ', ';

export const VALIDATION_MESSAGE_EMPTY =
  'Data field is required. Either delete all data from row or add missing data.';

export const DEFAULT_TABLE_SETTINGS: ITableSettings = {
  autoAssignIds: false,
  coordinatesVersion: 'latlng',
  farmSizeUnit: 'ha',
  weightUnit: 'kg',
};

const DEFAULT_COL_DEF: ColDef = { lockPinned: true, flex: 1, minWidth: 150 };
export const DEFAULT_COL_DEF_EDIT: ColDef = {
  ...DEFAULT_COL_DEF,
  editable: true,
};
export const DEFAULT_COL_DEF_VIEW: ColDef = {
  ...DEFAULT_COL_DEF,
  editable: false,
};

export const SYSTEM_COLUMN_DEF: ColDef<SystemColumns> = {
  headerName: '',
  editable: false,
  width: 30,
  maxWidth: 30,
  lockPosition: true,
  resizable: false,
  suppressMovable: true,
};
