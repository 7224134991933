import React, { useEffect, createContext, useContext, FC, PropsWithChildren } from 'react';
import Bugsnag from '@bugsnag/js';
import { useUserData } from 'components/hooks';
import FirebaseContext from './FirebaseContext';

const BugsnagContext = createContext<boolean | null>(null);

const BugsnagProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user, company } = useUserData();
  const { authenticated } = useContext(FirebaseContext);

  // to send user data, we use the BugsangContext. If a user is logged in, the user is set for bugsnag.
  useEffect(() => {
    if (authenticated && user && company) {
      const { id, fullName, email } = user;
      Bugsnag.setUser(id, email, fullName);
      Bugsnag.addMetadata('user', {
        companyID: company.id,
        company: company.name,
      });
    } else {
      Bugsnag.setUser('unregistered');
      Bugsnag.addMetadata('user', {
        companyID: '-',
        company: '-',
      });
    }
  }, [user, company, authenticated]);

  return <BugsnagContext.Provider value={Bugsnag.isStarted()}>{children}</BugsnagContext.Provider>;
};

export { BugsnagProvider };

export default BugsnagContext;
