/**
 * Guarantee uniqueness since multiple activities can have the same id if imported multiple times
 */
const componentChainActivityPolicies = {
  ComponentChainStepActivity: {
    keyFields: ['id', 'pointPosition'],
  },
};

export default componentChainActivityPolicies;
