import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import FormContainer from './FormContainer';
import { useContentBlock } from 'components/Product/MultiStep/hooks';
import MoreProductsDnD from './MoreProductsDnD';
import Header from './Header';
import { Box } from '@mui/material';

const MoreProducts = ({ menu }) => {
  const [{ data, enabled, id }, schema] = useContentBlock(menu.id);

  return (
    <Container>
      <FormContainer
        initialValues={{
          enabled,
          // show empty form initially
          moreProducts: data.moreProducts?.length
            ? data.moreProducts
            : schema.default().moreProducts,
        }}
        schema={schema}
      >
        {({ values, setValues }) => (
          <Fragment>
            <Header
              title={menu.title}
              showEnabled
              contentBlockId={id}
              setContent={setValues}
              contentType={menu.id}
            />
            <Box mt={3}>
              <MoreProductsDnD items={values.moreProducts} />
            </Box>
          </Fragment>
        )}
      </FormContainer>
    </Container>
  );
};

MoreProducts.propTypes = {
  menu: PropTypes.object.isRequired,
};

export default MoreProducts;
