import { Box } from '@mui/material';
import { InfoCircle } from '@styled-icons/bootstrap';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import StepSelectorBlock from 'components/ChainMapping/Forms/StepSelectorBlock';
import useComponentChainActions from 'components/ComponentsLibrary/hooks/useComponentChainActions';
import useComponentItemActions from 'components/ComponentsLibrary/hooks/useComponentItemActions';
import { usePartnerMutations } from 'components/Partners/hooks';
import { useDialog, useLogEvent } from 'components/hooks';
import chainActivitySchema, { activitySchema } from 'constants/schemas/chains';
import { DialogDefault, Tabs, ThemeTypography } from 'designSystem';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import ConditionalRender from 'designSystem/Layout/CondionalRender/ConditionalRender';
import { ITab } from 'designSystem/Primitives/Tabs/Tabs';
import { Form, Formik, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import React, { FC, useEffect, useRef, useState } from 'react';
import { IChainActivity, IChainStep, ISubChain } from 'types/chain.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { AvailableLanguages, AvailableLanguagesType } from 'types/enums';
import { MultiPolygon } from 'types/map.types';
import { IActivity, Partner, PartnerTypeEnum } from 'types/partner.types';
import ActivityCommunicationBlocks from '../Forms/ActivityCommunicationBlocks';
import CompanySelectBlock from '../Forms/CompanySelectBlock';
import CultivationAreasBlock from '../Forms/CultivationAreasBlock';
import LinkConnectionsBlock from '../Forms/LinkConnectionsBlock';
import LocationSelectorBlock from '../Forms/LocationSelectorBlock';
import OutputComponentBlock from '../Forms/OutputComponentBlock';
import {
  BlockBoxContainer,
  BlockContainer,
  BottomContainer,
  ButtonContainer,
  DialogInner,
  FormContainerLeft,
  FormContainerRight,
  ScrollBlockContainer,
  StyledLink,
} from '../Forms/styles';
import { extractActivityMutationValues } from '../utils/activity-form.utils';

export type AddEditActivity =
  | {
      mode: 'add-activity';
      /**
       * The partner / company selector will be hidden and the partner can be preselected with the partner prop
       */
      partner?: Partner;
    }
  | {
      mode: 'edit-activity';
      /**
       * Default tab is communication
       */
      activeTab?: TabTypes;
      /**
       * Location to be edited
       */
      activity: IActivity;
      /**
       * In this language the activity will be edited
       * Default is English
       */
      lang?: AvailableLanguagesType;
      /**
       * Shown on the communication side where you are not able to edit chain related information
       */
      linkToSupplyChainMapping?: string;
      /**
       * Only the communication values like translations and media can be edited
       */
      outputComponentMode?: 'select' | 'change-language';
    };

export type AddEditChainActivity =
  | {
      mode: 'add-chain-activity';
      /**
       * The step where the activity will be added to
       */
      selectedStepId: string;
      activities: IChainActivity[];
      subChains: ISubChain[];
      steps: IChainStep[];
      partner?: Partner;
      onActivityAdd: (
        activityId: string,
        activity: Pick<IChainActivity, 'chainStepId' | 'incomingLinks'>
      ) => void;
    }
  | {
      mode: 'edit-chain-activity';
      activities: IChainActivity[];
      subChains: ISubChain[];
      steps: IChainStep[];
      /**
       * Activity to be edited
       */
      chainStepActivity: Omit<IChainActivity, 'pointPosition'>;
      onActivityEdit: (
        activity: Pick<IChainActivity, 'id'> & Pick<IChainActivity, 'chainStepId' | 'incomingLinks'>
      ) => void;
      onActivityDelete: (activityId: string) => void;
    };

type IAddEditActivityDialogProps = {
  disableDelete?: boolean;
  hidePartnerSelector?: boolean;
} & IDefaultDialogProps &
  (AddEditChainActivity | AddEditActivity);

type TabTypes = 'communication' | 'cultivated-areas';

enum AddEditActivityMode {
  ADD_ACTIVITY = 'add-activity',
  EDIT_ACTIVITY = 'edit-activity',
  ADD_CHAIN_ACTIVITY = 'add-chain-activity',
  EDIT_CHAIN_ACTIVITY = 'edit-chain-activity',
}

type FormValues =
  | Pick<IChainActivity, 'chainStepId' | 'incomingLinks' | 'activity'>
  | {
      activity?: Omit<IActivity, 'cultivatedAreas'> & {
        coordinates?: MultiPolygon;
      };
    }
  | NonNullable<unknown>;

/**
 * The dialog can be opened in different modes controlling which information is shown and can be edited or created
 * AddEditActivityMode.ADD_ACTIVITY | AddEditActivityMode.EDIT_ACTIVITY | AddEditActivityMode.ADD_CHAIN_ACTIVITY | AddEditActivityMode.EDIT_CHAIN_ACTIVITY;
 */
const AddEditActivityDialog: FC<IAddEditActivityDialogProps> = ({
  open,
  disableDelete,
  hidePartnerSelector,
  onClose,
  ...props
}) => {
  const formRef = useRef<FormikProps<FormValues>>(null);
  const { logEvent } = useLogEvent();
  const { openDialog } = useDialog();
  const { createActivity: createPartnerActivity, handleRemoveActivity } = usePartnerMutations();
  const { updateActivityPartner: updatePartnerActivity } = useComponentChainActions();
  const { updateComponent } = useComponentItemActions();

  const [selectedTab, setSelectedTab] = useState<TabTypes | undefined>(
    props.mode === AddEditActivityMode.EDIT_ACTIVITY && props.activeTab
      ? props.activeTab
      : 'communication'
  );

  useEffect(() => {
    if (selectedTab === 'cultivated-areas') {
      logEvent('VISIT_POLYGON_DRAWING');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const mode = props.mode;
  const isChainActivity =
    mode === AddEditActivityMode.ADD_CHAIN_ACTIVITY ||
    mode === AddEditActivityMode.EDIT_CHAIN_ACTIVITY;
  const isEdit =
    mode === AddEditActivityMode.EDIT_CHAIN_ACTIVITY || mode === AddEditActivityMode.EDIT_ACTIVITY;
  const outputComponentMode = 'outputComponentMode' in props ? props.outputComponentMode : 'select';

  const initialData = isChainActivity
    ? {
        ...chainActivitySchema.default(),
        ...(isEdit
          ? {
              ...omit(props.chainStepActivity, 'pointPosition'),
            }
          : {
              chainStepId: props.selectedStepId,
              activity: {
                ...activitySchema.default()?.activity,
                partner: props.partner,
              },
            }),
      }
    : {
        ...activitySchema.default(),
        activity: {
          ...activitySchema.default()?.activity,
          ...(isEdit
            ? { ...props.activity, coordinates: props.activity.cultivatedAreas?.coordinates }
            : { partner: props.partner }),
        },
      };

  const tabs: ITab<TabTypes>[] | undefined =
    !isChainActivity &&
    ((isEdit && props.activity.partner?.type === PartnerTypeEnum.FARM) ||
      (!isEdit && props.partner?.type === PartnerTypeEnum.FARM))
      ? [
          {
            key: 'communication',
            label: 'Communication',
            startIconName: 'communication',
          },
          {
            key: 'cultivated-areas',
            label: 'Cultivation areas',
            startIconName: 'farm',
          },
        ]
      : undefined;

  const handleSubmit = async (values: FormValues) => {
    let activityResponse;
    if (isEdit && 'activity' in values && values.activity && initialData.activity) {
      // If we are editing the communication of an activity and the component title has changed, we need to update the correct component language
      if (
        outputComponentMode === 'change-language' &&
        values.activity.component &&
        values.activity.component.title !== (initialData.activity as IActivity).component?.title
      ) {
        updateComponent({
          variables: {
            id: values.activity.component.id,
            input: {
              ...omit(values.activity.component, ['id', 'rawMaterials']),
              rawMaterialIds: values.activity.component.rawMaterials.map(({ id }) => id),
            },
            lang: ('lang' in props && props.lang) || AvailableLanguages.ENGLISH,
          },
        });
      }

      const newActivityValues = extractActivityMutationValues(values);
      const hasActivityChanged = !isEqual(
        newActivityValues,
        extractActivityMutationValues(initialData as { activity: IActivity })
      );
      if (hasActivityChanged) {
        await updatePartnerActivity({
          variables: {
            id: values.activity.id,
            input: {
              ...newActivityValues,
            },
            lang: !isChainActivity && props.lang ? props.lang : AvailableLanguages.ENGLISH,
          },
        });
      }
    } else if ('activity' in values && values.activity) {
      // with the id in the response we can create the activity
      activityResponse = await createPartnerActivity({
        variables: {
          input: {
            partnerId: values.activity.partner?.id || null,
            locationCoordinates: values.activity.locationCoordinates,
            componentId: values.activity.component?.id,
            description: values.activity.description,
            title: values.activity.title,
            name: values.activity.name,
            mediaList: values.activity.mediaList,
            coordinates: 'coordinates' in values.activity ? values.activity.coordinates : undefined,
          },
        },
      });
    }

    if (isChainActivity && 'chainStepId' in values && 'incomingLinks' in values) {
      const initialActivityData = pick(initialData, ['chainStepId', 'incomingLinks']);
      const currentActivityData = pick(values, ['chainStepId', 'incomingLinks']);
      if (isEdit) {
        if (!isEqual(initialActivityData, currentActivityData)) {
          props.onActivityEdit({ id: props.chainStepActivity.id, ...currentActivityData });
        }
      } else {
        if (!activityResponse?.data?.createActivity?.activity?.id) {
          throw new Error('Partner location id is not defined');
        }
        props.onActivityAdd(activityResponse.data.createActivity.activity?.id, currentActivityData);
      }
    }
    onClose?.();
  };

  // If there are unsaved changes, ask the user if they want to leave the page
  const handleClose = () => {
    if (formRef.current && !isEqual(formRef.current.values, initialData)) {
      openDialog({
        type: 'ALERT',
        props: {
          title: 'Unsaved changes',
          text: 'Are you sure you want to close this window? All unsaved changes will be lost and you will not be able to undo this action.',
          submitText: 'Close',
          displayCloseButton: true,
          onSubmit: onClose,
          onCancel: () => undefined,
        },
      });
    } else {
      onClose?.();
    }
  };

  const handleDeleteClick = () => {
    // Delete is only possible in edit mode
    if (!disableDelete && isEdit) {
      if (isChainActivity) {
        props.onActivityDelete(props.chainStepActivity.id);
      } else {
        handleRemoveActivity({ activityId: props.activity.id });
      }
      onClose?.();
    }
  };

  return (
    <DialogDefault
      title={!isEdit ? 'New activity' : 'Edit activity'}
      onClose={handleClose}
      data-cy="add-edit-activity-dialog"
      open={open}
      fullWidth
      icon={!isEdit ? Plus : Edit}
      maxWidth="xl"
    >
      <Formik
        innerRef={formRef}
        initialValues={initialData}
        enableReinitialize
        validateOnChange
        initialStatus={{
          lang: isEdit && !isChainActivity && props.lang ? props.lang : AvailableLanguages.ENGLISH,
        }}
        validationSchema={isChainActivity ? chainActivitySchema : activitySchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, values, initialValues }) => (
          <Form>
            <DialogInner container min-height="70vh">
              <FormContainerLeft item xs={6}>
                <BlockContainer container overflowY="auto">
                  <StepSelectorBlock steps={isChainActivity ? props.steps : undefined} />

                  <LocationSelectorBlock />

                  {isChainActivity && props.steps && props.activities && (
                    <LinkConnectionsBlock
                      steps={props.steps}
                      activities={props.activities}
                      subChains={props.subChains}
                    />
                  )}

                  {!hidePartnerSelector && <CompanySelectBlock />}

                  <OutputComponentBlock
                    mode={outputComponentMode}
                    partnerView={hidePartnerSelector}
                  />
                </BlockContainer>

                {isEdit && !isChainActivity && props.linkToSupplyChainMapping && (
                  <BottomContainer>
                    <InfoCircle size={14} />
                    <Box mr={1} />
                    <ThemeTypography variant="BODY_LARGE">
                      To edit supply chain details of this activity, go to the{' '}
                    </ThemeTypography>
                    <Box mr={0.5} />
                    <StyledLink to={props.linkToSupplyChainMapping} onClick={onClose}>
                      Supply chain mapping tool.
                    </StyledLink>
                  </BottomContainer>
                )}
              </FormContainerLeft>

              <FormContainerRight item xs={6}>
                <ConditionalRender condition={tabs}>
                  {({ True, False }) => (
                    <>
                      <True>
                        <BlockBoxContainer height="calc(100% - 100px)">
                          <Tabs
                            tabs={tabs || []}
                            selectedTab={selectedTab}
                            onChange={setSelectedTab}
                          />
                          <ScrollBlockContainer height={'calc(100% - 64px)'}>
                            {selectedTab === 'communication' ? (
                              <ActivityCommunicationBlocks />
                            ) : (
                              <CultivationAreasBlock />
                            )}
                          </ScrollBlockContainer>
                        </BlockBoxContainer>
                      </True>
                      <False>
                        <BlockContainer
                          container
                          z-index={0}
                          height="calc(100% - 100px)"
                          overflowY="auto"
                        >
                          <ActivityCommunicationBlocks />
                        </BlockContainer>
                      </False>
                    </>
                  )}
                </ConditionalRender>

                <ButtonContainer justifyContent="space-between">
                  <Box>
                    {isEdit && !disableDelete && (
                      <ThemeButton
                        color="BLUE_ICE"
                        size="large"
                        type="button"
                        onClick={handleDeleteClick}
                        data-cy="delete-activity-form-btn"
                        startIcon={<Trash size={14} />}
                      >
                        Delete
                      </ThemeButton>
                    )}
                  </Box>

                  <Box display="flex" justifyContent="end" gap={1.8}>
                    <ThemeButton color="BLUE_ICE" size="large" type="button" onClick={handleClose}>
                      Cancel
                    </ThemeButton>

                    <ThemeButton
                      data-cy="submit-activity-form-btn"
                      loading={isSubmitting}
                      color="YELLOW"
                      size="large"
                      type="submit"
                      disabled={!isValid || isEqual(values, initialValues)}
                    >
                      {isEdit ? 'Apply changes' : 'Save'}
                    </ThemeButton>
                  </Box>
                </ButtonContainer>
              </FormContainerRight>
            </DialogInner>
          </Form>
        )}
      </Formik>
    </DialogDefault>
  );
};

export default AddEditActivityDialog;
