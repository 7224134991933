import { useMutation } from '@apollo/client';
import { useDialog, useMessages } from 'components/hooks';
import {
  CREATE_PARTNER,
  CREATE_PARTNER_LOCATION,
  DELETE_PARTNER,
  DELETE_PARTNER_LOCATION,
  TRANSLATE_PARTNER_LOCATION,
  UPDATE_PARTNER,
  UPDATE_PARTNER_LOCATION,
} from 'graphql/mutations/partners';
import {
  GET_ALL_BASE_PARTNERS,
  GET_PARTNER,
  GET_PARTNER_ACTIVITIES,
} from 'graphql/queries/partners';
import { AvailableLanguages } from 'types/enums';
import { IActivity, Partner } from 'types/partner.types';
import { CreateActivityInput, TranslateActivityInput, UpdateActivityInput } from 'types/types';

interface Props {
  onClose?: () => void;
}

const usePartnerMutations = (props?: Props) => {
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const { openDialog, closeDialog } = useDialog();

  const [deletePartners, { loading: isDeleting }] = useMutation<
    { deletePartners: { ids: string[] } },
    { ids: string[] }
  >(DELETE_PARTNER, {
    onCompleted: () => {
      setSuccessMessage('Partner deleted');
    },
    onError: () => setErrorMessage('There was an error deleting your partner'),
    update: (cache, { data }) => {
      const partnerData = cache.readQuery<{ partners: { edges: { node: Partner }[] } }>({
        query: GET_ALL_BASE_PARTNERS,
      });
      const partners = partnerData?.partners;
      const deletedIds = new Set(data?.deletePartners.ids);
      cache.writeQuery({
        query: GET_ALL_BASE_PARTNERS,
        data: {
          partners: {
            ...partners,
            edges: partners?.edges.filter(edge => !deletedIds.has(edge.node.id)),
          },
        },
      });
    },
  });

  const [updatePartner, { loading: isUpdating }] = useMutation<
    { partner: Partner },
    { input: Partial<Partner> }
  >(UPDATE_PARTNER, {
    onCompleted: () => {
      setSuccessMessage('Partner successfully updated');
      props?.onClose?.();
    },
    onError: () => setErrorMessage('There was an error updating your partner'),
  });

  const [createPartner, { loading: isCreating }] = useMutation<
    { createPartner: { partner: Partner } },
    { input: Omit<Partner, 'id' | 'company'> & { companyId?: string } }
  >(CREATE_PARTNER, {
    onCompleted: () => {
      setSuccessMessage('Partner successfully created');
      props?.onClose?.();
    },
    onError: () => setErrorMessage('There was an error creating your partner'),
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          partners(existingPartners = { edges: [] }) {
            return {
              ...existingPartners,
              edges: [
                { node: data?.createPartner.partner, __typename: 'PartnerEdge' },
                ...existingPartners.edges,
              ],
            };
          },
        },
      });
    },
  });

  const [createActivity] = useMutation<
    { createActivity: { activity: IActivity } },
    { input: CreateActivityInput; lang?: AvailableLanguages }
  >(CREATE_PARTNER_LOCATION, {
    onCompleted: () => {
      setSuccessMessage('Activity successfully created');
      props?.onClose?.();
    },
    onError: () => {
      setErrorMessage('There was an error creating the activity');
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          activities(existingActivities = { edges: [] }) {
            return {
              ...existingActivities,
              edges: [
                { node: data?.createActivity.activity, __typename: 'ActivityEdge' },
                ...existingActivities.edges,
              ],
            };
          },
        },
      });
    },
    refetchQueries: [GET_PARTNER],
  });

  const [translateActivity] = useMutation<
    { activity: IActivity },
    { id: string; input: TranslateActivityInput }
  >(TRANSLATE_PARTNER_LOCATION, {
    onError: () => setErrorMessage('There was an error translating the location'),
  });

  const [updateActivity] = useMutation<
    { updateActivity: { activity: IActivity } },
    { id: string; lang: AvailableLanguages; input: UpdateActivityInput }
  >(UPDATE_PARTNER_LOCATION);

  const [removeActivity] = useMutation<{ deleteActivities: { ids: string[] } }, { ids: string[] }>(
    DELETE_PARTNER_LOCATION,
    {
      onCompleted: () => {
        setSuccessMessage('Activity is deleted');
      },
      onError: () => setErrorMessage('There was an error deleting the activity'),
      update: (cache, { data }) => {
        const activityData = cache.readQuery<{ activities: { edges: { node: IActivity }[] } }>({
          query: GET_PARTNER_ACTIVITIES,
        });
        const activities = activityData?.activities;
        const deletedIds = new Set(data?.deleteActivities.ids);
        cache.writeQuery({
          query: GET_PARTNER_ACTIVITIES,
          data: {
            activities: {
              ...activities,
              edges: activities?.edges.filter(edge => !deletedIds.has(edge.node.id)),
            },
          },
        });
      },
      refetchQueries: [GET_PARTNER],
    }
  );

  const handleCreatePartner = async (values: Omit<Partner, 'id'> & { companyId?: string }) => {
    return await createPartner({
      variables: {
        input: values,
      },
    });
  };

  const handleRemovePartner = async ({ partnerId }: { partnerId: string }) => {
    return await deletePartners({
      variables: {
        ids: [partnerId],
      },
    });
  };

  const handleRemoveActivity = async ({
    activityId,
    alertFirst = false,
  }: {
    activityId: string;
    alertFirst?: boolean;
  }) => {
    const mutationProps = {
      variables: {
        ids: [activityId],
      },
    };

    if (alertFirst) {
      openDialog({
        type: 'ALERT',
        props: {
          title: 'Delete activity',
          text: 'Are you sure you want to delete this location? All information will be lost and you will not be able to get this information back.',
          onSubmit: async () => {
            await removeActivity(mutationProps);
          },
          submitText: 'Delete',
          onCancel: () => {
            closeDialog();
          },
        },
      });
      return;
    }

    return await removeActivity(mutationProps);
  };

  const handleCreateActivity = ({
    variables,
  }: {
    variables: { input: CreateActivityInput; lang?: AvailableLanguages };
  }) => {
    return createActivity({
      variables,
    });
  };

  const handleUpdatePartner = async (values: Partial<Partner>) => {
    return await updatePartner({
      variables: {
        input: {
          title: values.title,
          id: values.id,
          logo: values.logo,
          type: values.type,
          externalId: values.externalId,
        },
      },
    });
  };

  const handleTranslateActivity = async (partnerId: string, input: TranslateActivityInput) => {
    return await translateActivity({
      variables: {
        id: partnerId,
        input,
      },
    });
  };

  return {
    handleRemovePartner,
    handleCreatePartner,
    handleUpdatePartner,
    loading: isDeleting || isCreating || isUpdating,
    createActivity: handleCreateActivity,
    handleTranslateActivity,
    updateActivity,
    handleRemoveActivity,
  };
};

export default usePartnerMutations;
