import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { createTheme } from '@mui/material/styles';
import 'draft-js/dist/Draft.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'styles/app.css';
import { themeColors } from './themeColors';
import { CustomShadows } from 'types/theme';
import { IconButton } from '@mui/material';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React from 'react';

const fontFamilyTitle = ['Montserrat', 'Lato', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',');
const fontFamilyBase = ['Lato', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',');

const customShadows: CustomShadows = [
  'none',
  // button
  '0 2px 10px 1px rgba(0, 0, 0, 0.1)',
  // drawer
  '2px 0 13px 3px rgba(0, 0, 0, 0.1)',
  '0px 2px 10px 1px rgba(0, 0, 0, 0.04)',
  // product cards
  '0px 4px 26px rgba(0, 0, 0, 0.12)',
  '0px 2px 16px 0px rgba(0, 0, 0, 0.11)',
];

const theme = createTheme({
  palette: {
    secondary: {
      light: '#253561',
      main: '#0C193B',
    },
    primary: {
      main: '#FFB801',
      light: '#FFFAEB',
    },
    text: {
      primary: '#000',
      secondary: '#484242',
    },
    info: {
      light: '#E9EDF6',
      main: '#4F6FBD',
    },
    success: {
      light: '#edffda',
      main: '#2DB875',
    },
    error: {
      light: '#FEF6F6',
      main: '#E74E4E',
    },
    warning: {
      light: '#ffede7',
      main: '#F37B53',
    },
    background: {
      default: '#F7F7F7',
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: ['Lato', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontFamily: fontFamilyTitle,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 400,
      fontSize: 14,
    },
    body2: {
      fontWeight: 500,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  custom: {
    shadows: customShadows,
    editBorderStyle: '1px dashed transparent',
    editBorderStyleHover: '1px dashed #616161',
    editorSidebarWidth: 500,
    dropContainerHeight: 660,
    dropContainerWidth: 375,
    drawerClosedWidth: 73,
    drawerWidth: 230,
    appBarHeight: 70,
    menuHoverShadow: '0 0 23px -10px rgba(0, 0, 0, 0.5)',
    maxPageWidth: 2120,
    defaultTransition: (type: string) => `${type} .2s ease`,
    fontFamilyTitle,
    fontFamilyBase,
    themeColors: themeColors,
    colors: {
      actionButtonHover: '#AFAFAF',
      lightBorderColor: '#EEEEEE',
      lightestBorder: '#F4F4F4',
      almostBlack: '#3B3B3B',
      backgroundLight: '#F4F5FA',
      backgroundMedium: '#F9F9F9',
      blueFrost: '#B2BCEB',
      secondaryLighter: '#5D6D98',
      secondaryLightest: '#EAF0F6',
      textLight: '#7B7B7B',
      offWhite: '#FCFCFC',
      seedtraceOrange: '#F6A92D',
      babyBlue: '#62D6EF',
    },
  },
  statusColor: {
    APPROVED: '#2DB875',
    CREATED: '#000',
    UPDATED: '#B2BCEB',
    REQUESTED: '#B2BCEB',
    DENIED: '#E74E4E',
    ARCHIVED: '#AFAFAF',
    DELETED: '#AFAFAF',
  },
});

theme.components = {
  MuiRadio: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      body1: {},
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: (
        <IconButton>
          <Icon name="chevron-down" color="gray-40" />
        </IconButton>
      ),
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 700,
        padding: theme.spacing(1.1, 5, 1.1, 5),
        borderRadius: 4,
        letterSpacing: 0.25,
        lineHeight: 1.4,

        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(1.1, 4, 1.1, 4),
        },

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.1, 3, 1.1, 3),
        },
      },
      text: {
        padding: theme.spacing(1.1, 2),
      },
      containedPrimary: {
        color: theme.palette.secondary.main,

        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
          opacity: 0.8,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[300],
          '& .MuiButton-startIcon svg': {
            color: theme.custom.themeColors.grayScale[40],
          },
        },
        '&.Mui-disabled:hover': {
          backgroundColor: theme.palette.grey[300],
        },
      },
      contained: {
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[300],
        },
      },
      outlined: {
        padding: theme.spacing(0.8, 4, 0.8, 4),
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          border: `1px solid ${theme.custom.colors.backgroundLight}`,
        },
      },
      containedSecondary: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
          color: '#FFF',
          opacity: 0.8,
        },
      },
      colorInherit: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.custom.colors.backgroundLight,

        '&:hover': {
          color: theme.custom.colors.secondaryLighter,
          backgroundColor: theme.custom.colors.backgroundLight,
          opacity: 0.8,
        },
      },
      containedSizeSmall: {
        padding: theme.spacing(0.5, 2, 0.5, 2),
      },
      sizeSmall: {
        height: theme.spacing(3),
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          marginBottom: 4,
        },
        '&:not(.MuiInputBase-input).MuiInputBase-sizeSmall': {
          height: 30,
          '& .MuiInputBase-input': {
            padding: '0 12px',
          },
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {},
      outlined: {
        fontSize: '0.85rem',
        '&.MuiInputLabel-sizeSmall': {
          lineHeight: '0.9em',
        },

        '&.Mui-focused': {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: theme.custom.colors.offWhite,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
        color: theme.palette.secondary.main,
        fontWeight: 700,

        '&.small': {
          fontSize: '0.8125rem',
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        input: {
          fontSize: 14,
          fontWeight: 500,
          padding: '12px 14px',
          background: theme.palette.common.white,
        },

        '& .MuiInputBase-root': {
          '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.custom.themeColors.grayScale[20],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },

          '&.Mui-error': {
            marginBottom: 4,
          },
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        padding: '12px 14px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        input: {
          fontSize: 14,
          fontWeight: 500,
          padding: '12px 14px',
          background: theme.palette.common.white,
          height: '1.1876em',
          borderRadius: 4,
        },

        '&.MuiInputBase-root, & .MuiInputBase-root': {
          background: theme.palette.common.white,

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.custom.themeColors.grayScale[20],
          },

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      contained: {
        position: 'absolute',
        bottom: 4,
        marginLeft: 0,
        transform: 'translateY(100%)',
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiPaper-root': {
          overflow: 'hidden',
          maxHeight: '98vh',
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        '&.MuiTypography-h6': {
          padding: theme.spacing(3, 3, 2),
          fontSize: '16px',
          fontWeight: 300,
          textTransform: 'uppercase',
          lineHeight: '19px',
          letterSpacing: '0.1em',
          fontFamily: theme.custom.fontFamilyTitle,
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: theme.spacing(2, 3),
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
  MuiIconButton: {},
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        height: 8,
        borderRadius: 8,
      },
      colorPrimary: {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        '& .MuiTypography-body2': {
          fontWeight: 500,
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1, 3, 2.5),
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 58,
        height: 40,
      },
      thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
      },
      switchBase: {
        top: 5,
        left: 5,

        '&.Mui-checked': {
          transform: 'translateX(18px)',
        },
      },
      colorPrimary: {
        '&.Mui-checked': {
          color: theme.palette.common.white,
        },
        '&.Mui-checked + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.success.main,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: theme.palette.grey[400],
        },
      },
      track: {
        borderRadius: 20,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderRadius: 6,
      },
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        boxShadow: 'none',
        border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
        '&.MuiButtonGroup-root': {
          backgroundColor: theme.custom.themeColors.grayScale[10],
          padding: theme.spacing(0.25),
          '& :not(:last-of-type)': {
            marginRight: theme.spacing(0.5),
          },
        },
        '& .MuiButton-root': {
          border: 'none',
          backgroundColor: theme.custom.themeColors.grayScale[10],
          color: theme.custom.themeColors.grayScale[60],
          '&:hover': {
            backgroundColor: theme.custom.themeColors.grayScale[20],
          },
          '&.selected': {
            backgroundColor: theme.custom.themeColors.white,
            color: theme.custom.colors.almostBlack,
          },
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
  },
};

export default theme;
