import { CustomCellEditorProps } from 'ag-grid-react';
import useRawMaterials from 'components/ComponentsLibrary/hooks/useRawMaterials';
import AutocompleteSelection from 'designSystem/Inputs/AutocompleSelection/AutocompleteSelection';
import React, { FC, useState } from 'react';

const CROPS_SEPARATOR = ', ';

const CropCellEditor: FC<CustomCellEditorProps> = ({ value, onValueChange, stopEditing }) => {
  const { rawMaterials, createRawMaterial } = useRawMaterials();

  const initialTitles = value ? value.split(CROPS_SEPARATOR) : [];
  const initialIds = initialTitles
    .map((title: string) => rawMaterials.find(rawMaterial => rawMaterial.title === title)?.id)
    .filter(Boolean);

  const [selectedIds, setSelectedIds] = useState<string[]>(initialIds);

  const getCropTitles = (ids: string[]) =>
    ids
      .map((id: string) => rawMaterials.find(rawMaterial => rawMaterial.id === id)?.title)
      .filter(Boolean)
      .join(CROPS_SEPARATOR);

  const handleAddNewItemClick = (title: string) => {
    createRawMaterial({ variables: { title } });
  };

  const handleRawMaterialSelect = (newIds: string[] | string | undefined) => {
    setSelectedIds(newIds as string[]);
    onValueChange(getCropTitles(newIds as string[]));
    stopEditing();
  };

  return (
    <AutocompleteSelection
      label="Select raw material"
      closeAfterSelection
      defaultOpen
      enableAddNewItem
      multiple
      size="small"
      itemName="raw material"
      items={rawMaterials}
      errorMessage={'error'}
      hasError={false}
      selected={selectedIds}
      maxWidth={150}
      onAddNewItemClick={handleAddNewItemClick}
      onSelectionChange={handleRawMaterialSelect}
    />
  );
};

export default CropCellEditor;
