import { Box } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap';
import { ClaimTitleAndStatus } from 'components/ImpactClaims/ClaimShared';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { CardContainer } from 'designSystem';
import { ThemeTypography, InfoTooltip } from 'designSystem';
import React, { useState } from 'react';
import { StyledIconButton } from '../Activities/ActivitiesList';
import { useDialog } from 'components/hooks';
import { Partner } from 'types/partner.types';
import { ImpactClaim } from 'types/impactClaimTypes';
import { ShowMoreText } from 'components/Structure';

interface Props {
  partner: Partner;
  impactClaims: ImpactClaim[];
  actions: {
    icon: JSX.Element;
    tooltip: string;
    onClick: (claim: ImpactClaim) => void;
    dataCy?: string | undefined;
  }[];
}

const PartnerImpactClaimList: React.FC<Props> = ({ partner, impactClaims, actions }) => {
  const { openDialog } = useDialog();
  const [showMore, setShowMore] = useState(false);
  const filteredClaims: ImpactClaim[] = showMore ? impactClaims : impactClaims.slice(0, 1);

  return (
    <CardContainer>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <ThemeTypography variant={'BUTTON_LARGE'}>Impact claims</ThemeTypography>
          <InfoTooltip
            variant="INFO"
            text="Impact claims on partner level are a way to prove the social and environmental impact of your partners and their part of the supply chain process"
          />
        </Box>
        <ThemeButton
          color="BLUE_ICE"
          size="medium"
          variant="contained"
          startIcon={<Plus size={20} />}
          onClick={() => {
            openDialog({ type: 'CREATE_IMPACT_CLAIM', props: { partner } });
          }}
        >
          Add new claim
        </ThemeButton>
      </Box>

      <Box>
        {filteredClaims.map(claim => {
          return (
            <Box mt={1} key={claim.impactCatalogItem.id}>
              <CardContainer hasBorder hasShadow={false} padding={1.5}>
                <Box display="flex" justifyContent="space-between">
                  <ClaimTitleAndStatus
                    impactCatalogItem={claim.impactCatalogItem}
                    loading={false}
                    claimStatus={claim.status}
                    transparent={false}
                  />

                  <Box display="flex" alignItems="center">
                    {actions.map((action, index) => {
                      return (
                        <StyledIconButton
                          key={`action-${claim.id}-${index}`}
                          onClick={event => {
                            event.stopPropagation();
                            action.onClick(claim);
                          }}
                        >
                          {action.icon}
                        </StyledIconButton>
                      );
                    })}
                  </Box>
                </Box>
              </CardContainer>
            </Box>
          );
        })}

        {impactClaims.length > 1 && (
          <Box mt={2}>
            <ShowMoreText
              showMore={showMore}
              setShowMore={setShowMore}
              showMoreText={`See all (${impactClaims.length})`}
            />
          </Box>
        )}
      </Box>
    </CardContainer>
  );
};

export default PartnerImpactClaimList;
