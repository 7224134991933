import { IActivity } from 'types/partner.types';
import omit from 'lodash/omit';
import { MultiPolygon } from 'types/map.types';

export const extractActivityMutationValues = (values: {
  activity?: Omit<IActivity, 'cultivatedAreas'> & {
    coordinates?: MultiPolygon; // TODO: Really bad backend naming should be called cultivatedAreas
  };
}) => ({
  partnerId: values.activity?.partner?.id || null,
  componentId: values.activity?.component?.id || null,
  ...omit(values.activity, [
    'id',
    'partnerId',
    'partner',
    'component',
    'langs',
    'cultivatedAreas',
    'createdTimestamp',
  ]),
});
