import { useMutation } from '@apollo/client';
import { useDialog, useLogEvent, useMessages } from 'components/hooks';
import { CREATE_PRODUCT, DUPLICATE_PRODUCT } from 'graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { ProductFormValues } from '../ProductForm';

const useCreateProduct = () => {
  const navigate = useNavigate();
  const { logEvent } = useLogEvent();
  const { setErrorMessage } = useMessages();
  const { closeDialog } = useDialog();

  const [createProduct] = useMutation(CREATE_PRODUCT, {
    onCompleted: ({
      createNewProduct: {
        product: { id },
      },
    }) => {
      closeDialog();
      navigate(`/products/${id}`);
      logEvent('CREATE_NEW_PRODUCT', {
        productId: id,
      });
    },
    onError: () => {
      setErrorMessage('Something went wrong creating your product. Please try again!');
    },
    fetchPolicy: 'no-cache',
  });

  const [duplicateProduct] = useMutation(DUPLICATE_PRODUCT, {
    onCompleted: ({
      duplicateProduct: {
        product: { id },
      },
    }) => {
      closeDialog();
      navigate(`/products/${id}`);
      logEvent('DUPLICATE_PRODUCT', {
        productId: id,
      });
    },
    onError: () => {
      setErrorMessage('Something went wrong duplicating your product. Please try again!');
    },
    fetchPolicy: 'no-cache',
  });

  const onCreate = async ({ title, slug, languageConfig, articleNumber }: ProductFormValues) => {
    return createProduct({
      variables: {
        input: {
          title,
          slug: slug.toLowerCase(),
          articleNumber,
          languageConfig: {
            ...languageConfig,
            enabled: [languageConfig.default],
          },
        },
      },
    });
  };

  const onDuplicate = async ({
    title,
    slug,
    id,
    articleNumber,
  }: ProductFormValues & { id: string }) => {
    return duplicateProduct({
      variables: {
        id,
        input: {
          articleNumber,
          title,
          slug: slug.toLowerCase(),
        },
      },
    });
  };

  return {
    onDuplicate,
    onCreate,
  };
};

export default useCreateProduct;
