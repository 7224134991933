import { Box, Grid, Link } from '@mui/material';
import { ReactComponent as Pin } from 'assets/img/icons/pin.svg';
import { GeolocationAutocomplete } from 'components/Forms';
import SelectableMap from 'components/Forms/SelectableMap';
import { FieldWrapper } from 'designSystem';
import { Field } from 'formik';
import React, { FC, useState } from 'react';
import { OpenMapAction, ShadowedFieldBlock } from './styles';
import CoordinatesInput from 'designSystem/Formik/CoordinatesInputField/CoordinatesInputField';

const LocationSelectorBlock: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const [mapOpen, setMapOpen] = useState<boolean>(false);

  return (
    <ShadowedFieldBlock container grid-gap={2}>
      <Grid item xs={12}>
        {!disabled && (
          <OpenMapAction>
            {mapOpen ? (
              <>
                Can&apos;t get the right location?{' '}
                <Link onClick={() => setMapOpen(false)}>Search address instead</Link>
              </>
            ) : (
              <>
                Can&apos;t find the location?{' '}
                <Link onClick={() => setMapOpen(true)}>Select on map or add coordinates</Link>
              </>
            )}
          </OpenMapAction>
        )}
        <Grid>
          <FieldWrapper
            label="Location"
            required
            tooltip={{
              variant: 'INFO',
              helperText:
                'Enter the physical location, so that you and your customers can follow your supply chain on the map. You can enter an address or select on the map. You can later choose if you want to show the exact location externally or not.',
            }}
          >
            {mapOpen ? (
              <Grid item xs={12}>
                <Box display="flex" gap={1} alignItems="center" width="100%" mb={1.5} mt={0.5}>
                  <Pin width={16} />
                  <Field
                    component={CoordinatesInput}
                    name="activity.locationCoordinates"
                    disabled={disabled}
                    locationNameKey="activity.name"
                  />
                </Box>
                <Box height={200}>
                  <Field
                    component={SelectableMap}
                    name="activity.locationCoordinates"
                    locationNameKey="activity.name"
                    disabled={disabled}
                  />
                </Box>
              </Grid>
            ) : (
              <Box display="flex" gap={1} alignItems="center">
                <Pin width={16} />
                <Field
                  component={GeolocationAutocomplete}
                  fullWidth
                  name="activity.name"
                  coordinatesKey="activity.locationCoordinates"
                  variant="outlined"
                  size="small"
                  data-cy="location-name-input"
                  disabled={disabled}
                />
              </Box>
            )}
          </FieldWrapper>
        </Grid>
      </Grid>
    </ShadowedFieldBlock>
  );
};

export default LocationSelectorBlock;
