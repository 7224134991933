import { gql } from '@apollo/client';
import { RAW_MATERIAL_FRAGMENT } from './components';
import {
  CULTIVATED_AREAS_FRAGMENT,
  PARTNER_BASE_FRAGMENT,
  PARTNER_REQUEST_FRAGMENT,
} from './partner';

export const COMPLIANCE = gql`
  fragment compliance on Compliance {
    id
    type
    title
    description
    imageUrl
  }
`;

export const GEOC_BATCH = gql`
  fragment geocBatchValues on GeocBatch {
    id
    createdTimestamp
    externalBatchId
    geocBatchId
    eudrDatasetId
    weight
    riskStatus
    harvestDate
  }
`;

export const EUDR_BASE_DATASET = gql`
  fragment eudrBaseDatasetValues on EUDRDataset {
    id
    createdTimestamp
    companyId
    title
    originCountry
    partnerId
    status
  }
`;

export const EUDR_DATASET = gql`
  fragment eudrDatasetValues on EUDRDataset {
    ...eudrBaseDatasetValues
    geocBatches {
      ...geocBatchValues
    }
    partner {
      ...partnerBaseValues
    }
    rawMaterial {
      id
      title
    }
    documents {
      id
      title
    }
    cultivationAreas: cultivatedAreas {
      ...cultivatedAreaValues
    }
    tableRepresentation {
      columnsNames
      rows {
        name {
          value
          error {
            documentId
            errorMessage
          }
        }
        farmId {
          value
          error {
            documentId
            errorMessage
          }
        }
        coordinates {
          value
          error {
            documentId
            errorMessage
          }
        }
        size {
          value
          error {
            documentId
            errorMessage
          }
        }
      }
    }
  }
  ${EUDR_BASE_DATASET}
  ${CULTIVATED_AREAS_FRAGMENT}
  ${PARTNER_BASE_FRAGMENT}
  ${GEOC_BATCH}
`;

export const EUDR_BASE_STATEMENT = gql`
  fragment eudrBaseStatementValues on EUDRStatement {
    id
    title
    createdTimestamp
    year
    status
    companyResponsibilityType
    companyAffectednessType
    aggregatedQuestionnaires {
      aggregatedResponseSummary {
        answered
        notAnswered
        total
      }
      aggregatedRiskSummary {
        lowRiskPercentage
        mediumRiskPercentage
        highRiskPercentage
      }
      requests {
        ...partnerRequestValues
      }
    }
  }
  ${PARTNER_REQUEST_FRAGMENT}
`;

export const EUDR_STATEMENT = gql`
  fragment eudrStatementValues on EUDRStatement {
    ...eudrBaseStatementValues
    involvedPartners {
      id
    }
    eudrDatasets {
      ...eudrDatasetValues
    }
  }
  ${EUDR_BASE_STATEMENT}
  ${EUDR_DATASET}
`;

export const GEOC_PARCEL = gql`
  fragment geocParcelValues on GeocParcel {
    id
    createdTimestamp
    geocParcelId
    geocBatchId
    harvestDate
    rawMaterialId
    cultivatedAreasId
    resultTimestamp
    deforestationRisk
    baselineDate
    landcoverPngBaselineDate
    forestCoverage
    plantationCoverage
    shrubsCoverage
    noTreesCoverage
    rgbPngBaselineDate
    rgbPngProductionEnddate
    forestChangePng
    deforestationIndex
    deforestationAreaHa
    degradationRisk
    degradationAreaHa
    degradationIndex
    countryRisk
    geocBatch {
      ...geocBatchValues
    }
    rawMaterial {
      ...rawMaterialValues
    }
    cultivatedAreas {
      ...cultivatedAreaValues
    }
  }
  ${GEOC_BATCH}
  ${RAW_MATERIAL_FRAGMENT}
  ${CULTIVATED_AREAS_FRAGMENT}
`;

export const EUDR_DATASET_MAPPING = gql`
  fragment eudrDatasetMappingValues on DatasetMapping {
    datasetId
    columnsData {
      columnName
      columnDataExample
    }
  }
`;

export const QUESTIONNAIRE_RESPONSE_OPTION_FRAGMENT = gql`
  fragment questionnaireResponseOptionValues on QuestionnaireResponsesOptions {
    id
    text
    weight
    riskLevel
    answerPercentage
  }
`;
