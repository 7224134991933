export const applicationHelperTexts = {
  node: {
    image:
      'Add one image that characterizes this location the most. This image will be displayed everywhere this location is referenced, e.g. on the map or in the navigation.',
    location:
      'Enter the physical location, so that customers can follow your product journeys on the map.',
    incomingConnections:
      'Only nodes from previous steps can be selected. This could be e.g. the farm that sends raw cotton to a spinnery. If the node already has a connection to this location it cannot be selected again.',
    outgoingConnections:
      'Only nodes from next steps can be selected. This could be e.g. a spinnery that receives raw cotton from a farm. If this location already has a connection to that node it cannot be selected again.',
    step: 'Select a step to which this location belongs. Changing the step will delete all current connections of this location.',
  },
  feedback: {
    rating:
      'This will determine if the user can give a star rating on a scale of 1-5. If you choose to disable this you can only collect text based feedback.',
  },
  tracking: {
    trackingId: 'The Tracking-ID/Measurement-ID is used for all of your products.',
  },
  api: {
    keys: 'The current key is invalidated automatically if you create a new one.',
  },
};

// Hints are slightly different from the ordinary helper texts, they are displayed with a yellow lightbuld.
export const applicationHints = {
  node: {
    mediaList:
      'Communication tip: Consumers often want to see the people behind a product and connect to their reality. Prioritise media content that cultivates an emotional connection and try to avoid images that are too vague.',
    description:
      'Communication tip: Describe what happens at this location. Consumers appreciate to learn details. Try to avoid too generic information.',
  },
  about: {
    image:
      'Compelling image and video material of the people involved in creating a product is one of the things that make consumers trust a brand the most. ',
    description:
      'A description of your business and how your products are made is important to earn trust. Consumers appreciate to know details.',
    link: 'Add a link to where users can buy your product and learn more about your product and company.',
  },
  impactFacts: {
    image:
      'To include high quality photos of the process and people involved in creating a product is one of the things that make consumers trust a brand the most.',

    description:
      'A clear description of your business values is important to earn trust. Consumers can more easily relate to a company with outspoken integrity.',
  },
};
