import { Box, ButtonGroup, styled } from '@mui/material';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import UploadField from 'components/ComplianceGuide/components/Forms/UploadField';
import RawMaterialSelection from 'components/ComponentsLibrary/Forms/RawMaterialSelection';
import useEudrStatementDatasets from 'components/ComponentsLibrary/hooks/useEudrStatementDatasets';
import { Loader, TextInputField } from 'components/Forms';
import CountrySelectField from 'components/Forms/CountrySelectField';
import { useConfig, useUploadState } from 'components/hooks';
import {
  IGeoUploadFormValue,
  IGeoUploadFormValues,
  geoUploadDatasetSchema,
  geoUploadSchema,
} from 'constants/schemas/geoUpload.schema';
import { ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Field, Form, Formik, FormikProps } from 'formik';
import { IProcessEUDRDatasetInput } from 'graphql/mutations/types/compliance-mutation.types';
import React, { FC, useMemo, useRef, useState } from 'react';
import PartnerAutocomplete from '../../../../ChainMapping/Forms/PartnerAutocomplete';
import FormikTabs from '../../FormikTabs';

const SelectContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  background: theme.custom.themeColors.grayScale[5],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  display: 'flex',
  marginBottom: theme.spacing(2),
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  '& > div': {
    flex: 3,
  },
}));

const UploadContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(4),
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  '& > div': {
    flex: 2,
  },
}));

const HintContainer = styled('div')(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  height: 'fit-content',
}));

const UploadGeoData: FC = () => {
  const formRef = useRef<FormikProps<IGeoUploadFormValues>>(null);
  const { bookGeoDataSupportUrl } = useConfig();
  const { statement, setFormData, setSelectedSubSection } = useEudrComplianceGuide();
  const {
    createEUDRDatasets,
    deleteEUDRDataset,
    startEudrDatasetProcessing,
    deleteEudrDatasetDocument,
    updateEudrDatasets,
  } = useEudrStatementDatasets();
  const { handleCleanCreatedRecords } = useUploadState();

  const [selectedDatasetIndex, setSelectedDatasetIndex] = useState<number>(0);

  // Ideally we set the initial values based on the statement, but ths is currently not since we would need to download the files again and put them back into the upload context
  // This is a huge issue since since now we need to make sure to delete datasets if something goes wrong otherwise we have orphaned datasets on the statement that you can not delete
  const initialValues: IGeoUploadFormValues = useMemo(
    () =>
      statement?.eudrDatasets?.length
        ? statement.eudrDatasets.map(dataset => ({
            ...geoUploadDatasetSchema.default(),
            datasetId: dataset.id,
            title: dataset.title,
            rawMaterialId: dataset.rawMaterial.id,
            countryCode: dataset.originCountry,
            partner: dataset.partner,
            allowMultipleFiles: false,
            files: dataset.documents.map(document => ({
              id: document.id,
              title: document.title,
              inUploadContext: false,
            })),
          }))
        : geoUploadSchema.default(),
    [statement]
  );

  if (!statement) {
    return <Loader />;
  }

  const handleDeleteDocument = async (documentId: string) => {
    const datasetId = formRef.current?.values[selectedDatasetIndex].datasetId;
    if (!datasetId) {
      console.error('Could not get current selected dataset id to delete document');
      return;
    }
    // Do not change view updating the datasets in the statement
    setFormData({ doNotChangeSection: true });
    await deleteEudrDatasetDocument(datasetId, documentId);
    // Delete the file out of the formik values
    formRef.current.setValues(values =>
      values.map(value => {
        if (value.datasetId === datasetId) {
          return {
            ...value,
            files: value.files.filter(({ id }) => id !== documentId),
          };
        }
        return value;
      })
    );
  };

  const handleSubmit = async (values: IGeoUploadFormValues) => {
    // Do not change view updating the datasets in the statement
    setFormData({ doNotChangeSection: true });

    // Create the EUDR datasets that were not created and update existing datasets

    const datasetCreationResults = await createEUDRDatasets(
      statement.id,
      values.filter(({ datasetId }) => !datasetId) // The datasets were not created yet
    );
    const datasetUpdateResults = await updateEudrDatasets(
      values.filter(({ datasetId }) => !!datasetId) as (IGeoUploadFormValue & {
        datasetId: string;
      })[] // The value were datasetId are set were already created
    );

    try {
      // Build unique array with all existing dataset ids
      const datasetIds = [
        ...datasetCreationResults.map(result => result.data?.createEUDRDataset.eudrDataset.id),
        ...datasetUpdateResults.map(result => result.data?.updateEUDRDataset.eudrDataset.id),
      ].filter(datasetId => !!datasetId) as string[];

      const inputs: IProcessEUDRDatasetInput[] = datasetIds.map(datasetId => ({
        datasetId,
        decimalSeperator: '.', // TODO: add this to frontend
        csvSeperator: ',',
      }));

      const response = await startEudrDatasetProcessing(inputs);

      if (response.data?.processEUDRDatasets.datasetMappings) {
        setFormData({ datasetMappings: response.data?.processEUDRDatasets.datasetMappings });
        setSelectedSubSection('map-columns');
      } else if (
        response.data?.processEUDRDatasets?.eudrDatasets.some(
          ({ tableRepresentation }) => !!tableRepresentation
        )
      ) {
        // Skip the mapping and process with validation
        setSelectedSubSection('validate');
      } else {
        setSelectedSubSection('confirmation');
      }
    } finally {
      formRef.current?.setSubmitting(false);
      handleCleanCreatedRecords();
    }
  };

  return (
    <Formik<IGeoUploadFormValues>
      innerRef={formRef}
      validationSchema={geoUploadSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ isValid, errors, values, isSubmitting, submitForm, setFieldValue }) => (
        <Form>
          <EudrComplianceGuideSubSection
            allowNextStepNavigation={isValid && !errors.length}
            nextStepLoading={isSubmitting}
            customNextStepButton={{ text: 'Upload & convert' }}
            onNextStepClick={submitForm}
          >
            <FormikTabs
              selectedIndex={selectedDatasetIndex}
              onChange={setSelectedDatasetIndex}
              onDeleteDataset={deleteEUDRDataset}
            />

            <Box mt={2} mb={2}>
              <ButtonGroup>
                <ThemeButton
                  className={values[selectedDatasetIndex]?.allowMultipleFiles ? '' : 'selected'}
                  onClick={() => setFieldValue(`${selectedDatasetIndex}.allowMultipleFiles`, false)}
                >
                  Multiple polygons per file
                </ThemeButton>
                <ThemeButton
                  className={values[selectedDatasetIndex]?.allowMultipleFiles ? 'selected' : ''}
                  onClick={() => setFieldValue(`${selectedDatasetIndex}.allowMultipleFiles`, true)}
                >
                  One file per polygon
                </ThemeButton>
              </ButtonGroup>
            </Box>

            {/* Dataset content */}
            <SelectContainer>
              <TextInputField
                name={`[${selectedDatasetIndex}].title`}
                isRequired
                helperText="Add a name to your geo-dataset that helps you and your colleagues remember to what origin this dataset refers to. Using specific region names could be helpful."
                label="Title"
                size="small"
                placeholder='E.g. "Origin, geo-data Ghana"'
              />
              <RawMaterialSelection
                isRequired
                multiple={false}
                name={`[${selectedDatasetIndex}].rawMaterialId`}
                size="small"
                helperText="Select the commodity that is sourced from this origin. You can only choose in this list from the EUDR-affected raw materials."
              />
              <CountrySelectField
                isRequired
                name={`[${selectedDatasetIndex}].countryCode`}
                label="Country of origin"
                helperText="Select the country where this origin geo-data is located. You can only have one country per origin geo-dataset."
              />
              <PartnerAutocomplete
                isRequired
                name={`[${selectedDatasetIndex}].partner`}
                label="Owner"
                helperText="Select an owner of this origin geo-dataset. This would optimally be a supplier that is involved in the supply chain connected to this origin. You can also select your own company."
              />
            </SelectContainer>

            <UploadContainer>
              <Field
                component={UploadField}
                name={`[${selectedDatasetIndex}].files`}
                multiple={values[selectedDatasetIndex]?.allowMultipleFiles}
                supportedFileTypes={['xlsx']}
                // supportedFileTypes={['csv', 'xls', 'xlsx', 'kml', 'json', 'geojson']} // For the now we only support xls and xlsx
                onExistingDocumentDelete={handleDeleteDocument}
              />

              <Box display="flex" flexDirection="column" gap={2}>
                <HintContainer>
                  <ThemeTypography variant="BODY_MEDIUM" color="BLUE_80">
                    Your geo-data needs to include multiple coordinates for each farm plot to form a
                    polygon per farm plot. A single coordinate in the middle of the farm plot is
                    also allowed for plots smaller than 4 hectares. You can either upload multiple
                    files for one dataset (one file per polygon) or one file for one dataset
                    containing multiple polygons.
                  </ThemeTypography>
                </HintContainer>

                <HintContainer>
                  <ThemeTypography variant="BODY_MEDIUM" color="BLUE_80">
                    If you do not have the geo-data in any of the supported file formats, we can
                    also allocate resources to help you to manually convert the data to a readable
                    format for the deforestation analysis. Set up a meeting with us to discuss this
                    further.
                  </ThemeTypography>
                  <Box mt={2} />
                  <ThemeButton
                    color="WHITE"
                    startIcon={<Icon name="calendar" />}
                    onClick={() => window.open(bookGeoDataSupportUrl, '_blank')}
                  >
                    Schedule meeting
                  </ThemeButton>
                </HintContainer>
              </Box>
            </UploadContainer>
          </EudrComplianceGuideSubSection>
        </Form>
      )}
    </Formik>
  );
};

export default UploadGeoData;
