import { DialogActions, DialogContent } from '@mui/material';
import { TextFieldLimited } from 'components/Forms';
import createRawMaterialSchema from 'constants/schemas/rawMaterials';
import { DialogDefault, FieldWrapper, ThemeButton } from 'designSystem';
import { Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import useRawMaterials from '../hooks/useRawMaterials';

const AddRawMaterialDialog: FC<IDefaultDialogProps> = ({ open, onClose }) => {
  const { createRawMaterial } = useRawMaterials();

  const handleCreateRawMaterial = (values: { title: string }) => {
    createRawMaterial({ variables: { input: { title: values.title } } });
  };

  return (
    <DialogDefault
      open={open}
      title="Add raw material"
      fullWidth
      data-cy="add-raw-material-dialog"
      onClose={onClose}
    >
      <Formik
        initialValues={{ title: '' }}
        validationSchema={createRawMaterialSchema}
        onSubmit={handleCreateRawMaterial}
      >
        {({ isValid }) => (
          <Form data-cy="create-raw-material-form">
            <DialogContent>
              <FieldWrapper label="Add the new raw material" variant="small">
                <Field
                  name="title"
                  fullWidth
                  placeholder="E.g. Tomato, Onion, Milk"
                  component={TextFieldLimited}
                  variant="outlined"
                  charLimit={50}
                  inputProps={{
                    'data-cy': 'field-component-title',
                  }}
                />
              </FieldWrapper>
            </DialogContent>
            <DialogActions>
              <ThemeButton
                color="WHITE"
                size="large"
                data-cy="add-raw-material-dialog-cancel"
                onClick={onClose}
              >
                Cancel
              </ThemeButton>
              <ThemeButton
                type="submit"
                size="large"
                disabled={!isValid}
                data-cy="add-raw-material-dialog-copy"
              >
                Add
              </ThemeButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogDefault>
  );
};

export default AddRawMaterialDialog;
