import { useQuery, useMutation } from '@apollo/client';
import { Box, Grid, styled } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap';
import EmptyGridStateIcon from 'assets/img/empty-grid-state.svg';
import DataImportOverviewCard from 'components/DataImport/DataImportOverviewCard';
import { DATA_IMPORT_CATEGORY_CONFIG } from 'components/DataImport/constants/dataImportConfig';
import { ActionContainer, PageContainer, PageSubTitle, PageTitle } from 'components/Structure';
import FlexBox from 'components/Structure/FlexBox';
import { useDialog, useMessages, usePageTitle } from 'components/hooks';
import { Tabs, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { DELETE_DATA_IMPORT } from 'graphql/mutations/imports';
import { GET_FIRST_MILE_IMPORTS } from 'graphql/queries/imports';
import React, { FC, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate, useParams } from 'react-router-dom';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { FirstMileImport, IFirstMileImportsResponse } from 'types/dataImport.types';

const DATA_IMPORT_ITEM_HEIGHT = 182;

const Container = styled('div')(() => ({
  position: 'relative',
  minHeight: 50,
}));

const DataItem = styled('div')<{ selected: booleanish }>(({ theme, selected }) => ({
  height: DATA_IMPORT_ITEM_HEIGHT,
  position: 'relative',
  padding: theme.spacing(2),
  background: '#fff',
  borderRadius: theme.spacing(1),
  overflow: 'hidden',

  border:
    selected === 'true'
      ? `2px solid ${theme.palette.secondary.main}`
      : `2px solid ${theme.palette.grey[200]}`,
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.6,
  },

  '&:hover .select-button': {
    opacity: 1,
  },
}));

const DatasetTitle = styled('p')<{ unnamed?: boolean }>(({ theme, unnamed = false }) => ({
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 700,
  color: unnamed ? theme.custom.themeColors.grayScale[60] : theme.custom.themeColors.black,
  margin: 0,
  padding: 0,
}));

const DatasetDate = styled('p')(({ theme }) => ({
  fontSize: '11px',
  lineHeight: '16px',
  fontWeight: 400,
  color: theme.custom.themeColors.grayScale[80],
  margin: 0,
  padding: 0,
}));

const DetailsCard = styled('div')(({ theme }) => ({
  height: DATA_IMPORT_ITEM_HEIGHT,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2),
  background: '#fff',
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
  border: `2px solid ${theme.palette.grey[200]}`,
  width: '20%',
  minWidth: 300,
}));

const StyledGridContainer = styled(Grid)(() => ({
  marginRight: 16,
  width: 'calc(80% - 16px)',
}));

const DataImports: FC = () => {
  usePageTitle('Data import');
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const { id: partnerId } = useParams<{ id?: string }>();

  const [importDetails, setImportDetails] = useState<FirstMileImport>();

  const { data, loading } = useQuery<IFirstMileImportsResponse>(GET_FIRST_MILE_IMPORTS, {
    variables: { partnerId },
  });

  const [deleteDataImport] = useMutation(DELETE_DATA_IMPORT, {
    onCompleted: () => {
      setSuccessMessage('Data import successfully deleted');
    },
    onError: () => {
      setErrorMessage('Failed to delete data import');
    },
    refetchQueries: [GET_FIRST_MILE_IMPORTS],
  });

  // Farm tab is the default tab
  const categoryConfig = DATA_IMPORT_CATEGORY_CONFIG[0];

  // Nothing, since only one tab exists for now
  const handleTabChange = () => undefined;

  const startEditingTable = (importId?: string) => {
    const basePath = partnerId
      ? `/partners/${partnerId}/imports/${categoryConfig.key}`
      : `/imports/${categoryConfig.key}`;
    navigate(`${basePath}${importId ? `/${importId}` : ''}`);
  };

  const handleDeleteImport = async (id: string) => {
    await deleteDataImport({ variables: { id } });
    setImportDetails(undefined);
  };

  const handleDeleteImportClick = () => {
    if (!importDetails) {
      // eslint-disable-next-line no-console
      console.log('No import details to delete');
      return;
    }
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete data import',
        itemTitle: importDetails.title || 'Unnamed',
        text: 'Are you sure you want to delete this data import? The imported data will still be available in the system.',
        cancelText: 'Cancel',
        onSubmit: () => handleDeleteImport(importDetails.id),
        onCancel: () => undefined,
      },
    });
  };

  return (
    <PageContainer>
      <PageTitle
        title="Data import"
        goBackUrl={partnerId ? '/partners' : undefined}
        goBackLabel={partnerId ? 'All partners' : undefined}
      />

      <Container>
        <Box mt={3} mb={1}>
          <Tabs
            tabs={DATA_IMPORT_CATEGORY_CONFIG.map(({ tab }) => tab)}
            selectedTab={categoryConfig.key}
            onChange={handleTabChange}
          />
        </Box>

        {!loading && data && data?.firstMileImports.edges.length > 0 ? (
          <>
            <ActionContainer>
              <ThemeButton
                size="medium"
                color="YELLOW"
                startIcon={<Plus size={20} />}
                onClick={() => startEditingTable()}
              >
                Add new data set
              </ThemeButton>
            </ActionContainer>
            <Box mt={4} display="flex" gap={1}>
              <StyledGridContainer container spacing={3}>
                {data.firstMileImports.edges.map(({ node }) => (
                  <Grid item key={node.id} xs={6} sm={4} md={3} lg={2}>
                    <DataItem
                      selected={Booleanish(importDetails?.id === node.id)}
                      onClick={() => setImportDetails(node)}
                    >
                      <FlexBox justifyContent="center" mb={2}>
                        <img width={100} src={EmptyGridStateIcon} alt="empty" />
                      </FlexBox>
                      {node.title ? (
                        <DatasetTitle>{node.title}</DatasetTitle>
                      ) : (
                        <DatasetTitle unnamed>Unnamed</DatasetTitle>
                      )}
                      <DatasetDate>
                        <Moment format="L">{node.createdTimestamp}</Moment>
                      </DatasetDate>
                    </DataItem>
                  </Grid>
                ))}
              </StyledGridContainer>

              {importDetails !== undefined && (
                <DetailsCard>
                  <PageSubTitle title="Details" />
                  <Box mb={1} />
                  <ThemeTypography variant="BODY_MEDIUM_BOLD">
                    {importDetails.title || 'Unnamed'}
                  </ThemeTypography>
                  <FlexBox mb={2}>
                    <ThemeTypography variant="BODY_MEDIUM">
                      Created <Moment format="LL">{importDetails.createdTimestamp}</Moment>
                    </ThemeTypography>
                  </FlexBox>
                  <FlexBox>
                    <ThemeButton
                      color="BLUE_ICE"
                      startIcon={<Icon name="show" />}
                      onClick={() => startEditingTable(importDetails?.id)}
                    >
                      View
                    </ThemeButton>
                    <Box ml={2} />
                    <ThemeButton
                      color="BLUE_ICE"
                      startIcon={<Icon name="delete" />}
                      onClick={handleDeleteImportClick}
                    >
                      Delete
                    </ThemeButton>
                  </FlexBox>
                </DetailsCard>
              )}
            </Box>
          </>
        ) : (
          <DataImportOverviewCard
            title={`Add your ${categoryConfig.dataLabel} data to enable tracing of impact along your supply chains`}
            onAddTableClick={startEditingTable}
          />
        )}
      </Container>
    </PageContainer>
  );
};

export default DataImports;
