import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { MediaField, TextFieldLimited } from 'components/Forms';
import { FieldWrapper } from 'designSystem';
import { useContentBlock } from 'components/Product/MultiStep/hooks';
import { applicationHints } from 'config/applicationHints';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import FormContainer from './FormContainer';
import Header from './Header';
import ImpactFactItems from './ImpactFactItems';
import { Container } from './styles';
import HTMLEditorField from 'designSystem/Formik/HTMLEditorField/HTMLEditorField';

const ImpactFacts = ({ menu, previewIsActive }) => {
  const [{ data, enabled, id }, schema] = useContentBlock(menu.id);
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const showTextOnTheSide = !previewIsActive && largeScreen;

  return (
    <Container>
      <FormContainer
        initialValues={{
          enabled,
          ...data,
        }}
        schema={schema}
      >
        {({ values, setValues }) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Header
                title={menu.title}
                showEnabled
                contentBlockId={id}
                setContent={setValues}
                contentType={menu.id}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection={showTextOnTheSide ? `row` : 'column'} gap={3}>
                <FieldWrapper
                  display="flex"
                  flexDirection="column"
                  label="Image"
                  tooltip={{ helperText: applicationHints.impactFacts.image, variant: 'HINT' }}
                >
                  <Field
                    component={MediaField}
                    fullWidth
                    name="image"
                    styles={{
                      width: 280,
                      height: 280,

                      [theme.breakpoints.down('md')]: {
                        width: 200,
                        height: 200,
                      },
                    }}
                    data-cy="impact-form-image-field"
                  />
                </FieldWrapper>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FieldWrapper label="Headline">
                      <Field
                        component={TextFieldLimited}
                        fullWidth
                        name="headline"
                        variant="outlined"
                        rows={4}
                        charLimit={50}
                        placeholder="Beyond sustainability"
                        inputProps={{
                          'data-cy': 'impact-form-title-field',
                        }}
                      />
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper
                      label="Description"
                      tooltip={{
                        variant: 'HINT',
                        helperText: applicationHints.impactFacts.description,
                      }}
                    >
                      <Field
                        component={HTMLEditorField}
                        name="description"
                        charLimit={650}
                        placeholder="Describe how you are achieving this impact..."
                        minHeight={100}
                        data-cy="impact-form-description-field"
                      />
                    </FieldWrapper>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <ImpactFactItems items={values.impactFacts} />
          </Grid>
        )}
      </FormContainer>
    </Container>
  );
};

ImpactFacts.propTypes = {
  menu: PropTypes.object.isRequired,
  previewIsActive: PropTypes.bool,
};

export default ImpactFacts;
