import React, { FC } from 'react';
import useActivePartner from 'components/Partners/hooks/useActivePartner';

/**
 * Returns the title of the partner, that is fetched from the cache only
 */
const PartnerName: FC = () => {
  const { partner } = useActivePartner();

  return <span>{partner?.title || ''}</span>;
};

export default PartnerName;
