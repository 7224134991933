import * as Yup from 'yup';
import DocumentExpiryDateValidity from 'components/DocumentLibrary/constants/dateValidity';
import { DocumentCategories } from 'types/enums';

const document = {
  title: Yup.string()
    .default('')
    .test('test_title_empty', '', function (value) {
      // check if title is empty without extension name
      if (value.split('.').slice(0, -1).join('.').length === 0) {
        return this.createError({ message: 'Please enter a document title' });
      }
      return true;
    }),
  category: Yup.string().default('').required('Please select a document category'),
  certificateCatalogItemId: Yup.string()
    .default('')
    .nullable()
    .when('category', {
      is: DocumentCategories['CERTIFICATE'],
      then: Yup.string().required('Please add a certificate'),
    }),
  issuedDate: Yup.date()
    .default(null)
    .nullable()
    .when('category', {
      is: category => category && DocumentExpiryDateValidity[category].requireDate,
      then: Yup.date().required('Issued date is a required field'),
    }),
  expiryDate: Yup.date()
    .default(null)
    .nullable()
    .test('Check if date in past', 'Expiry date can not be in the past', value => {
      if (!value) return true;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return value >= today;
    })
    .min(Yup.ref('issuedDate'), 'Expiry date cannot be before issued date')
    .when('category', {
      is: category => category && DocumentExpiryDateValidity[category].requireDate,
      then: Yup.date().required('Issued date is a required field'),
    }),
};

export const documentSchema = Yup.object().shape(document);

export const requestDocumentSchema = Yup.object().shape({
  ...document,
  visibility: Yup.string().default('PUBLIC').required('Please select a visibility setting'),
});
