import { Box, styled } from '@mui/material';
import ComponentChainIllustration from 'assets/img/components/component-chain-illustration.png';
import { FlexBox } from 'components/Structure';
import { ThemeButton, ThemeTypography } from 'designSystem';
import React, { FC } from 'react';

interface ICreateChainPlaceholderProps {
  startFromScratchButtonLabel: string;
  isCopyButtonVisible?: boolean;
  onCopyChainClick?: () => void;
  onStartFromSketchClick: () => void;
}

const Container = styled('div')(({ theme }) => ({
  height: '100%',
  borderRadius: 6,
  border: `1px solid ${theme.custom.colors.lightBorderColor}`,
  background: theme.custom.themeColors.white,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const CreateChainPlaceholder: FC<ICreateChainPlaceholderProps> = ({
  startFromScratchButtonLabel,
  isCopyButtonVisible,
  onCopyChainClick,
  onStartFromSketchClick,
}) => {
  return (
    <Container>
      <ThemeTypography variant="TITLE_SMALL" color="BLUE_80">
        How would you like to start mapping this chain?
      </ThemeTypography>
      <Box mt={4} />
      <img src={ComponentChainIllustration} alt="Chain illustration" />
      <FlexBox mt={4}>
        <ThemeButton size="large" color="BLUE_ICE" onClick={onStartFromSketchClick}>
          {startFromScratchButtonLabel}
        </ThemeButton>
        <Box mr={2} />

        {isCopyButtonVisible && (
          <ThemeButton size="large" color="YELLOW" onClick={onCopyChainClick}>
            Copy chain
          </ThemeButton>
        )}
      </FlexBox>
    </Container>
  );
};

export default CreateChainPlaceholder;
