/**
 * By DEFAULT everything is set to true except editing subChain is not allowed
 */
export type ViewModeType = 'default' | 'edit-activities' | 'view' | 'partner-default';

/**
 * canImportSubChains -> Edit, delete and reorder subChains in the chain
 * canEditSubChains -> Import subChain into the chain
 * canEditSubChainActivities -> Edit activities part of a subChain
 * canEditSteps -> Edit chain steps except the first one which is always prohibited
 * canAddSteps -> Add chain steps in between and to the end
 * canEditActivities -> Edit chain activities that are not part of a subChain
 * canAddActivities -> Add new or existing activities to the steps
 * canSeePartner -> See partner details
 */
export interface ChainMappingConfig {
  canImportSubChains: boolean;
  canEditSubChains: boolean;
  canEditSubChainActivities: boolean;
  canEditSteps: boolean;
  canAddSteps: boolean;
  canEditActivities: boolean;
  canAddActivities: boolean;
  canSeePartner: boolean;
}

export const VIEW_MODE_CONFIG: Record<ViewModeType, ChainMappingConfig> = {
  default: {
    canImportSubChains: true,
    canEditSubChains: true,
    canEditSubChainActivities: false,
    canEditSteps: true,
    canAddSteps: true,
    canEditActivities: true,
    canAddActivities: true,
    canSeePartner: true,
  },
  'partner-default': {
    canImportSubChains: true,
    canEditSubChains: true,
    canEditSubChainActivities: false,
    canEditSteps: true,
    canAddSteps: true,
    canEditActivities: true,
    canAddActivities: true,
    canSeePartner: false,
  },
  'edit-activities': {
    canImportSubChains: false,
    canEditSubChains: false,
    canEditSubChainActivities: true,
    canEditSteps: false,
    canAddSteps: false,
    canEditActivities: true,
    canAddActivities: false,
    canSeePartner: false,
  },
  view: {
    canImportSubChains: false,
    canEditSubChains: false,
    canEditSubChainActivities: false,
    canEditSteps: false,
    canAddSteps: false,
    canEditActivities: false,
    canAddActivities: false,
    canSeePartner: true,
  },
};

export type ChainMappingEvent =
  | { type: 'ADD_STEPS'; targetIndex: number }
  | { type: 'ADD_ACTIVITY'; stepId: string }
  | {
      type: 'EDIT_ACTIVITY' | 'EDIT_STEP' | 'REMOVE_SUB_CHAIN' | 'EDIT_SUB_CHAIN';
      elementId: string;
    }
  | {
      type: 'IMPORT';
    }
  | {
      type: 'REORDER';
      subChainOrder: string[];
    }
  | {
      type: 'ADD_SUB_CHAIN_FROM_ACTIVITY';
      stepActivityIds: string[];
      subChainIds?: string[];
    };
