import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { Box, Tooltip, styled } from '@mui/material';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { useDialog, useMessages } from 'components/hooks';
import { OTHER_CERTIFICATE_ID } from 'constants/documents';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { DOCUMENT_FRAGMENT } from 'graphql/fragments';
import { UPDATE_DOCUMENT } from 'graphql/mutations';
import { GET_CONNECTED_COMPANIES } from 'graphql/queries';
import PropTypes from 'prop-types';
import React from 'react';
import { DocumentCategories } from 'types/enums';
import EditForm from './EditForm';

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: '2px solid',
  borderColor: theme.palette.grey[200],
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Footer = styled(({ renderedOnDialog, ...props }) => <div {...props} />)(
  ({ theme, renderedOnDialog }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    bottom: 0,
    left: 0,
    position: renderedOnDialog ? 'absolute' : 'static',
    padding: renderedOnDialog ? theme.spacing(3, 4) : theme.spacing(2, 0, 0),
  })
);

const EditContainer = ({
  onClose,
  documentId,
  onDelete,
  renderedOnDialog,
  isRequestDocument = false,
}) => {
  const { openDialog } = useDialog();
  const client = useApolloClient();
  const { expiryDate, issuedDate, title, category, certificateCatalogItem, visibility } =
    client.readFragment({
      fragment: DOCUMENT_FRAGMENT,
      id: `Document:${documentId}`,
    });

  // Documents that belong to the category certificate and have the certificate type other do not have a certificateCatalogItem in the backend.
  // So that the certificate autocomplete is not empty, a certificate id is assigned here.
  const certificateCatalogItemId =
    !certificateCatalogItem && renderedOnDialog && category === 'CERTIFICATE'
      ? OTHER_CERTIFICATE_ID
      : certificateCatalogItem?.id;

  const currentCategory = DocumentCategories[category];
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    onCompleted: ({ updateDocument: { document } }) => {
      // open the request certification dialog if document is certification but has no id
      if (document.category === 'CERTIFICATE' && !document.certificateCatalogItem?.id) {
        openDialog({ type: 'REQUEST_CERTIFICATION', props: { documentTitle: title } });
      }
      setSuccessMessage('Document updated successfully');
      onClose();
    },
    onError: error => {
      if (error.message === 'DOCUMENT_UPDATE_FORBIDDEN_IF_USED') {
        setErrorMessage('You cannot update this document because it is already used in a request.');
      } else {
        setErrorMessage('There was an error updating your Document');
      }
    },
  });
  const [getConnectedCompanies] = useLazyQuery(GET_CONNECTED_COMPANIES);

  const handleSave = async values => {
    const selectedCategory = Object.keys(DocumentCategories).find(
      key => DocumentCategories[key] === values.category
    );

    // later on we will offer the possibility to select individual companies with which to share the documents.
    // For now all connected companies will be automatically selected
    let connectedCompanies = [];
    if (values.visibility === 'SELECTED') {
      const { data: { connectedCompanies: { edges = [] } = {} } = {} } =
        await getConnectedCompanies();
      connectedCompanies = edges.map(({ node }) => node.id);
    }

    const fileName = values.title.indexOf('.pdf') === -1 ? values.title + '.pdf' : values.title;

    return await updateDocument({
      variables: {
        id: documentId,
        input: {
          title: fileName,
          fileName,
          expiryDate: values.expiryDate,
          issuedDate: values.issuedDate,
          category: selectedCategory,
          visibility: values.visibility,
          // to distinguish between other certificate and no certificate, we use the the OTHER_CERTIFICATE_ID in the frontend.
          // In the backend, both are null, so we have to filter the id out here.
          certificateCatalogItemId:
            values.certificateCatalogItemId === OTHER_CERTIFICATE_ID
              ? null
              : values.certificateCatalogItemId,
          sharedWithCompanyIds: connectedCompanies,
        },
      },
    });
  };

  return (
    <Container>
      <EditForm
        title={title}
        expiryDate={expiryDate}
        issuedDate={issuedDate}
        category={currentCategory}
        certificateCatalogItemId={certificateCatalogItemId}
        onSubmit={handleSave}
        visibility={visibility}
        isRequestDocument={isRequestDocument}
      >
        {({ submitForm, isSubmitting }) => (
          <Footer renderedOnDialog={renderedOnDialog}>
            <ThemeButton
              color="BLUE_ICE"
              size="large"
              startIcon={<Trash size={14} />}
              data-cy="delete-document-btn"
              onClick={onDelete}
            >
              Delete
            </ThemeButton>
            <Box display="flex">
              <Box mr={1}>
                <ThemeButton color="BLUE_ICE" size="large" onClick={onClose}>
                  Cancel
                </ThemeButton>
              </Box>
              <Tooltip title="Make sure that all required fields are set">
                <div>
                  <ThemeButton
                    onClick={submitForm}
                    loading={isSubmitting}
                    size="large"
                    color="YELLOW"
                    data-cy="save-edited-document-btn"
                  >
                    Apply changes
                  </ThemeButton>
                </div>
              </Tooltip>
            </Box>
          </Footer>
        )}
      </EditForm>
    </Container>
  );
};

EditContainer.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  documentId: PropTypes.string.isRequired,
  renderedOnDialog: PropTypes.bool,
  isRequestDocument: PropTypes.bool,
};

export default EditContainer;
