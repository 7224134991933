import { styled } from '@mui/material/styles';
import { PropsWithChildren, Ref } from 'react';

interface IAbsoluteElementProps extends PropsWithChildren {
  ref?: Ref<HTMLDivElement>;
  x?: number;
  y?: number;
  width?: number | string;
  height?: number | string;
  zIndex?: number;
  onClick?: () => void;
}

const AbsoluteElement = styled('div')<IAbsoluteElementProps>(
  ({ x = 0, y = 0, width, height, zIndex }) => ({
    position: 'absolute',
    left: x,
    top: y,
    width,
    height,
    zIndex,
  })
);

export default AbsoluteElement;
