import { Box, IconButton, Paper, styled, Tooltip, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { UserSettings } from '@styled-icons/remix-line/UserSettings';
import { Trash } from '@styled-icons/bootstrap';
import { Edit } from '@styled-icons/boxicons-solid';
import { IActivity } from 'types/partner.types';

const ActivityWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ActivityRow = styled(Paper)(({ theme }) => ({
  background: '#fff',
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ActivityTitle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  marginLeft: theme.spacing(1),
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

interface Props {
  locationActivities: IActivity[];
  editableActivity?: IActivity;
  setEditableActivity: (activity: IActivity | undefined) => void;
  onRemovePartnerActivityFromLocation: (activityId: string) => void;
  children?: React.ReactNode;
}

export const ActivitiesList: React.FC<Props> = ({
  locationActivities,
  editableActivity,
  setEditableActivity,
  onRemovePartnerActivityFromLocation,
  children,
}) => {
  return (
    <ActivityWrapper>
      {locationActivities.map(locationActivity => {
        if (locationActivity.id === editableActivity?.id) {
          return children;
        }

        return (
          <ActivityRow key={`activity-row-${locationActivity.id}`} variant="outlined">
            <Box display="flex" alignItems="center">
              <UserSettings size={17} />
              <ActivityTitle>{locationActivity.title}</ActivityTitle>
            </Box>

            <Box>
              <Tooltip title="Edit">
                <StyledIconButton
                  onClick={() => {
                    setEditableActivity(locationActivity);
                  }}
                >
                  <Edit size="17" />
                </StyledIconButton>
              </Tooltip>

              <Tooltip title="Delete">
                <StyledIconButton
                  onClick={() => {
                    onRemovePartnerActivityFromLocation(locationActivity.id);
                  }}
                >
                  <Trash size="16" />
                </StyledIconButton>
              </Tooltip>
            </Box>
          </ActivityRow>
        );
      })}

      {!editableActivity && <Fragment>{children}</Fragment>}
    </ActivityWrapper>
  );
};

export default ActivitiesList;
