import { gql } from '@apollo/client';
import { COMPONENT_FRAGMENT } from 'graphql/fragments';
import { CHAIN_BASE_FRAGMENT } from 'graphql/fragments/chains';
import { PRODUCT_CHAIN_FRAGMENT, RAW_MATERIAL_FRAGMENT } from 'graphql/fragments/components';

export const GET_COMPONENT = gql`
  query GetComponent($id: UUID!, $lang: Language) {
    component(id: $id, lang: $lang) {
      ...componentValues
      componentChains {
        ...chainBaseValues
      }
      supplyChains {
        ...chainBaseValues
      }
    }
  }
  ${COMPONENT_FRAGMENT}
  ${CHAIN_BASE_FRAGMENT}
  ${PRODUCT_CHAIN_FRAGMENT}
`;

export const GET_COMPONENTS_LIST = gql`
  query GetComponentsList($partnerId: UUID) {
    components(partnerId: $partnerId) {
      edges {
        node {
          ...componentValues
        }
      }
    }
  }
  ${COMPONENT_FRAGMENT}
`;

export const GET_RAW_MATERIALS = gql`
  query GetRawMaterials {
    rawMaterials {
      edges {
        node {
          ...rawMaterialValues
        }
      }
    }
  }
  ${RAW_MATERIAL_FRAGMENT}
`;
