import { Box, IconButton, TableCell, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trash } from '@styled-icons/bootstrap';
import { Edit } from '@styled-icons/boxicons-solid';
import { ComponentPlaceholderImage, PartnerPlaceholderImage } from 'assets/img';
import { FlexBox } from 'components/Structure';
import { StyledTableRow } from 'components/TableBase';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, MouseEvent } from 'react';
import Moment from 'react-moment';
import { Booleanish } from 'types/booleanish.types';
import { IComponentItem } from 'types/component.types';
import { COMPONENT_ITEM_IDS } from './constants/headerColumns';
import useComponentItemActions from './hooks/useComponentItemActions';
import { useDialog } from 'components/hooks';
import Image from 'designSystem/DataDisplay/Image/Image';

interface IComponentItemTableRowProps {
  componentItem: IComponentItem;
  selected?: IComponentItem;
  columns: COMPONENT_ITEM_IDS[];
  hidePartner?: boolean;
  onRowClick: (componentItem: IComponentItem) => void;
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const IconContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: 170,
}));

const Date = styled(Typography)(() => ({
  width: 140,
}));

const ComponentItemTableRow: FC<IComponentItemTableRowProps> = ({
  componentItem,
  columns,
  selected,
  hidePartner,
  onRowClick,
}) => {
  const { id, title, image, modifiedTimestamp, partner, inUse, articleNumber, rawMaterials } =
    componentItem;
  const isSelected = selected && selected.id === componentItem.id;

  const { openDialog, closeDialog } = useDialog();
  const { deleteComponent } = useComponentItemActions();

  const handleRowClick = (event: MouseEvent) => {
    event.stopPropagation();
    onRowClick(componentItem);
  };

  const handleDeleteClick = (event: MouseEvent) => {
    event.stopPropagation();
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete component',
        displayCloseButton: true,
        text: 'Are you sure you want to delete this component? All data will be lost and you will not be able to recover this item.',
        submitText: 'Delete',
        onSubmit: () => {
          deleteComponent({ variables: { id } });
        },
        onCancel: () => closeDialog('ALERT'),
      },
    });
  };

  const handleEditClick = (event: MouseEvent) => {
    event.stopPropagation();
    openDialog({
      type: 'CREATE_EDIT_COMPONENT',
      props: {
        title: 'Edit component',
        componentItem: componentItem,
        hidePartner,
      },
    });
  };

  return (
    <StyledTableRow
      data-cy="components-table-item"
      data-cy-id={id}
      hover
      key={id}
      is-selected={Booleanish(isSelected)}
      onClick={handleRowClick}
    >
      {columns.includes(COMPONENT_ITEM_IDS.TITLE) && (
        <TableCell scope="row" width={500}>
          <FlexBox>
            <Image
              image={image}
              width={42}
              height={42}
              backupImageUrl={ComponentPlaceholderImage}
            />
            <Box ml={2}>
              <FlexBox justifyContent="flex-start">
                <ThemeTypography variant="ITEM_TITLE">{title}</ThemeTypography>
                {articleNumber && (
                  <Box ml={0.8}>
                    <ThemeTypography
                      variant="BODY_MEDIUM"
                      autoOverflow
                      maxWidth={150}
                      color="GRAY_80"
                    >
                      | {articleNumber}
                    </ThemeTypography>
                  </Box>
                )}
              </FlexBox>
              <FlexBox>
                <Icon name="raw-material" mr={0.5} color="gray-80" />
                <ThemeTypography
                  variant="BODY_MEDIUM_BOLD"
                  color="GRAY_80"
                  maxWidth={200}
                  autoOverflow
                >
                  {rawMaterials?.reduce(
                    (prev, rawMaterial) =>
                      prev ? `${prev}, ${rawMaterial.title}` : rawMaterial.title,
                    ''
                  )}
                </ThemeTypography>
              </FlexBox>
            </Box>
          </FlexBox>
        </TableCell>
      )}

      {columns.includes(COMPONENT_ITEM_IDS.PARTNER) && (
        <TableCell scope="row" width={500}>
          <Box display="flex" alignItems="center">
            <Image
              image={partner?.logo}
              width={42}
              height={42}
              backupImageUrl={PartnerPlaceholderImage}
            />
            <Box ml={2}>
              <ThemeTypography variant="ITEM_SUBTITLE">
                {partner?.title || 'Unknown'}
              </ThemeTypography>
            </Box>
          </Box>
        </TableCell>
      )}

      {columns.includes(COMPONENT_ITEM_IDS.LAST_EDITED) && (
        <TableCell scope="row">
          <Date variant="subtitle2">
            <Moment format="LL">{modifiedTimestamp}</Moment>
          </Date>
        </TableCell>
      )}
      {columns.includes(COMPONENT_ITEM_IDS.ACTIONS) && (
        <TableCell width={170} scope="row" align="left">
          <IconContainer>
            <Tooltip title="Edit component">
              <StyledIconButton data-cy="edit-component-btn" onClick={handleEditClick}>
                <Edit size="16" />
              </StyledIconButton>
            </Tooltip>
            <Box mr={1.5} />
            <Tooltip title={inUse ? 'Component is used in a supply chain' : 'Delete component'}>
              <Box component="span">
                <StyledIconButton
                  data-cy="delete-component-btn"
                  onClick={handleDeleteClick}
                  disabled={inUse}
                >
                  <Trash size="16" />
                </StyledIconButton>
              </Box>
            </Tooltip>
          </IconContainer>
        </TableCell>
      )}
    </StyledTableRow>
  );
};

export default ComponentItemTableRow;
