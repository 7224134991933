import { isNumber } from '@turf/turf';
import { ITableSettings, ValidationResult } from 'types/dataImport.types';
import { IExcelTableContext, ValidatorFunction } from '../DataImportTable/excelTable.types';
import { CROPS_SEPARATOR, VALIDATION_MESSAGE_EMPTY } from '../constants/dataImport.constants';
import { isValueEmpty } from './dataImport.utils';
import { IRawMaterial } from 'types/component.types';

export const areCoordinatesValid = (coordinates: string, longFirst: boolean = false): boolean => {
  const regex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
  const match = coordinates.match(regex);

  if (!match) {
    return false;
  }

  const latitude = parseFloat(match[longFirst ? 3 : 1]);
  const longitude = parseFloat(match[longFirst ? 1 : 3]);

  const isLatitudeValid = latitude >= -90 && latitude <= 90;
  const isLongitudeValid = longitude >= -180 && longitude <= 180;

  return isLatitudeValid && isLongitudeValid;
};

export const noValidation: ValidatorFunction = () => ({
  isValid: true,
  validationMessage: undefined,
});

export const validateNotEmpty: ValidatorFunction = value => {
  const valid = !isValueEmpty(value);
  return {
    isValid: valid,
    validationMessage: valid ? undefined : VALIDATION_MESSAGE_EMPTY,
  };
};

export const validateCoordinates: ValidatorFunction = (value, context) => {
  if (!value || isValueEmpty(value)) {
    return {
      isValid: false,
      validationMessage: VALIDATION_MESSAGE_EMPTY,
    };
  }

  const longFirst = context?.settings?.coordinatesVersion === 'lnglat';

  const valid = areCoordinatesValid(value, longFirst);

  return {
    isValid: valid,
    validationMessage: valid
      ? undefined
      : 'Location needs to be in format of coordinates (e.g. "52.324, 13.465").',
  };
};

export const createPositiveNumberValidator: (
  validationMessage: string,
  required?: boolean
) => ValidatorFunction =
  (validationMessage, required = false) =>
  value => {
    if (required && isValueEmpty(value))
      return {
        isValid: false,
        validationMessage: VALIDATION_MESSAGE_EMPTY,
      };

    if (value === undefined)
      return {
        isValid: undefined,
      };

    const valid = value !== undefined && isNumber(value) && parseFloat(value) > 0;
    return {
      value: valid ? parseFloat(value) : value,
      isValid: valid,
      validationMessage: valid ? undefined : validationMessage,
    };
  };

export const validateCrop = (
  value?: string,
  context?: IExcelTableContext & { settings: ITableSettings; rawMaterials: IRawMaterial[] }
): ValidationResult => {
  if (!value || isValueEmpty(value)) {
    return {
      isValid: false,
      validationMessage: VALIDATION_MESSAGE_EMPTY,
    };
  }

  const materialTitles = context?.rawMaterials.map(material => material.title.toLowerCase()) || [];

  const userMaterials = value.split(CROPS_SEPARATOR).map(v => v.trim());
  const invalidMaterials = userMaterials.filter(
    userMaterial => !materialTitles.includes(userMaterial.toLowerCase())
  );

  if (userMaterials.length === 1 && invalidMaterials.length === 1) {
    return {
      isValid: false,
      validationMessage:
        'This raw material type is not yet part of our list. Select an existing type from list, or create new.',
    };
  }

  if (invalidMaterials.length > 0) {
    const invalidMaterialsString = invalidMaterials.map(m => '"' + m + '"').join(' & ');

    return {
      isValid: false,
      validationMessage: `${invalidMaterialsString} is not yet part of our raw material list. Select an existing type from list, or create new.`,
    };
  }

  return {
    isValid: true,
  };
};
