import { WelcomeSlidesProvider } from 'components/WelcomeSlides/Contexts';
import { useUserData } from 'components/hooks';
import { CompanyPlanProvider, useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import CustomProviders from 'contexts/CustomProviders';
import { DialogProvider } from 'contexts/DialogContext';
import FirebaseContext from 'contexts/FirebaseContext';
import { FrontendVersionProvider } from 'contexts/FrontendVersionContext';
import { OnboardingTourProvider } from 'contexts/OnboardingTourContext';
import { TermsProvider } from 'contexts/TermsContext';
import { UploadProvider } from 'contexts/UploadContext';
import React, { FC, memo, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import ClientUIRouter from 'routes/ClientUIRoutes';
import SlimPlanRoutes from 'routes/SlimPlanRoutes';
import PartnerUIRouter from './PartnerUIRoutes';

/** These providers can only be created and used when the user is authenticated */
const AUTH_ROUTER_PROVIDERS = [
  UploadProvider as FC, // Needs to be on top of the dialog provider
  FrontendVersionProvider,
  DialogProvider,
  TermsProvider,
  CompanyPlanProvider,
  WelcomeSlidesProvider,
  OnboardingTourProvider,
];

const AuthenticatedRoute: FC = () => {
  const { plan, isPartner } = useCompanyPlanContext();
  const { pathname, search } = useLocation();
  const { user, company } = useUserData();

  // check if user data in cache is sufficient
  const localStateSufficient = user && company;

  if (!localStateSufficient) {
    return (
      <Navigate
        to="/login"
        state={{
          redirect: `${pathname}${search}`,
        }}
      />
    );
  }

  if (plan !== 'FULL_PLAN') {
    return <SlimPlanRoutes />;
  }

  return <>{isPartner ? <PartnerUIRouter /> : <ClientUIRouter />}</>;
};

// Wrap the auth providers around the AuthenticatedRoute
const AuthenticatedRouter: FC = () => {
  const { authenticated } = useContext(FirebaseContext);
  // It would be better to use no globals here and the useLocation hook from react-router-dom
  // but the location hook takes ways too long to update and introduces a flickering in the drawer
  // eslint-disable-next-line no-restricted-globals
  const { pathname, search } = location;

  if (!authenticated) {
    return (
      <Navigate
        to="/login"
        state={{
          redirect: `${pathname}${search}`,
        }}
      />
    );
  }

  return (
    <CustomProviders providers={AUTH_ROUTER_PROVIDERS}>
      <AuthenticatedRoute />
    </CustomProviders>
  );
};

export default memo(AuthenticatedRouter);
