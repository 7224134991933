import { Box, DialogTitle, MenuItem, Select, styled, SelectChangeEvent } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap';
import CreateEditComponentForm, {
  FormValues,
} from 'components/ComponentsLibrary/Forms/CreateEditComponentForm';
import { LanguageSelectorItem } from 'components/Forms';
import { StyledDialogContent, TopBackground } from 'components/Product/Create/styles';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import { PageSubTitle } from 'components/Structure';
import { useConfig, useDialog } from 'components/hooks';
import { InfoTooltip } from 'designSystem';
import { StyledDialog } from 'designSystem/Overlays/Dialog/DialogDefault/DialogDefault';
import React, { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IComponentItem } from 'types/component.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { AvailableLanguages, AvailableSizes } from 'types/enums';
import useComponentItemActions from '../hooks/useComponentItemActions';
import { Edit } from '@styled-icons/boxicons-solid';
import { Partner } from 'types/partner.types';
import { FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
export interface ICreateComponentDialogProps extends IDefaultDialogProps {
  title: string;
  /* Pass this if the partner select should already be prefilled
   * Warning: This overrides the partner on the componentItem and disabled the field (so the partner can not be changed)
   */
  partner?: Partner;
  hidePartner?: boolean;
  componentItem?: Omit<IComponentItem, 'inUse' | 'lastEditedTimestamp'>;
  infoText?: string;
  /*
   * Callback will be called after the creation returned result (success or error)
   * By default the user will be redirected to the component overview
   */
  onComponentCreated?: (componentId: string) => void;
}

const DialogTitleStyled = styled(DialogTitle)(() => ({
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: 40,
  paddingBottom: 0,
}));

const StyledSelect = styled(Select)(() => ({
  '& .MuiSelect-select ': {
    padding: '6px 12px !important',
  },

  '& .MuiButtonBase-root': {
    padding: 0,
    paddingRight: 20,
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  '& .MuiButtonBase-root': {
    padding: 0,
    paddingRight: 20,
  },
}));

const CreateEditComponentDialog: FC<ICreateComponentDialogProps> = ({
  open,
  title,
  partner,
  hidePartner,
  componentItem,
  infoText,
  onClose,
  onComponentCreated,
}) => {
  const { openDialog } = useDialog();
  const [selectedLanguage, setSelectedLanguage] = useState<AvailableLanguages>(
    AvailableLanguages.ENGLISH
  );
  const formRef = useRef<FormikProps<FormValues>>(null);
  const { closeDialog } = useDialog();
  const navigate = useNavigate();
  const { createComponent, updateComponent } = useComponentItemActions();
  const { productLanguages } = useConfig();

  /*
   * Either create or update the component
   * Create callback will be called after the creation returned result (success or error)
   * By default the user will be redirected to the component overview which can be overwritten by the callback
   */
  const handleSubmit = async (values: FormValues) => {
    if (componentItem) {
      const variables = {
        id: componentItem.id,
        input: {
          ...omit(values, 'partner'),
          partnerId: hidePartner ? partner?.id : values.partner?.id || null,
        },
        inputLanguage: selectedLanguage,
      };
      updateComponent({ variables });
    } else {
      const variables = {
        input: {
          ...omit(values, 'partner'),
          partnerId: hidePartner ? partner?.id : values.partner?.id || null,
        },
        lang: selectedLanguage,
      };
      const { data } = await createComponent({ variables });
      const componentId = data?.createComponent?.component?.id;
      closeDialog('CREATE_EDIT_COMPONENT');
      if (componentId) {
        onComponentCreated
          ? onComponentCreated(componentId)
          : navigate(`/components/${componentId}`);
      }
    }
  };

  // If there are unsaved changes, ask the user if they want to leave the page
  const handleClose = () => {
    if (formRef.current && !isEqual(formRef.current.values, formRef.current.initialValues)) {
      openDialog({
        type: 'ALERT',
        props: {
          title: 'Unsaved changes',
          text: 'Are you sure you want to close this window? All unsaved changes will be lost and you will not be able to undo this action.',
          submitText: 'Close',
          displayCloseButton: true,
          onSubmit: onClose,
          onCancel: () => undefined,
        },
      });
    } else {
      onClose?.();
    }
  };

  const handleLanguageChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedLanguage(event.target.value as AvailableLanguages);
  };

  return (
    <StyledDialog
      open={!!open}
      onClose={handleClose}
      maxWidth="md"
      data-cy="create-edit-component-dialog"
    >
      <DialogTitleStyled>
        <Box display="flex" alignItems="center">
          <Box mr={1}>{componentItem ? <Edit size={16} /> : <Plus size={16} />}</Box>
          <PageSubTitle title={title} />
        </Box>
        <Box display="flex" alignItems="center">
          <InfoTooltip
            size={AvailableSizes.SMALL}
            text="You can add and edit the component name in different languages. This can also be managed later on in the product page communication."
          />
          <StyledSelect
            label="Component name"
            variant="outlined"
            value={selectedLanguage}
            onChange={handleLanguageChange}
            data-cy="component-language-select"
          >
            {productLanguages.map(
              ({ title, key, flag }: { title: string; key: string; flag: string }) => (
                <StyledMenuItem
                  key={`product-lang-${key}`}
                  value={key}
                  data-cy="component-language-select-option"
                >
                  <LanguageSelectorItem
                    value={key}
                    flag={flag}
                    title={title}
                    hoverBackground={false}
                    noPadding={undefined}
                  />
                </StyledMenuItem>
              )
            )}
          </StyledSelect>
        </Box>
      </DialogTitleStyled>
      <TopBackground />
      <StyledDialogContent>
        <Box display="flex" alignItems="center" mb={2}>
          {(!hidePartner || !!infoText) && (
            <InlineHelperText
              variant="INFO"
              helperText={
                !!infoText
                  ? infoText
                  : 'Components are ingredients, packaging, or something else that make up your finished products.'
              }
            />
          )}
        </Box>
        <CreateEditComponentForm
          formRef={formRef}
          component={componentItem}
          partner={partner}
          hidePartner={hidePartner}
          onSubmit={handleSubmit}
          onCancel={handleClose}
        />
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default CreateEditComponentDialog;
