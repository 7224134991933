import * as Yup from 'yup';
import { createImageSchema, createMediaSchema } from './shared';

export const linkSchema = Yup.object().shape({
  chainStepActivitySourceId: Yup.string().required(),
});

const locationSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  title: Yup.string().label('Activity detail').default('').required(),
  description: Yup.string().label('Description').default(''),
  locationCoordinates: Yup.object()
    .shape({
      lat: Yup.number(),
      lng: Yup.number(),
    })
    .typeError('Please add a location to the partner')
    .required('Location is a required field'),
  name: Yup.string().nullable().required('Location is a required field'),
  mediaList: Yup.array().of(createMediaSchema('', false)).nullable().default([]),
  partner: Yup.object()
    .shape({
      id: Yup.string().required(),
      title: Yup.string().required(),
      logo: createMediaSchema('', false).nullable(),
    })
    .nullable()
    .default(null),
  component: Yup.object()
    .shape({
      id: Yup.string().required(),
      title: Yup.string().required(),
      partnerId: Yup.string().nullable().default(null),
      rawMaterials: Yup.array().of(Yup.object().shape({ id: Yup.string() })),
    })
    .nullable()
    .default(null),
  coordinates: Yup.array()
    .of(Yup.array().of(Yup.array().of(Yup.number())))
    .nullable()
    .default(null),
});

export const activitySchema = Yup.object().shape({
  activity: locationSchema,
});

const chainActivitySchema = Yup.object().shape({
  id: Yup.string().notRequired(),
  incomingLinks: Yup.array().of(linkSchema).default([]),
  activity: locationSchema,
  chainStepId: Yup.string().required(),
});

export const editChainSchema = Yup.object().shape({
  title: Yup.string().required(),
  image: createImageSchema({
    message: 'Please add an image of your component',
    required: false,
  }),
  partnerId: Yup.string(),
});

export default chainActivitySchema;
