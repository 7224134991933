import merge from 'lodash/merge';
import queryPolicies from './query';
import feedbackPolicies from './feedback';
import mediaPolicies from './media';
import companyPolicies from './company';
import productPolicies from './product';
import versionPolicies from './version';
import impactClaimPolicies from './impactClaims';
import documentsPolicies from './document';
import componentChainActivityPolicies from './componentChainActivities';

export default merge(
  queryPolicies,
  mediaPolicies,
  feedbackPolicies,
  companyPolicies,
  productPolicies,
  versionPolicies,
  impactClaimPolicies,
  documentsPolicies,
  componentChainActivityPolicies
);
