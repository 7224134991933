import React, {
  ClassAttributes,
  FC,
  InputHTMLAttributes,
  PropsWithChildren,
  createElement,
} from 'react';

type CustomProvider<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (InputHTMLAttributes<HTMLInputElement> & ClassAttributes<HTMLInputElement>) | null = any
> = { provider: FC<T & PropsWithChildren>; props: T };
interface ICustomProvidersProps extends PropsWithChildren {
  providers: (FC<PropsWithChildren> | CustomProvider)[];
}

/**
 *
 * @param param0 an array providers and optional props
 * The list is sorted, the once in the beginning will be rendered first
 */
const CustomProviders: FC<ICustomProvidersProps> = ({ providers, children }) => {
  return (
    <>
      {providers.reverse().reduce((child, customProvider) => {
        if ('provider' in customProvider) {
          return createElement(customProvider.provider, customProvider.props, child);
        }
        return createElement(customProvider, undefined, child);
      }, children)}
    </>
  );
};

export default CustomProviders;
