import React, { ReactNode, FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box } from '@mui/material';
import { Loader } from 'components/Forms';
import { styled } from '@mui/material/styles';
import ScrollableTableContainer from './ScrollableTableContainer';

const LoadingContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledInfiniteScroll = styled(InfiniteScroll)<{ 'max-height'?: string }>(
  ({ theme, 'max-height': maxHeight }) => ({
    margin: theme.spacing(0, -2),
    padding: theme.spacing(0, 2),
    maxHeight,
  })
);

interface IInfiniteScrollWrapper {
  dataLength: number;
  hasMore: boolean;
  scrollableTarget?: ReactNode;
  scrollThreshold?: number;
  children: ReactNode;
  height?: number;
  next: () => void;
}

const InfiniteScrollWrapper: FC<IInfiniteScrollWrapper> = ({
  children,
  ...infiniteScrollProps
}) => {
  return (
    <StyledInfiniteScroll
      loader={
        <LoadingContainer>
          <Loader size={20} />
        </LoadingContainer>
      }
      {...infiniteScrollProps}
    >
      <ScrollableTableContainer>{children}</ScrollableTableContainer>
    </StyledInfiniteScroll>
  );
};

export default InfiniteScrollWrapper;
