import { ReactNode } from 'react';
import { IRawMaterial } from './component.types';
import { CultivatedAreas } from './types';
import { Partner, PartnerRequest } from './partner.types';

export enum EudrStatementStatus {
  CREATED = 'CREATED',
  EVALUATING_AFFECTEDNESS = 'EVALUATING_AFFECTEDNESS',
  COLLECTING_GEO_DATA = 'COLLECTING_GEO_DATA',
  SELECTING_RISK_ANALYSIS = 'SELECTING_RISK_ANALYSIS',
  PENDING_RISK_ANALYSIS = 'PENDING_RISK_ANALYSIS',
  COMPLETED_RISK_ANALYSIS = 'COMPLETED_RISK_ANALYSIS',
  SELECTING_ASSESSMENTS = 'SELECTING_ASSESSMENTS',
  PENDING_ASSESSMENTS = 'PENDING_ASSESSMENTS',
  COMPLETED_ASSESSMENTS = 'COMPLETED_ASSESSMENTS',
  RISK_MITIGATION = 'RISK_MITIGATION',
  COMPLETED = 'COMPLETED',
  SUBMITTED_EU = 'SUBMITTED_EU',
  ARCHIVED = 'ARCHIVED',
}

export enum EudrResponsibilityType {
  OPERATOR = 'OPERATOR',
  TRADER = 'TRADER',
  TRADER_OPERATOR = 'TRADER_OPERATOR',
  PARTNER = 'PARTNER',
}

export enum EudrAffectednessType {
  NOT_AFFECTED = 'NOT_AFFECTED',
  PARTIALLY_AFFECTED = 'PARTIALLY_AFFECTED',
  INDIRECTLY_AFFECTED = 'INDIRECTLY_AFFECTED',
  FULLY_AFFECTED = 'FULLY_AFFECTED',
}

export interface EudrStatement {
  id: string;
  title: string;
  createdTimestamp: string;
  year: number;
  companyResponsibilityType: EudrResponsibilityType;
  companyAffectednessType: EudrAffectednessType;
  involvedPartners: { id: string }[];
  status: EudrStatementStatus;
  eudrDatasets: EudrDataset[];
  aggregatedQuestionnaires?: {
    aggregatedResponseSummary: { answered: number; notAnswered: number; total: number };
    aggregatedRiskSummary: {
      lowRiskPercentage: number;
      mediumRiskPercentage: number;
      highRiskPercentage: number;
    };
    requests: PartnerRequest[];
  };
}

export enum RiskLevel {
  LOW = 'Low risk',
  MEDIUM = 'Medium risk',
  HIGH = 'High risk',
}

export enum ChangeDetection {
  LOW = 'No/Negligible risk',
  MEDIUM = 'Medium risk',
  HIGH = 'High risk',
}

export enum EUDRDatasetStatus {
  CREATED = 'CREATED',
  PARSED = 'PARSED',
  PARSED_FAILED = 'PARSED_FAILED',
  MAPPED = 'MAPPED',
  MAPPED_FAILED = 'MAPPED_FAILED',
  VALIDATED = 'VALIDATED',
  VALIDATED_FAILED = 'VALIDATED_FAILED',
  NORMALISED = 'NORMALISED',
  NORMALISED_FAILED = 'NORMALISED_FAILED',
}

export enum EUDRDatasetColumns {
  FARM_ID = 'Farm ID',
  FARM_OWNER = 'Farm owner name',
  FARM_NAME = 'Farm name',
  COORDINATES_LAT_LNG = 'Coordinates (Lat/Lng)',
  COORDINATES_LNG_LAT = 'Coordinates (Lng/Lat)',
  COORDINATES_LATITUDE = 'Latitude',
  COORDINATES_LONGITUDE = 'Longitude',
  COORDINATES_DD = 'Coordinates DD (40.7128° N, 74.0060° W)',
  COORDINATES_DD_N = 'Coordinates DD North (40.7128° N)',
  COORDINATES_DD_W = 'Coordinates DD West (74.0060° W)',
  COORDINATES_DMS = `Coordinates DMS (40°42'46" N, 74°0'21" W)`,
  COORDINATES_DMS_N = `Coordinates DMS North (40°42'46" N)`,
  COORDINATES_DMS_W = `Coordinates DMS West (74°0'21" W)`,
  COORDINATES_DDM = `Coordinates DDM (40°42.767' N, 74°0.35' W)`,
  COORDINATES_DDM_N = `Coordinates DDM North (40°42.767' N)`,
  COORDINATES_DDM_W = `Coordinates DDM West (74°0.35' W)`,
  FARM_PLOT_SIZE_POINT = 'Farm plot size (Hectares) (Point decimal 1.0)',
  FARM_PLOT_SIZE_COMMA = 'Farm plot size (Hectares) (Comma decimal 1,0)',
}

export type EUDRDatasetColumnsType = keyof typeof EUDRDatasetColumns;

export const EUDRDatasetColumnKeys: EUDRDatasetColumnsType[] = [
  'FARM_ID',
  'FARM_OWNER',
  'FARM_NAME',
  'COORDINATES_LAT_LNG',
  'COORDINATES_LNG_LAT',
  'COORDINATES_LATITUDE',
  'COORDINATES_LONGITUDE',
  'COORDINATES_DD',
  'COORDINATES_DD_N',
  'COORDINATES_DD_W',
  'COORDINATES_DMS',
  'COORDINATES_DMS_N',
  'COORDINATES_DMS_W',
  'COORDINATES_DDM',
  'COORDINATES_DDM_N',
  'COORDINATES_DDM_W',
  'FARM_PLOT_SIZE_POINT',
  'FARM_PLOT_SIZE_COMMA',
];

export interface EudrStatement {
  id: string;
  title: string;
  createdTimestamp: string;
  year: number;
  status: EudrStatementStatus;
}

export interface IComplianceGuideSubSection {
  key: string;
  title: string;
  itemTitle?: string;
  description?: string;
  content: ReactNode;
}

export interface IComplianceGuideSection {
  key: string;
  title: string;
  subSections: IComplianceGuideSubSection[];
}

export interface IComplianceGuideDetails {
  key: string;
  image: string;
  title: string;
  illustration: string;
  regulationUrl: string;
}

export type IComplianceGuide = IComplianceGuideDetails & { sections: IComplianceGuideSection[] };

export interface GeoCParcel {
  geocParcelId: string;
  geocBatchId: string;
  harvestDate: string;
  rawMaterialId: string;
  cultivatedAreas: CultivatedAreas;
  cultivatedAreasId: string;
  resultTimestamp: string;
  deforestationRisk: keyof typeof RiskLevel;
  baselineDate: string;
  landcoverPngBaselineDate: string;
  forestCoverage: number;
  plantationCoverage: number;
  shrubsCoverage: number;
  noTreesCoverage: number;
  rgbPngBaselineDate: string;
  rgbPngProductionEnddate: string;
  forestChangePng: string;
  deforestationIndex: number;
  deforestationAreaHa: number;
  degradationRisk: keyof typeof RiskLevel;
  degradationAreaHa: number;
  degradationIndex: number;
  countryRisk: keyof typeof RiskLevel;
  geocBatch: GeoCBatch;
  rawMaterial: IRawMaterial;
  id: string;
  createdTimestamp: string;
}

export interface EUDRDatasetDocumentError {
  documentId: string;
  errorMessage: string;
}

export type EUDRDatasetCell = {
  value: string;
  error?: [EUDRDatasetDocumentError];
  isValid?: boolean;
};

export type EUDRDatasetRow = {
  name: EUDRDatasetCell;
  farmId: EUDRDatasetCell;
  coordinates: EUDRDatasetCell;
  size: EUDRDatasetCell;
};

export type EUDRDatasetTableRepresentation = {
  columnsNames: string[];
  rows: EUDRDatasetRow[];
};

export interface EudrDataset {
  id: string;
  title: string;
  status: keyof typeof EUDRDatasetStatus;
  originCountry: string;
  partnerId: string;
  documents: { id: string; title: string }[];
  /**
   * Partner base values
   */
  partner: Partner;
  rawMaterial: IRawMaterial;
  riskStatus: keyof typeof RiskLevel;
  geocBatches: GeoCBatch[];
  cultivationAreas?: CultivatedAreas[];
  tableRepresentation?: [EUDRDatasetTableRepresentation];
}

export interface GeoCBatch {
  id: string;
  createdTimestamp: string;
  externalBatchId: string;
  geocBatchId: string;
  eudrDatasetId: string;
  weight: number;
  harvestDate: string;
  riskStatus: keyof typeof RiskLevel;
}

export interface GeocParcelConnection {
  edges: { node: GeoCParcel }[];
  highRisks: number;
  mediumRisks: number;
  lowRisks: number;
  count: number;
}

export interface ColumnsData {
  columnName: string;
  columnDataExample: string[]; // Array of strings representing first 5 items as examples
}

export interface DatasetMapping {
  datasetId: string;
  columnsData: ColumnsData[];
}

export interface EUDRDatasetParseError {
  datasetId: string;
  documentErrors: EUDRDatasetDocumentError[];
}

export interface ProcessEUDRDatasetsPayload {
  eudrDatasets: EudrDataset[];
  datasetParseErrors: EUDRDatasetParseError[];
  datasetMappings?: DatasetMapping[];
}

export interface ProcessEUDRDatasetInput {
  datasetId: string;
  mappedColumns: MapColumnsInput[];
  decimalSeperator?: string;
  csvSeperator?: string;
}

export interface MapColumnsInput {
  targetColumnName: EUDRDatasetColumnsType;
  sourceColumnName: string;
  columnDataExample?: string[];
  removed?: boolean;
}

export interface QuestionnaireResponsesOption {
  id: string;
  text: string;
  weight: number;
  riskLevel: RiskLevel;
  answerPercentage: number;
}

export interface QuestionnaireResponse {
  text: string;
  options: QuestionnaireResponsesOption[];
}
export interface QuestionnaireResponseSummary {
  averageResponseTime: number;
  riskMode: RiskLevel;
}
