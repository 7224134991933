import React, { FC, useState, useMemo } from 'react';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import { EudrDataset, GeoCBatch, GeoCParcel, RiskLevel } from 'types/compliance.types';
import BatchSelection from 'components/ComplianceGuide/components/BatchSelection';
import BatchRiskExample from 'assets/img/compliance/batch_result_example.png';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';
import { Field, Form, Formik } from 'formik';
import { batchesMitigationSchema, IBatchMitigation } from 'constants/schemas/mitigation.schema';
import RiskAccordion from 'components/ComplianceGuide/components/RiskAccordion';
import LandCoverExample from 'assets/img/compliance/land_cover_example.png';
import PeriodChangedExample from 'assets/img/compliance/period_changed_example.png';
import StartDateExample from 'assets/img/compliance/start_date_example.png';
import { PageSubTitle } from 'components/Structure';
import MitigationCheckbox from '../../MitigationCheckbox';
import FieldWrapper from 'designSystem/Inputs/FieldWrapper/FieldWrapper';
import { TextField } from 'formik-mui';
import UploadField from '../../Forms/UploadField';
import { Partner } from 'types/partner.types';

const EUDR_DATASETS: EudrDataset[] = [
  {
    id: '1',
    title: 'Origin data Qualcoco',
    status: 'VALIDATED',
    originCountry: 'Ghana',
    partnerId: 'Partner 1',
    partner: { title: 'Qualcoco' } as Partner,
    rawMaterial: { id: '1', title: 'Cocoa' },
    documents: [],
    riskStatus: 'HIGH',
    geocBatches: [
      {
        id: 'GHA-872574',
        createdTimestamp: '2023-09-24',
        externalBatchId: 'GHA-872574',
        geocBatchId: 'GHA-872574',
        eudrDatasetId: '1',
        weight: 9654,
        riskStatus: 'HIGH',
        harvestDate: '2023-09-24',
      },
      {
        id: 'GHA-872573',
        createdTimestamp: '2023-07-13',
        externalBatchId: 'GHA-872573',
        geocBatchId: 'GHA-872573',
        eudrDatasetId: '1',
        weight: 2934,
        riskStatus: 'MEDIUM',
        harvestDate: '2023-09-24',
      },
    ],
  },
  {
    id: '2',
    title: 'Origin data',
    status: 'VALIDATED',
    originCountry: 'Ghana',
    partnerId: 'Partner 1',
    documents: [],
    partner: { title: 'CocoMass' } as Partner,
    rawMaterial: { id: '1', title: 'Cocoa' },
    riskStatus: 'MEDIUM',
    geocBatches: [
      {
        id: 'GHA-476525',
        createdTimestamp: '2023-06-12',
        externalBatchId: 'GHA-476525',
        geocBatchId: 'GHA-476525',
        eudrDatasetId: '2',
        weight: 3541,
        riskStatus: 'MEDIUM',
        harvestDate: '2023-09-24',
      },
      {
        id: 'GHA-654324',
        createdTimestamp: '2023-05-23',
        externalBatchId: 'GHA-654324',
        geocBatchId: 'GHA-654324',
        eudrDatasetId: '2',
        weight: 934,
        riskStatus: 'LOW',
        harvestDate: '2023-09-24',
      },
    ],
  },
];

const PARCELS: GeoCParcel[] = [
  {
    geocParcelId: '12345679',
    geocBatchId: 'GHA-872573',
    harvestDate: '2024-01-19',
    resultTimestamp: '2024-01-19',
    deforestationRisk: 'MEDIUM',
    baselineDate: '2020-12-30',
    landcoverPngBaselineDate: LandCoverExample,
    forestCoverage: 0.5,
    plantationCoverage: 0.5,
    shrubsCoverage: 0.5,
    noTreesCoverage: 0.5,
    rgbPngBaselineDate: StartDateExample,
    rgbPngProductionEnddate: StartDateExample,
    forestChangePng: PeriodChangedExample,
    deforestationIndex: 0.5,
    deforestationAreaHa: 0.5,
    degradationRisk: 'LOW',
    degradationAreaHa: 0.5,
    degradationIndex: 0.5,
    countryRisk: 'HIGH',
    rawMaterialId: '',
    cultivatedAreas: {
      id: '1',
      coordinates: [
        [
          [1, 1],
          [2, 2],
          [3, 3],
          [1, 1],
        ],
      ],
      farm: {
        id: '1',
        partner: {
          title: 'Santa Maria',
        },
      },
    },
    cultivatedAreasId: '',
    geocBatch: EUDR_DATASETS[0].geocBatches[0],
    rawMaterial: {
      id: '1',
      title: 'Cocoa',
    },
    id: '12345678',
    createdTimestamp: '',
  },
  {
    geocParcelId: '12345679',
    geocBatchId: 'GHA-872573',
    harvestDate: '2024-01-19',
    resultTimestamp: '2024-01-19',
    deforestationRisk: 'LOW',
    baselineDate: '2020-12-30',
    landcoverPngBaselineDate: LandCoverExample,
    forestCoverage: 0.5,
    plantationCoverage: 0.5,
    shrubsCoverage: 0.5,
    noTreesCoverage: 0.5,
    rgbPngBaselineDate: StartDateExample,
    rgbPngProductionEnddate: StartDateExample,
    forestChangePng: PeriodChangedExample,
    deforestationIndex: 0.5,
    deforestationAreaHa: 0.5,
    degradationRisk: 'LOW',
    degradationAreaHa: 0.5,
    degradationIndex: 0.5,
    countryRisk: 'HIGH',
    rawMaterialId: '',
    cultivatedAreas: {
      id: '1',
      coordinates: [
        [
          [1, 1],
          [2, 2],
          [3, 3],
          [1, 1],
        ],
      ],
      farm: {
        id: '1',
        partner: {
          title: 'Augustus',
        },
      },
    },
    cultivatedAreasId: '',
    geocBatch: EUDR_DATASETS[0].geocBatches[0],
    rawMaterial: {
      id: '1',
      title: 'Cocoa',
    },
    id: '12345679',
    createdTimestamp: '',
  },
  {
    geocParcelId: '12345679',
    geocBatchId: 'GHA-872574',
    harvestDate: '2024-01-19',
    resultTimestamp: '2024-01-19',
    deforestationRisk: 'HIGH',
    baselineDate: '2020-12-30',
    landcoverPngBaselineDate: LandCoverExample,
    forestCoverage: 0.5,
    plantationCoverage: 0.5,
    shrubsCoverage: 0.5,
    noTreesCoverage: 0.5,
    rgbPngBaselineDate: StartDateExample,
    rgbPngProductionEnddate: StartDateExample,
    forestChangePng: PeriodChangedExample,
    deforestationIndex: 0.5,
    deforestationAreaHa: 0.5,
    degradationRisk: 'LOW',
    degradationAreaHa: 0.5,
    degradationIndex: 0.5,
    countryRisk: 'HIGH',
    rawMaterialId: '',
    cultivatedAreas: {
      id: '1',
      coordinates: [
        [
          [1, 1],
          [2, 2],
          [3, 3],
          [1, 1],
        ],
      ],
      farm: {
        id: '1',
        partner: {
          title: 'Augustus',
        },
      },
    },
    cultivatedAreasId: '',
    geocBatch: EUDR_DATASETS[0].geocBatches[0],
    rawMaterial: {
      id: '1',
      title: 'Cocoa',
    },
    id: '86543564',
    createdTimestamp: '',
  },
  {
    geocParcelId: '12345679',
    geocBatchId: 'GHA-872574',
    harvestDate: '2024-01-19',
    resultTimestamp: '2024-01-19',
    deforestationRisk: 'MEDIUM',
    baselineDate: '2020-12-30',
    landcoverPngBaselineDate: LandCoverExample,
    forestCoverage: 0.5,
    plantationCoverage: 0.5,
    shrubsCoverage: 0.5,
    noTreesCoverage: 0.5,
    rgbPngBaselineDate: StartDateExample,
    rgbPngProductionEnddate: StartDateExample,
    forestChangePng: PeriodChangedExample,
    deforestationIndex: 0.5,
    deforestationAreaHa: 0.5,
    degradationRisk: 'LOW',
    degradationAreaHa: 0.5,
    degradationIndex: 0.5,
    countryRisk: 'HIGH',
    rawMaterialId: '',
    cultivatedAreas: {
      id: '1',
      coordinates: [
        [
          [1, 1],
          [2, 2],
          [3, 3],
          [1, 1],
        ],
      ],
      farm: {
        id: '1',
        partner: {
          title: 'Qualcoco',
        },
      },
    },
    cultivatedAreasId: '',
    geocBatch: EUDR_DATASETS[0].geocBatches[0],
    rawMaterial: {
      id: '1',
      title: 'Cocoa',
    },
    id: '96543568',
    createdTimestamp: '',
  },
  {
    geocParcelId: '12345679',
    geocBatchId: 'GHA-476525',
    harvestDate: '2024-01-19',
    resultTimestamp: '2024-01-19',
    deforestationRisk: 'MEDIUM',
    baselineDate: '2020-12-30',
    landcoverPngBaselineDate: LandCoverExample,
    forestCoverage: 0.5,
    plantationCoverage: 0.5,
    shrubsCoverage: 0.5,
    noTreesCoverage: 0.5,
    rgbPngBaselineDate: StartDateExample,
    rgbPngProductionEnddate: StartDateExample,
    forestChangePng: PeriodChangedExample,
    deforestationIndex: 0.5,
    deforestationAreaHa: 0.5,
    degradationRisk: 'LOW',
    degradationAreaHa: 0.5,
    degradationIndex: 0.5,
    countryRisk: 'HIGH',
    rawMaterialId: '',
    cultivatedAreas: {
      id: '1',
      coordinates: [
        [
          [1, 1],
          [2, 2],
          [3, 3],
          [1, 1],
        ],
      ],
      farm: {
        id: '1',
        partner: {
          title: 'Santa Maria',
        },
      },
    },
    cultivatedAreasId: '',
    geocBatch: EUDR_DATASETS[0].geocBatches[0],
    rawMaterial: {
      id: '1',
      title: 'Cocoa',
    },
    id: '63645635',
    createdTimestamp: '',
  },
];

const DataSheetContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
}));

const WarningBox = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  background: theme.custom.themeColors.primary[5],
  borderRadius: 4,
  padding: 8,
  marginBottom: theme.spacing(2),
}));

const Container = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  background: theme.custom.themeColors.white,
  borderRadius: 6,
  padding: theme.spacing(3),
}));

const BatchResultExampleImage = styled('img')(({ theme }) => ({
  width: '100%',
  borderRadius: 6,
}));

const MapContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  borderRadius: 6,
  overflow: 'hidden',
}));

const MapOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: theme.spacing(1.5),
}));

const BlackGradientTopBottom = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)',
}));

const List = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: 0,
}));

const DisclaimerContainer = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  padding: theme.spacing(2),
  borderRadius: 6,
  border: `1px solid ${theme.custom.themeColors.primary[40]}`,
}));

const MitigationPolygons: FC = () => {
  const { sections, setSelectedSubSection, selectedSectionIndex, selectedSubSectionIndex } =
    useEudrComplianceGuide();
  const [selectedDatasetId, setSelectedDatasetId] = useState<string>(EUDR_DATASETS[0].id);
  const [selectedBatch, setSelectedBatch] = useState<GeoCBatch>(EUDR_DATASETS[0].geocBatches[0]);

  const initialFormValues = useMemo(() => {
    return EUDR_DATASETS.flatMap(dataset =>
      dataset.geocBatches.map(batch => ({
        batchId: batch.id,
        regionalRisk: {
          riskNegligible: false,
          comment: '',
          documents: [],
        },
        plotRisk: {
          riskNegligible: false,
          comment: '',
          documents: [],
        },
      }))
    );
  }, []);

  const currentBatchIndex = useMemo(() => {
    return initialFormValues.findIndex(({ batchId }) => batchId === selectedBatch.id);
  }, [selectedBatch, initialFormValues]);

  const handleClickDataSheet = (dataSetId: string) => {
    setSelectedDatasetId(dataSetId);
    const selectedBatch = EUDR_DATASETS.find(({ id }) => id === dataSetId)?.geocBatches[0];
    if (selectedBatch) {
      setSelectedBatch(selectedBatch);
    }
  };

  const handleBatchChange = (batch: GeoCBatch) => {
    setSelectedBatch(batch);
  };

  const handleSubmit = (values: IBatchMitigation) => {
    setSelectedSubSection(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      sections[selectedSectionIndex!].subSections[selectedSubSectionIndex! + 1].key
    );
    // TODO: Implement the submit to backend
    return;
  };

  return (
    <Formik<IBatchMitigation>
      validationSchema={batchesMitigationSchema}
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, submitForm, setFieldValue }) => {
        return (
          <Form>
            <EudrComplianceGuideSubSection
              allowNextStepNavigation
              nextStepLoading={isSubmitting}
              onNextStepClick={submitForm}
            >
              <Box>
                <WarningBox>
                  <ThemeTypography variant="BODY_MEDIUM_BOLD" color="BLUE_60">
                    Mitigation procedures required
                  </ThemeTypography>
                </WarningBox>
                <ThemeTypography variant="BODY_MEDIUM">
                  See here all origins and batches where there was a deforestation risk found. If
                  you have a medium or high risk for any regions or plots, you need to perform risk
                  mitigation procedures in order to be compliant. See here recommendations for each
                  risk.
                </ThemeTypography>
                <DisclaimerContainer mt={2}>
                  <ThemeTypography variant="BODY_MEDIUM">
                    <b>Disclaimer:</b> As a digital software provider, Seedtrace is legally not
                    allowed and authorised to provide legal advice in individual cases. This means
                    that the recommendations for action should be considered merely as guidance, and
                    the implementation of the mitigation recommendations does not provide a
                    guarantee of a full assessment of your legal obligations under the EUDR. To
                    consult additional legal advice in particular based on the individual countries
                    legislation is recommended.
                  </ThemeTypography>
                </DisclaimerContainer>
                <Box display="flex" gap={2} flexWrap="wrap" mt={2}>
                  {EUDR_DATASETS.map((dataSheet, index) => (
                    <DataSheetContainer
                      key={index}
                      onClick={() => handleClickDataSheet(dataSheet.id)}
                    >
                      <DatasetItem
                        title={dataSheet.title}
                        location={dataSheet.originCountry}
                        active={dataSheet?.id === selectedDatasetId}
                        owner={dataSheet.partner.title}
                        commodity={dataSheet.rawMaterial.title}
                        riskLevel={RiskLevel[dataSheet.riskStatus]}
                      />
                    </DataSheetContainer>
                  ))}
                </Box>
              </Box>
            </EudrComplianceGuideSubSection>
            <Box mt={3}>
              {selectedBatch && (
                <BatchSelection
                  batches={
                    EUDR_DATASETS.find(({ id }) => id === selectedDatasetId)?.geocBatches || []
                  }
                  selectedBatch={selectedBatch}
                  onSelectBatch={handleBatchChange}
                />
              )}
            </Box>

            {selectedBatch.riskStatus !== 'LOW' && (
              <>
                <Box mt={3}>
                  <PageSubTitle title="Regional risk measures" />
                  <Container mt={0.5}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <MapContainer>
                          <BlackGradientTopBottom />
                          <MapOverlay>
                            <ThemeTypography variant="BODY_MEDIUM_BOLD" color="WHITE">
                              Regional deforestation analysis
                            </ThemeTypography>
                            <Box width="fit-content" mt={1}>
                              <RiskBadge riskLevel={RiskLevel[selectedBatch.riskStatus]} />
                            </Box>
                          </MapOverlay>
                          <BatchResultExampleImage
                            src={BatchRiskExample}
                            alt="Batch result example"
                          />
                        </MapContainer>
                      </Grid>
                      <Grid item xs={8}>
                        <ThemeTypography variant="BODY_LARGER_BOLD">
                          Consider the following recommendations
                        </ThemeTypography>
                        <Box mt={2}>
                          <Box ml={2}>
                            <ThemeTypography variant="BODY_MEDIUM">
                              <List>
                                <li>
                                  Make sure that your plot areas are accurate, for instance through
                                  an on-site verification of plot areas.
                                </li>
                                <li>Engage local experts or specialized consulting firms.</li>
                                <li>
                                  Use historical data to identify deforestation trends over time.
                                </li>
                                <li>
                                  Work with local communities, environmental organizations, and NGOs
                                  to gain deeper insights into the local situation.
                                </li>
                                <li>
                                  Implement continuous monitoring and reporting systems to track
                                  changes in forests.
                                </li>
                                <li>
                                  Regularly review and update risk assessments and due diligence
                                  measures.
                                </li>
                                <li>
                                  Work closely and communicate with suppliers to ensure they are
                                  aware of the risks and take measures to mitigate them.
                                </li>
                                <li>
                                  Conduct trainings and workshops for suppliers to promote
                                  sustainable practices.
                                </li>
                              </List>
                            </ThemeTypography>
                          </Box>
                          <Box mt={2} />
                          <MitigationCheckbox
                            selected={values[currentBatchIndex]?.regionalRisk.riskNegligible}
                            label="I have performed risk mitigations and now consider this risk negligible."
                            onClick={() =>
                              setFieldValue(
                                `${currentBatchIndex}.regionalRisk.riskNegligible`,
                                !values[currentBatchIndex].regionalRisk.riskNegligible
                              )
                            }
                          />
                          {values[currentBatchIndex]?.regionalRisk.riskNegligible && (
                            <Box mt={2}>
                              <ThemeTypography variant="BODY_MEDIUM">
                                Add comments or upload any supporting documents to verify your risk
                                mitigation procedures below.
                              </ThemeTypography>
                              <Box mt={2.5} />
                              <FieldWrapper label="Comment">
                                <Field
                                  component={TextField}
                                  lang="en"
                                  name={`${currentBatchIndex}.regionalRisk.comment`}
                                  fullWidth
                                  multiline
                                  rows={4}
                                  charLimit={650}
                                  placeholder="Write your message..."
                                />
                              </FieldWrapper>
                              <Box mt={2} />
                              <FieldWrapper label="Document upload">
                                <Field
                                  component={UploadField}
                                  name={`${currentBatchIndex}.regionalRisk.documents`}
                                  multiple={true}
                                  supportedFileTypes={['pdf', 'csv', 'xls', 'xlsx']}
                                />
                              </FieldWrapper>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
                <Box mt={3}>
                  <PageSubTitle title="Plot risk measures" />
                  <Container mt={0.5}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        {PARCELS.filter(parcel => parcel.geocBatchId === selectedBatch.id).map(
                          parcel => (
                            <Box key={parcel.id} mb={1}>
                              <RiskAccordion hasBorder parcel={parcel} />
                            </Box>
                          )
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <ThemeTypography variant="BODY_LARGER_BOLD">
                          Consider the following recommendations
                        </ThemeTypography>
                        <Box mt={2}>
                          <Box ml={2}>
                            <ThemeTypography variant="BODY_MEDIUM">
                              <List>
                                <li>Make sure that all farm plots are mapped correctly.</li>
                                <li>Conduct an on-site self-inspection.</li>
                                <li>Exclude the respective plot(s).</li>
                                <li>
                                  Raw materials being harvested from this area need to be physically
                                  segregated and excluded from any batch with destination to the EU.
                                </li>
                              </List>
                            </ThemeTypography>
                          </Box>
                          <Box mt={2} />
                          <MitigationCheckbox
                            selected={values[currentBatchIndex]?.plotRisk.riskNegligible}
                            label="I have performed risk mitigations and now consider this risk negligible."
                            onClick={() =>
                              setFieldValue(
                                `${currentBatchIndex}.plotRisk.riskNegligible`,
                                !values[currentBatchIndex].plotRisk.riskNegligible
                              )
                            }
                          />
                          {values[currentBatchIndex]?.plotRisk.riskNegligible && (
                            <Box mt={2}>
                              <ThemeTypography variant="BODY_MEDIUM">
                                Add comments or upload any supporting documents to verify your risk
                                mitigation procedures below.
                              </ThemeTypography>
                              <Box mt={2.5} />
                              <FieldWrapper label="Comment">
                                <Field
                                  component={TextField}
                                  lang="en"
                                  name={`${currentBatchIndex}.plotRisk.comment`}
                                  fullWidth
                                  multiline
                                  rows={4}
                                  charLimit={650}
                                  placeholder="Write your message..."
                                />
                              </FieldWrapper>
                              <Box mt={2} />
                              <FieldWrapper label="Document upload">
                                <Field
                                  component={UploadField}
                                  name={`${currentBatchIndex}.plotRisk.documents`}
                                  multiple={true}
                                  supportedFileTypes={['pdf', 'csv', 'xls', 'xlsx']}
                                />
                              </FieldWrapper>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default MitigationPolygons;
