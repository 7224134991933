import Bugsnag from '@bugsnag/js';
import { ErrorView } from 'containers';
import React, { FC, PropsWithChildren } from 'react';

const ErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const BugsnagReactPlugin = Bugsnag.getPlugin('react');

  if (!BugsnagReactPlugin) {
    return <ErrorView />;
  }

  const Boundary = BugsnagReactPlugin.createErrorBoundary(React);
  return <Boundary FallbackComponent={ErrorView}>{children}</Boundary>;
};

export default ErrorBoundary;
