import React, { FC } from 'react';
import { Box, styled } from '@mui/material';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import { PartnerTypeEnum, Partner } from 'types/partner.types';
import { StyledCell, OpacityContainer, Title } from './PartnersTableBody.styles';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import { ReactComponent as PinIcon } from 'assets/img/icons/dark-pin.svg';
import { getReadablePartnerStatus, getPartnerStatusColor } from 'utils/partnerUtils';
import useFeatureFlags, { FeatureFlag } from 'components/hooks/useFeatureFlags';
import { FlexBox } from 'components/Structure';
import Icon from 'designSystem/Primitives/Icon/Icon';
import Image from 'designSystem/DataDisplay/Image/Image';

interface Props {
  align?: 'left' | 'right' | 'center';
  partner: Partner;
  hidePartnerInvitationStatus?: boolean;
}

export const Pin = styled(PinIcon)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  width: 14,
  height: 14,
  color: theme.custom.themeColors.grayScale[100],
}));

const PartnerTableTitle: FC<Props> = ({ align, partner, hidePartnerInvitationStatus }) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const isPartnerInvitationsEnabled =
    isFeatureEnabled(FeatureFlag.PARTNER_INVITATIONS) && !hidePartnerInvitationStatus;
  const { title, logo, status, type, externalId } = partner;

  const farmExternalId = type === PartnerTypeEnum.FARM ? partner.farm.externalId : undefined;

  return (
    <StyledCell scope="row" align={align}>
      <OpacityContainer display="flex" is-transparent="false" alignItems="center">
        <Image
          image={logo}
          width={44}
          height={44}
          backupImageUrl={defaultPartnerImage}
          alt={`Logo of ${title}`}
        />
        <Box ml={2} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <FlexBox>
            <Title>{title}</Title>
            {(externalId || farmExternalId) && (
              <FlexBox ml={0.8}>
                <ThemeTypography variant="BODY_MEDIUM" color="GRAY_80">
                  |
                </ThemeTypography>
                <Box mr={1} />
                <ThemeTypography variant="BODY_MEDIUM" autoOverflow maxWidth={150} color="GRAY_80">
                  {externalId || farmExternalId}
                </ThemeTypography>
              </FlexBox>
            )}
          </FlexBox>

          <FlexBox>
            <FlexBox mr={1}>
              <Icon name="tag-filled" color="gray-80" size="small" mr={1} />
              <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                {type === PartnerTypeEnum.FARM ? 'Farm' : 'Supplier'}
              </ThemeTypography>
            </FlexBox>
            {isPartnerInvitationsEnabled && (
              <ThemeTypography variant="ITEM_SUBTITLE" color={getPartnerStatusColor(status)}>
                {getReadablePartnerStatus(status)}
              </ThemeTypography>
            )}
          </FlexBox>
        </Box>
      </OpacityContainer>
    </StyledCell>
  );
};

export default PartnerTableTitle;
