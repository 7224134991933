import AboutBlock from './aboutBlock';
import registrationSchema from './auth';
import { addressSchema, companySchema, companySchemaCombined } from './company';
import designSettingsSchema from './designSettings';
import FeedbackBlock from './feedbackBlock';
import gaTrackingIdSchema from './gaTrackingId';
import generalSchema from './general';
import ImpactFactsBlock from './impactFacts';
import inviteUserSchema from './inviteUser';
import MoreInfoBlock from './moreInfoBlock';
import { productLanguageSchema } from './product';
import ProductJourneyBlock from './productJourney';
import ProductsBlock from './productsBlock';
import {
  createImpactFactDefault,
  createMediaSchema,
  createProductDefault,
  mediaSchema,
} from './shared';
import VerifiedPaymentsBlock from './verifiedPaymentsBlock';
import {
  PartnersSchema,
  PartnerInviteSchema,
  PartnerReInviteSchema,
  PartnerRequestSchema,
} from './partners.schema';
import { VisibilitySettingsSchema } from './supplyChain';

const contentBlockSchema = {
  ABOUT_BLOCK: { schema: AboutBlock, name: 'AboutBlock' },
  PRODUCT_JOURNEY_BLOCK: { schema: ProductJourneyBlock, name: 'ProductJourneyBlock' },
  IMPACT_FACTS_BLOCK: { schema: ImpactFactsBlock, name: 'ImpactFactsBlock' },
  MORE_INFO_BLOCK: { schema: MoreInfoBlock, name: 'MoreInfoBlock' },
  PRODUCTS_BLOCK: { schema: ProductsBlock, name: 'ProductsBlock' },
  FEEDBACK_BLOCK: { schema: FeedbackBlock, name: 'FeedbackBlock' },
  VERIFIED_PAYMENT_BLOCK: { schema: VerifiedPaymentsBlock, name: 'VerifiedPaymentsBlock' },
};

export {
  contentBlockSchema,
  createMediaSchema,
  createProductDefault,
  createImpactFactDefault,
  mediaSchema,
  generalSchema,
  companySchema,
  addressSchema,
  companySchemaCombined,
  designSettingsSchema,
  registrationSchema,
  productLanguageSchema,
  gaTrackingIdSchema,
  inviteUserSchema,
  PartnersSchema,
  PartnerInviteSchema,
  PartnerRequestSchema,
  PartnerReInviteSchema,
  VisibilitySettingsSchema,
};
