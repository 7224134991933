import React, { useCallback } from 'react';
import { useQueryReturn } from 'components/hooks';
import { GET_PLACES } from 'graphql/queries';
import { AsyncAutocomplete } from 'components/Forms';
import get from 'lodash/get';

const GeolocationAutocomplete = ({ coordinatesKey, ...props }) => {
  const getPlaces = useQueryReturn(GET_PLACES, {
    fetchPolicy: 'no-cache',
  });
  const request = useCallback(
    query =>
      getPlaces({
        variables: {
          query,
        },
      }),
    [getPlaces]
  );

  const setFieldValue = (name, input) => {
    if (!input) return;
    const { title, lat, lng } = input;
    props.form.setFieldValue(name, title);
    // The field name is name, this is so the validation flow of Formik will be shown correctly.
    // This means we have to still set the locationCoordinates field manually
    props.form.setFieldValue(coordinatesKey, {
      lat,
      lng,
    });
  };

  return (
    <AsyncAutocomplete
      label=""
      queryKey="getPlaces"
      request={request}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      fieldProps={{
        ...props,
        form: { ...props.form, setFieldValue },
        placeholder: 'Type location name (Address, City, Country)',
        'data-cy': 'geolocation-name-input',
        field: {
          ...props.field,
          value: {
            title: get(props.form?.values, props.field.name),
            lat: get(props.form?.values, coordinatesKey || 'activity.locationCoordinates')?.lat,
            lng: get(props.form?.values, coordinatesKey || 'activity.locationCoordinates')?.lng,
          },
        },
      }}
    />
  );
};

export default GeolocationAutocomplete;

const getOptionLabel = option => option.title || '';
const getOptionSelected = (option, value) => option.title === value.title;
